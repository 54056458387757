$.ImageSettingsDialog = (function() {
	return function(settings, options) {
		if(!options || !options.title || !options.image) {
			throw 'Invalid options passed to $.ImageSettingsDialog';
		}

		var modal = $('<div class="ui modal"><i class="close icon"></i></div>');
		var header = $('<div class="header">');
		header.text(options.title);
		if(options.icon) {
			header.prepend('<i class="icon ' + options.icon + '">')
		}
		modal.append(header);

		var content = $('<div class="content">');
		modal.append(content);

		var context = modal[0];
		modal.append('<div class="actions"><div class="ui deny button">' + i18n.t('misc.cancel') + '</div><div class="ui approve primary button">' + i18n.t('misc.ok') + '</div></div>');

		var wrapperDim = options.wrapperDim || 210;
		var centerWrapper = $('<div>').css({
			height: wrapperDim + 'px'
		});
		if(options.image.ratio) {
			centerWrapper.css('width', (wrapperDim * options.image.ratio) + 'px');
		}

		var img;
		if(options.imageWrapper) {
			var clone = options.imageWrapper.clone();
			centerWrapper.append(clone);
			img = centerWrapper.find('img');

			if(!img[0].src) {
				img.attr('src', 'css/images/image-placeholder.png').show();
			}

			// Remove any hard coded width/height (ie: calc(100% - 10px) is fine)
			var startWidth = clone.css('width');
			if(startWidth.indexOf('px') != -1 && startWidth.indexOf('calc') == -1) {
				clone.css({
					width: '',
					height: ''
				});
			}
		} else {
			img = $('<img src="' + options.image.url + '" style="height: 100%; max-width: 100%;">');
			centerWrapper.append(img);
		}
		var wrapperPadding = 1;
		if(options.background) {
			wrapperPadding = 1.2;
		}
		var topPanel = $('<div>').css({
			display: 'flex',
			'justify-content': 'center',
			'align-items': 'center',
			width: '100%',
			height: (wrapperDim * wrapperPadding) + 'px',
			'box-sizing': 'content-box'
		});

		if(options.background) {
			var background;
			if(typeof options.background == 'string') {
				background = options.background;
			} else {
				background = options.background.url;
			}

			topPanel.css({
				background: background,
				'background-size': '100%'
			});

			if(options.background.position) {
				topPanel.css('background-position', options.background.position);
			}
		}

		topPanel.append(centerWrapper);
		content.append(topPanel).append('<p/>');

		var form = $('<div class="ui form">');
		var settingsForm = form.SettingsBuilder(settings, {
			onSettingChange: options.onSettingChange
		});
		context.settingsForm = settingsForm;
		content.append(form);

		$.extend(context, options);
		if(options.initImageSettings) {
			options.initImageSettings.call(context, img, settingsForm.originalSettings);
		}

		content[0].maskedBorder = options.maskedBorder;

		return modal.modal({
			onVisible: function() {
				settingsForm.find('.multiple.dropdown').children('.ui.label').css('display', 'inline-block').removeClass('hidden').addClass('visible');

				window.setTimeout(function() {
					settingsForm.find('.multiple.dropdown').children('.ui.label').css('display', 'inline-block').removeClass('hidden').addClass('visible');
				}, 10);
			},
			onShow: function() {
				if(options.onShow) {
					options.onShow.call(context);
				}
			},
			onApprove: function() {
				var changes = settingsForm.getChanges();

				if(changes.length || context.startingContainerData) {
					settingsForm.applyChanges(changes);

					if (options.onSettingsApplied) {
						options.onSettingsApplied.call(context, settingsForm.currentSettings, changes);
					}
				}
			},
			onHidden: function() {
				$(this).remove();
			},
			closable: false,
			observeChanges: true
		}).modal('show');
	};
}) ();
$.ImageSettingsDialog.getDefaultSettings = function(definition, options) {
	if(!options) {
		options = {
			borders: true,
			dropShadow: true,
			miscEffects: true
		};
	}

	var settings = [];
	if(options.borders) {
		settings.push({
			name: 'border',
			description: 'Borders',
			type: 'section',
			group: 'border',
			value: definition.border,
			settings: [
				{
					name: 'enabled',
					description: 'Enabled',
					type: 'checkbox'
				},
				{
					name: 'thickness',
					description: 'Thickness: %value%',
					type: 'inc/dec',
					range: [1, 10],
					value: 1
				},
				{
					name: 'color',
					description: window.i18n.t('misc.color'),
					type: 'color',
					value: '#000000'
				}
			]
		});
	}

	if(options.dropShadow) {
		settings.push({
			name: 'dropShadow',
			description: 'Drop Shadow',
			type: 'section',
			group: 'dropShadow',
			value: definition.dropShadow,
			settings: [
				{
					name: 'enabled',
					description: 'Enabled',
					type: 'checkbox'
				},
				{
					name: 'depth',
					description: 'Depth of %value%',
					type: 'inc/dec',
					range: [2, 20],
					value: options.defaultDropdownDepth ? options.defaultDropdownDepth : 12,
					inc: 2
				},
				{
					name: 'intensity',
					description: 'Intensity of %value%',
					type: 'inc/dec',
					range: [1, 8],
					value: 4
				},
				{
					name: 'color',
					description: window.i18n.t('misc.color'),
					type: 'color',
					value: '#000000'
				}
			]
		});
	}

	if(options.miscEffects) {
		settings.push({
			description: 'Misc',
			type: 'section',
			settings: [
				{
					name: 'blur',
					description: '%value%% Blur',
					type: 'inc/dec',
					range: [0, 10],
					inc: 0.5,
					value: definition.blur ? definition.blur : 0,
					minDisplay: 'No',
					displayMultiplier: 10
				},
				{
					name: 'grayscale',
					description: 'Grayscale %value%',
					type: 'checkbox',
					value: definition.grayscale ? definition.grayscale : false
				},
				{
					name: 'invert',
					description: 'Invert %value%',
					type: 'checkbox',
					value: definition.invert ? definition.invert : false
				},
				{
					name: 'opacity',
					description: '%value%% Opacity',
					type: 'inc/dec',
					range: [5, 100],
					inc: 5,
					value: definition.opacity ? definition.opacity : 100
				},
				{
					name: 'saturate',
					description: '%value%% Saturation',
					type: 'inc/dec',
					range: [5, 200],
					inc: 5,
					value: definition.saturate ? definition.saturate : 100
				},
				{
					name: 'contrast',
					description: '%value%% Contrast',
					type: 'inc/dec',
					range: [10, 160],
					inc: 5,
					minDisplay: 'Min',
					value: definition.contrast ? definition.contrast : 100
				},
				{
					name: 'brightness',
					description: '%value%% Brightness',
					type: 'inc/dec',
					range: [10, 200],
					inc: 5,
					minDisplay: 'Min',
					value: definition.brightness ? definition.brightness : 100
				},
				{
					name: 'hue',
					description: '%value%\u00B0 Hue',
					type: 'inc/dec',
					range: [-180, 180],
					inc: 15,
					value: definition.hue ? definition.hue : 0
				}
			]
		});
	}

	return settings;
};

$.fn.SettingsBuilder = function(settings, options) {
	if(!this || !this.length) {
		console.warn('Failed to find SettingsBuilder to initialize');
		return this;
	}

	$.extend(this, {
		createSetting: function (setting, appendTo) {
			if ($.isArray(setting)) {
				setting = {
					type: 'section',
					settings: setting
				};
			}

			if (!setting.id && setting.name) {
				setting.id = setting.name;
			}
			var inputId = setting.id;
			if($.isInit(setting.subGroup)) {
				inputId += setting.subGroup;
			} else {
				inputId += '';
			}

			var value;
			if($.isInit(setting.value)) {
				value = setting.value;
			} else {
				if($.isInit(setting.defaultValue)) {
					value = setting.defaultValue;
				} else {
					value = '';
				}

				setting.value = '';
			}
			if (typeof setting.description == 'undefined') {
				setting.description = '';
			}
			var id = setting.id;

			let me = this, field, section, ruleType;
			if (setting.type == 'section' || setting.type == 'cards') {
				var settings = setting.settings;

				if (setting.description && !setting.collapsed) {
					var header = $('<h' + (setting.type == 'section' ? '5' : '4') + ' class="ui dividing header">');
					header.attr('id', 'setting-header-' + setting.id).text(setting.description).appendTo(appendTo);
				}

				var settingsLength = settings.length;
				for(let i = 0; i < settings.length; i++) {
					if(settings[i].hidden) {
						settingsLength--;
					}
				}

				if ((settings.length > 1 || setting.forceGroup) && !setting.noGrouping && setting.type == 'section') {
					var maxSettings = 2;
					if(settingsLength && settings[0].type == 'checkbox') {
						if(settingsLength == 6) {
							maxSettings = 3;
						} else if(settingsLength == 7 || settingsLength == 8) {
							maxSettings = 4;
						} else {
							maxSettings = 5;
						}
					} else if(settingsLength == 3) {
						maxSettings = 3;
					}

					// Require at least the settings amount and at least 2
					var sections = Math.max(2, Math.min(settingsLength, maxSettings));
					if(setting.perLine) {
						sections = setting.perLine;
					}

					section = $('<div class="' + $.numToWord(sections) + ' fields">').appendTo(appendTo);
					field = section;
				} else {
					section = appendTo;
				}

				if (setting.description && setting.collapsed) {
					var accordion = $('<div class="ui accordion field">');
					accordion.append('<div class="title"><i class="icon dropdown"></i>' + setting.description + '</div>');
					section = $('<div class="content field">').appendTo(accordion);
					accordion.appendTo(appendTo);

					if(setting.startsOpen) {
						accordion.children().addClass('active');
					}
					if(this.styledAccordions) {
						accordion.addClass('styled');
					}

					if(setting.exclusiveOpen !== false) {
						accordion.accordion({
							onOpening: function() {
								accordion.siblings('.accordion.field').each(function() {
									$(this).accordion('close', 0);
								});
							}
						});
					}
					field = accordion;
				}

				if (setting.group) {
					var settingVal = setting.value;
					if (settingVal) {
						settingVal = $.extend(true, {}, settingVal);
					}
					this.originalSettings[id] = settingVal;
					this.currentSettings[id] = this.originalSettings[id];
				}

				var sectionEnabled = true;
				if($.isInit(setting.enabled)) {
					sectionEnabled = setting.enabled;
				}
				if(settings.length) {
					for (let i = 0; i < settings.length; i++) {
						var childSetting = settings[i];
						this.createSubSetting(setting, childSetting, section, appendTo);

						if(childSetting.name == 'enabled') {
							sectionEnabled = childSetting.value;
						}
					}
				} else if(setting.emptyMessage) {
					$('<div class="ui warning visible message">').text(setting.emptyMessage).appendTo(appendTo);
				}

				if(setting.createSetting) {
					this.createSubSetting(setting, setting.createSetting, section, appendTo);
				}

				if(setting.group || $.isInit(setting.enabled)) {
					this.setSectionEnabled(section, sectionEnabled);
				}
			} else if (setting.type == 'header') {
				$('<h4 class="ui dividing header">')
					.text(setting.description)
					.appendTo(appendTo);
			} else if (setting.type == 'checkbox') {
				field = $('<div class="field checkboxField">');
				let label = $('<label>').appendTo(field);
				var checkbox = $('<div class="ui toggle checkbox checkboxSetting">')
					.append($('<input type="checkbox">').attr('name', setting.name))
					.appendTo(field);

				var values;
				if (setting.values) {
					values = setting.values;
				} else {
					values = {
						true: true,
						false: false
					};
				}
				if (value == 'true' || value === values.true) {
					checkbox.find('input').attr('checked', true);
				}

				checkbox.checkbox({
					onChecked: function () {
						me.setCurrentSetting(setting, values.true);

						if (setting.description.true) {
							label.text(setting.description.true);
						} else if(setting.description.indexOf('%value%') != -1) {
							label.text(setting.description.replace('%value%', 'On'));
						}
					},
					onUnchecked: function () {
						me.setCurrentSetting(setting, values.false);

						if (setting.description.true) {
							label.text(setting.description.false);
						} else if(setting.description.indexOf('%value%') != -1) {
							label.text(setting.description.replace('%value%', 'Off'));
						}
					},
					fireOnInit: $.isInit(setting.fireOnInit) ? setting.fireOnInit : false
				});
				checkbox.data('setting', setting);
				if (setting.value == 'true') {
					setting.value = true;
				}

				if(setting.readOnly) {
					field.addClass('readOnly');
					checkbox.addClass('read-only');
				}

				var description;
				if (setting.description.true) {
					if (setting.value === values.true) {
						description = setting.description.true;
					} else {
						description = setting.description.false;
					}
				} else {
					description = setting.description.replace('%value%', setting.value ? 'On' : 'Off');
				}
				label.text(description);

				field[0].setChecked = function(value) {
					if(value) {
						checkbox.checkbox('check');
					} else {
						checkbox.checkbox('uncheck');
					}
				};

				$(appendTo).append(field);
			} else if (setting.type == 'inc/dec') {
				var option = $('<div class="field incDecSetting">')[0];
				field = $(option);
				option.setting = setting;
				$(option).data('setting', setting);
				if (!setting.inc) {
					setting.inc = 1;
				}

				option.updateValue = function (value) {
					if (value === this.value) {
						return;
					}

					this.value = value;
					me.setCurrentSetting(setting, value);
					this.label.updateText();
					if(range) {
						range.range('set value', value);
					}
				};
				option.updateDisplayedValue = function (value) {
					if (value === this.value) {
						return;
					}

					this.value = value;
					me.setCurrentSetting(setting, value, {
						triggerCallbacks: false
					});
					this.label.updateText();
				};

				let label = $('<label>').appendTo(option)[0];
				label.updateText = function () {
					var description = setting.description;
					let value = option.value;
					if (value == setting.range[0]) {
						value = setting.minDisplay ? setting.minDisplay : 'Min';
						description = description.replace('%value%%', '%value%');
					} else if (value == setting.range[1]) {
						value = setting.maxDisplay ? setting.maxDisplay : 'Max';
						description = description.replace('%value%%', '%value%');
					} else if (setting.displayMultiplier) {
						value = value * setting.displayMultiplier;
					} else if(!value && setting.zeroDisplay) {
						description = setting.zeroDisplay;
					}

					$(this).text(description.replace('%value%', value));
				};
				option.label = label;

				let range;
				if($.fn.range) {
					range = $('<div class="ui range">').appendTo(option);

					let initialized = false;
					range.range({
						start: value,
						min: setting.range[0],
						max: setting.range[1],
						onChange: (val, meta) => {
							if(meta.triggeredByUser && initialized) {
								let newValue = Math.round(val / setting.inc) * setting.inc;
								option.updateValue(newValue);
							}
						}
					});
					initialized = true;
				}

				var minusButton = $('<div class="ui icon button"><i class="minus icon"></i></div>').appendTo(option);
				minusButton.click(function () {
					let value = option.value;
					value = Math.max(setting.range[0], value - setting.inc);

					option.updateValue(value);
				});
				var plusButton = $('<div class="ui icon button"><i class="plus icon"></i></div>').appendTo(option);
				plusButton.click(function () {
					let value = option.value;
					value = Math.min(setting.range[1], value + setting.inc);

					option.updateValue(value);
				});

				option.value = value;
				label.updateText();

				$(appendTo).append(option);
			} else if (setting.type == 'largeText') {
				field = $('<div class="inline field largeTextField">')
					.append($('<label>').text(setting.description));
				let box = $('<textarea>')
					.css('height', '8em')
					.attr('name', inputId)
					.text(value).keypress(function(e) {
						if((e.keyCode == 13 && e.shiftKey) || (e.keyCode == 10 && e.ctrlKey)) {
							if(options.onSubmit) {
								options.onSubmit.apply(me, arguments);
							}
						}
					});
				box.data('setting', setting);

				if(setting.placeholder) {
					box.attr('placeholder', setting.placeholder);
				}
				if(setting.maxLength) {
					box.attr('maxLength', setting.maxLength);
				}

				field.append(box).appendTo(appendTo);
			} else if (setting.type == 'text' || setting.type == 'email' || setting.type == 'url' || setting.type == 'hiddenText') {
				field = $('<div class="field textField">')
					.data('setting', setting)
					.append($('<label>').text(setting.description));

				var placeholder;
				if(setting.placeholder) {
					placeholder = setting.placeholder;
				} else if(setting.type == 'url') {
					placeholder = 'https://google.com/';
				} else {
					placeholder = setting.description;
				}

				let box = $('<input type="text" autocomplete="nope" />')
					.val(value)
					.attr('name', inputId)
					.attr('placeholder', placeholder);
				box.data('setting', setting);
				if(setting.type == 'hiddenText') {
					box.css({
						'-webkit-text-security': 'disc'
					});
				}
				if(setting.readOnly) {
					box.attr('readonly', 'readonly');
					field.addClass('readOnly');
				}

				ruleType = 'empty';
				if (setting.type == 'email') {
					ruleType = 'email';
				} else if(setting.type == 'url') {
					ruleType = 'url';
				} else if(setting.maxLength) {
					ruleType = 'maxLength[' + setting.maxLength + ']';
					box.attr('maxlength', setting.maxLength);
				}

				box.on('keyup', function () {
					me.setCurrentSetting(setting, this.value);
				});

				field.append(box).appendTo(appendTo);
			} else if (setting.type == 'positiveInt' || setting.type == 'positiveFloat') {
				field = $('<div class="field ' + setting.type + 'Field">');
				if(setting.description) {
					$('<label>').text(setting.description).appendTo(field);
				}
				let box = $('<input type="text" autocomplete="nope" />')
					.val(value)
					.attr('name', inputId);
				box.data('setting', setting);

				box.on('keyup', function () {
					value = this.value;
					if(value) {
						if(setting.type == 'positiveInt') {
							value = parseInt(value);
						} else if(setting.type == 'positiveFloat') {
							value = parseFloat(value);
						}
					}

					me.setCurrentSetting(setting, value);
				});

				if(setting.readOnly) {
					box.attr('readonly', 'readonly');
					field.addClass('readOnly');
				}

				field.append(box).appendTo(appendTo);

				if(setting.range) {
					ruleType = (setting.type == 'positiveInt' ? 'intRange[' : 'floatRange[') + setting.range[0] + '-' + setting.range[1] + ']';
				} else {
					ruleType = setting.type;
				}

				if(setting.fireOnInit && value) {
					window.setTimeout(function() {
						me.setCurrentSetting(setting, value);
					}, 10);
				}
			} else if (setting.type == 'color') {
				field = $('<div class="field colorField">')
					.append($('<label>').text(setting.description ? setting.description : window.i18n.t('misc.color')));
				var colorPicker;
				if ($.spectrum) {
					colorPicker = $('<input type="text" value="' + (value ? value : '#ff8040') + '" />');
					// Has to already be on the DOM to work
					window.setTimeout(function () {
						// Can't start disabled
						var disabled = false;
						if (colorPicker.attr('disabled') == 'disabled') {
							disabled = true;
							colorPicker.removeAttr('disabled');
						}

						colorPicker.spectrum({
							preferredFormat: 'rgb',
							showInitial: true,
							showInput: true,
							showPalette: !!$.DefaultColorPalette,
							palette: $.DefaultColorPalette ?? undefined,
							allowEmpty: setting.allowEmpty ? true : false,
							change: function (color) {
								me.setCurrentSetting(setting, color ? color.toHexString() : null);
							}
						});

						if (disabled) {
							colorPicker.attr('disabled', true);
							colorPicker.spectrum('disable');
						}
					}, 10);
				} else {
					colorPicker = $('<input type="color" value="' + (value ? value : '#ff8040') + '" />');
					colorPicker.on('change', function () {
						var color = $(this).val();
						me.setCurrentSetting(setting, color);
					});
				}
				colorPicker.data('setting', setting);

				field.append(colorPicker).appendTo(appendTo);
			} else if (setting.type == 'datetime' || setting.type == 'date') {
				field = $('<div class="field dateTimeField">')
					.append($('<label>').text(setting.description))
					.data('setting', setting);
				let box = $('<input type="text" />')
					.attr('name', inputId);

				if(setting.placeholder) {
					box.attr('placeholder', setting.placeholder);
				}

				var pickerOptions = {
					dateFormat: 'm/d/Y'
				};
				if(setting.type !== 'date') {
					pickerOptions.enableTime = true;
					pickerOptions.dateFormat += ' h:i K';
				}

				if(setting.readOnly) {
					box.attr('readonly', 'readonly');
					field.addClass('readOnly');
				}

				var shouldUpdateOtherPicker = false;
				pickerOptions.onChange = function (dates) {
					if(!dates) {
						return;
					}
					var date;
					if($.isArray(dates)) {
						date = dates[0];
					} else {
						date = dates;
					}
					if(!date) {
						return;
					}
					me.setCurrentSetting(setting, date.toISOString());

					if(shouldUpdateOtherPicker) {
						// Delay to make sure other element is initialized already
						window.setTimeout(function () {
							updateOtherPicker();
						}, 10);
					}
				};

				if(typeof setting.minDateFor == 'string' || typeof setting.maxDateFor == 'string') {
					shouldUpdateOtherPicker = true;
					window.setTimeout(function() {
						updateOtherPicker();
					}, 10);
				}
				if(setting.minDate) {
					pickerOptions.minDate = setting.minDate;
				}
				if(setting.maxDate) {
					pickerOptions.maxDate = setting.maxDate;
				}
				if(setting.fireOnInit && value) {
					window.setTimeout(function() {
						me.setCurrentSetting(setting, value);
					}, 10);
				}

				// eslint-disable-next-line
				function updateOtherPicker() {
					var id, dateParam;
					if (typeof setting.maxDateFor == 'string') {
						id = setting.maxDateFor;
						dateParam = 'maxDate';
					} else {
						id = setting.minDateFor;
						dateParam = 'minDate';
					}

					var otherPicker = $(me).find('#settings-' + id).data('flatpickr');
					if (otherPicker) {
						otherPicker.set(dateParam, picker.selectedDates[0]);
					}
				}

				field.append(box).appendTo(appendTo);
				ruleType = 'empty';

				var boxId = $.getGuid();
				box.attr('id', boxId).val(value ? new moment(value).toISOString() : '');
				
				if(!setting.readOnly) {
					var picker = flatpickr(box[0], pickerOptions);
					field.data('flatpickr', picker);
				} else {
					box.val(new moment(setting.value).format('M/D/Y'));
				}
			} else if(setting.type == 'dropdown') {
				let fieldLabel = $('<label>').text(setting.description);
				field = $('<div class="field dropdownField">')
					.append(fieldLabel);

				let dropdown = $('<div class="ui fluid loading button selection dropdown">');
				$('<input type="hidden" autocomplete="nope">').attr('name', inputId).appendTo(dropdown);
				var textLabel = $('<span class="text">').text(setting.placeholder ? setting.placeholder : setting.description).appendTo(dropdown);
				if(!value) {
					textLabel.addClass('default');
				}
				if(!setting.multiple) {
					dropdown.append('<i class="dropdown icon">');
				}
				dropdown.append('<div class="menu">');
				dropdown.data('setting', setting);

				if(setting.search) {
					dropdown.addClass('search');
				}

				let idField = setting.itemId ? setting.itemId : 'id';
				let nameField = setting.itemName ? setting.itemName : 'name';
				$.extend(field[0], {
					clearSelected: function() {
						dropdown.dropdown('set exactly', null);
					},
					setSelected: function(selected) {
						dropdown.dropdown('set selected', selected);
					},
					getSelected: function() {
						return dropdown.dropdown('get value');
					},
					setOptions: function(options, checkIfValueIsValid) {
						this.showOptions(options);
						this.currentOptions = options;

						// This should probably be default, but it is a fairly large change and not a whole lot of tests would cover this behavior
						if(checkIfValueIsValid) {
							let checkOptions = options.map(option => {
								if(typeof option === 'string') {
									return option;
								} else {
									return option[idField];
								}
							});
							let currentValue = this.getSelected();
							if(currentValue && !checkOptions.find(checkValue => checkValue == currentValue)) {
								this.clearSelected();
							}
						}
					},
					getOptions: function() {
						return this.currentOptions;
					},
					showOptions: function(options) {
						var menu = dropdown.find('.menu');
						menu.empty();

						const DOUBLE_WHITE_SPACE_REGEX = /\s\s+/g;
						for(let i = 0; i < options.length; i++) {
							var item = options[i];

							var id, name;
							if(typeof item == 'string') {
								id = name = item;
							} else {
								id = item[idField];

								name = item[nameField];
							}

							var itemDiv;
							if(setting.createCustomItem) {
								itemDiv = setting.createCustomItem(item);
							} else {
								itemDiv = $('<div class="item">')
									.attr('data-value', id)
									.text(name.replace(DOUBLE_WHITE_SPACE_REGEX, ' '))
									.data('item', item);
							}

							if($(itemDiv).attr('data-text')) {
								let text = $(itemDiv).attr('data-text');
								if(text.match(DOUBLE_WHITE_SPACE_REGEX)) {
									$(itemDiv).attr('data-text', text.replace(DOUBLE_WHITE_SPACE_REGEX, ' '));
								}
							}

							menu.append(itemDiv);
						}
					},
					removeFilter: function() {
						if(this.currentOptions) {
							this.showOptions(this.currentOptions);
						}
					},
					applyFilter: function(filterFunc) {
						if(this.currentOptions) {
							var options = [];
							for (let i = 0; i < this.currentOptions.length; i++) {
								var option = this.currentOptions[i];
								if (filterFunc.call(this, option)) {
									options.push(option);
								}
							}

							this.showOptions(options);
						}
					}
				});

				var dropdownData;
				var chain = new $.ExecutionChain(function(results) {
					if(results.errors) {
						$(dropdown).addClass('error');
						console.error('Failed to load');
						return;
					}

					if(setting.onAfterChainDone) {
						var ret = setting.onAfterChainDone(dropdownData);
						if(typeof ret != 'undefined') {
							dropdownData = ret;
						}
					}
					field[0].setOptions(dropdownData);

					var currentShowingSettings = null;
					var dropdownSettings = {
						onChange: function(value, text, elem) {
							var item;
							if(typeof elem == 'string') {
								// For multi select the arguments are actually (values, selectedValue, text)
								try {
									item = $(dropdown).find('.item[data-value="' + text.replace('\\', '\\\\').replace('"', '\\"') + '"]').data('item');
								} catch(e) {
									console.error('Failed to find item for ' + text);
								}
							} else {
								item = $(elem).data('item');
							}

							if(setting.onChange) {
								if(!$(field).isAttached()) {
									window.setTimeout(function() {
										setting.onChange.call(me, value, text, {
											setting: setting,
											field: field,
											appendTo: appendTo,
											item: item
										});
									}, 10);
								} else {
									setting.onChange.call(me, value, text, {
										setting: setting,
										field: field,
										appendTo: appendTo,
										item: item
									});
								}
							}

							if(currentShowingSettings) {
								me.hideSettingsByNames(currentShowingSettings);
								currentShowingSettings = null;
							}

							if(item && item.showSettings) {
								me.showSettingsByNames(item.showSettings);
								currentShowingSettings = item.showSettings;
							}
						}
					};
					if(setting.search) {
						$.extend(dropdownSettings, {
							fullTextSearch: 'exact',
							match: 'text'
						});
					}
					if(setting.multiple) {
						dropdown.addClass('search multiple');
						$.extend(dropdownSettings, {
							forceSelection: false
						});
					}
					if(setting.allowAdditions) {
						$.extend(dropdownSettings, {
							allowAdditions: true,
							forceSelection: true
						});
					}
					if(setting.dropdownSettings) {
						$.extend(dropdownSettings, setting.dropdownSettings);
					}
					dropdown.dropdown(dropdownSettings);

					if(value) {
						if(setting.multiple) {
							let values = value.split(',');
							// Single set selected is much faster but loses order - the assumption here is that a large list of organizations the order doesn't matter, but for a smaller set it does
							// If we change to an explicit opt-in, make sure we don't break layouts' Group By/Sort By again
							if(values.length > 100) {
								dropdown.dropdown('set selected', values);
							} else {
								for(let i = 0; i < values.length; i++) {
									dropdown.dropdown('set selected', values[i]);
								}
							}
						} else {
							dropdown.dropdown('set selected', value);
						}
					} else if(setting.defaultFirst && dropdownData.length) {
						let defaultItem = dropdownData[0];
						if(typeof setting.defaultFirst === 'string') {
							defaultItem = dropdownData.find(item => item[idField] == setting.defaultFirst || item[nameField] == setting.defaultFirst) ?? dropdownData[0];
						}
						dropdown.dropdown('set selected', defaultItem[idField]);
					}

					dropdown.find('input').attr('autocomplete', 'nope');
					dropdown.removeClass('loading button');
				});

				if(setting.loadAjax) {
					chain.add($.extend({
						dataType: 'json'
					}, setting.loadAjax, {
						success: function (data) {
							if(setting.loadSubParam) {
								data = data[setting.loadSubParam];
							}

							dropdownData = data;

							if(setting.loadAjax.success) {
								setting.loadAjax.success.apply(this, arguments);
							}
						}
					}));
				} else if(setting.loadFunction) {
					chain.add(function() {
						setting.loadFunction(chain, function(data) {
							if (setting.loadSubParam) {
								data = data[setting.loadSubParam];
							}

							dropdownData = data;
						});
					});
				} else if(setting.options) {
					chain.add(function() {
						dropdownData = setting.options;
					});
				} else {
					dropdownData = [];
				}

				if(setting.valueAjax) {
					chain.add($.extend({
						dataType: 'json'
					}, setting.valueAjax, {
						success: function(data) {
							if (setting.valueLoadSubParam) {
								data = data[setting.valueLoadSubParam];
							}

							if(setting.onAfterLoadValues) {
								var ret = setting.onAfterLoadValues.call(field[0], data);
								if(ret !== undefined) {
									data = ret;
								}
							}

							var values = [];
							for(let i = 0; i < data.length; i++) {
								values.push(data[i].id);
							}

							setting.value = value = setting.value = values.join(',');
						}
					}));
				}

				if(setting.onBeforeChainDone) {
					setting.onBeforeChainDone.call(this, chain);
				}

				chain.done();

				if(!setting.multiple) {
					ruleType = 'empty';
				}
				field.append(dropdown).appendTo(appendTo);

				if(setting.copyButton) {
					$('<div class="ui tiny circular icon button" style="margin-left: 0.5em"><i class="copy icon"/></div>').click(function() {
						let value = field[0].getSelected().replace(/&quot;/g, '"');
						let itemValues = [];
						if(value) {
							itemValues = value.split(',');
						}
						let items = itemValues;
						let options = field[0].getOptions();
						let idField = setting.itemId ? setting.itemId : 'id';
						if(options) {
							items = items.map(item => options.find(o => o[idField] === item));
						}

						setting.copyButton.onClick(field[0], itemValues, items);
					}).attr('data-tooltip', setting.copyButton.label ?? i18n.t('misc.copyToClipboard')).appendTo(fieldLabel);
				}
			} else if(setting.type == 'selectAll') {
				field = $('<div class="ui field selectAllField">');
				var selectAllCheckbox = $('<div class="ui checkbox"><input type="checkbox" class="hidden" tabindex="0"><label>Select All</label></div>');
				selectAllCheckbox.checkbox({
					fireOnInit: false,
					onChecked: function() {
						field.parent().find('.checkboxField').children('.checkbox').checkbox('set checked');
					},
					onUnchecked: function() {
						field.parent().find('.checkboxField').children('.checkbox').checkbox('set unchecked');
					}
				});
				// TODO: Show count
				// var selectCountLabel = $('<div class="ui teal tag label selectedCount">0 selected</div>');

				field.append(selectAllCheckbox).appendTo(appendTo);
			} else if(setting.type == 'radio') {
				field = $('<div class="ui field radioField">')
					.append($('<label>').text(setting.description))
					.data('setting', setting);
				
				setting.options.forEach(function(option) {
					var radioCheckbox = $('<div class="ui radio checkbox" style="display: block;">');

					var input = $('<input type="radio" class="hidden" tabindex="0">').appendTo(radioCheckbox);
					var idField = setting.itemId ? setting.itemId : 'id';
					input.attr('name', setting.id);
					input.attr('value', option[idField]);
					if((setting.value || setting.defaultValue) == option[idField]) {
						input.attr('checked', 'checked');
					}

					$('<label>').text(setting.label(option)).appendTo(radioCheckbox);
					radioCheckbox.appendTo(field);
				});
				field.find('.checkbox').checkbox({
					fireOnInit: false
				});

				field.appendTo(appendTo);
			} else if(setting.type == 'tagPicker') {
				field = $('<div class="ui field tagPickerField">')
					.append($('<label>').text(setting.description))
					.data('setting', setting);

				let dropdown = $('<div class="ui fluid selection search multiple dropdown">');
				$('<input type="hidden">').attr('name', inputId).appendTo(dropdown);
				$('<span class="default text">').text(setting.placeholder ? setting.placeholder : setting.description).appendTo(dropdown);
				dropdown.append('<div class="menu">');

				dropdown.dropdown({
					allowAdditions: true,
					forceSelection: true
				});

				if(value) {
					window.setTimeout(function() {
						for(let i = 0; i < value.length; i++) {
							dropdown.dropdown('set selected', value[i]);
						}
					}, 10);
				}

				if(setting.rule) {
					ruleType = 'commaDelimited[' + setting.rule + ']'
				}

				field.append(dropdown).appendTo(appendTo);
			} else if(setting.type == 'range') {
				field = $('<div class="ui field rangeField">')
					.append($('<label>').text(setting.description))
					.data('setting', setting);

				if(value) {
					if(typeof value == 'string') {
						setting.value = value = JSON.parse(value);
					}

					for (let i = 0; i < value.length; i++) {
						this.addRangeBound(field, setting, setting.subSetting, value[i], i);
					}
				}
				this.addRangeBound(field, setting, setting.subSetting, null, value ? (value.length + 1) : 0);

				field.appendTo(appendTo);
			} else if(setting.type == 'button') {
				field = $('<div class="ui field buttonField">')
					.data('setting', setting);

				if(setting.hasFieldLabel !== false) {
					field.append('<label>&nbsp;</label>');
				}

				var button;
				if(setting.href) {
					button = $('<a class="ui button">').attr('href', setting.href).appendTo(field);
				} else if(setting.label) {
					button = $('<div class="ui label">').appendTo(field);
				} else {
					button = $('<div class="ui button">').appendTo(field);
				}

				if(setting.icon) {
					if(!setting.label) {
						if(setting.href) {
							button.addClass('labeled');
						}
						
						button.addClass('icon');
					}
					$('<i class="icon">').addClass(setting.icon).appendTo(button);
				}

				if(setting.description) {
					button.append(setting.description);
				}

				if(setting.color) {
					button.addClass(setting.color);
				}
				if(setting.disabled) {
					button.addClass('disabled');
				}
				if(setting.popup) {
					button.attr('data-tooltip', setting.popup);
				}
				if(setting.onClick) {
					button.on('click', function() {
						setting.onClick.call(button);
					});
				}

				field.appendTo(appendTo);
			} else {
				console.warn('Invalid setting type: ' + setting.type);
			}

			if ($.isInit(setting.customRule)) {
				ruleType = setting.customRule;
			}

			if(ruleType) {
				var rules = [
					{
						type: ruleType
					}
				];
				if($.isArray(ruleType)) {
					rules = ruleType.map(function(rule) {
						return {
							type: rule
						}
					});
				}

				this.rules[inputId] = {
					identifier: inputId,
					rules: rules
				};
			}

			if (id && setting.value) {
				if(setting.group) {
					// Only want to setup currentSettings for sections and not ranges
					if(setting.parent && setting.parent.type === 'section') {
						if(this.originalSettings[setting.group]) {
							this.originalSettings[setting.group][setting.id] = setting.value;
						}

						if(this.currentSettings[setting.group]) {
							this.currentSettings[setting.group][setting.id] = setting.value;
						}
					}
				} else {
					this.originalSettings[id] = setting.value;
					this.currentSettings[id] = this.originalSettings[id];
				}
			}
			if (setting.id && field && field.attr) {
				field.attr('id', 'settings-' + setting.id);
			}

			if (setting.help) {
				var label, style = '';
				if (field) {
					label = field.find('label').first();
				} else if (section) {
					label = section.find('h4');
					style = 'margin-bottom: 0.5em';
				}

				$('<i class="blue help icon" style="' + style + '"></i>').popup({
					content: setting.help
				}).appendTo(label);
			}
			if(setting.disabled) {
				if(field) {
					field.addClass('disabled');
				}
			}

			if(setting.hidden) {
				this.hideSetting(setting, field);
			} else {
				$(field).addClass('visible');
			}

			var rootButtons = $(this).children('.button');
			if(rootButtons.length) {
				rootButtons.detach().appendTo(this);
			}
			if(field) {
				setting.field = field;
				$(field)[0].setting = setting;
			}

			if(setting.onCreate) {
				setting.onCreate.call(this, setting, field);
			}
		},
		createSubSetting: function(parentSetting, childSetting, section, appendTo, insertAfter) {
			childSetting.parent = parentSetting;
			if (parentSetting.group) {
				childSetting.group = parentSetting.group;
				if(parentSetting.groupArray) {
					if(parentSetting.groupArray === true) {
						parentSetting.groupArray = 0;
					}

					if($.isInit(childSetting.subGroup)) {
						parentSetting.groupArray = childSetting.subGroup;
					} else {
						childSetting.subGroup = parentSetting.groupArray;
						parentSetting.groupArray++;
					}
				} else if($.isInit(parentSetting.subGroup)) {
					childSetting.subGroup = parentSetting.subGroup;
				}
				childSetting.inserted = parentSetting.inserted;

				if (childSetting.name == 'enabled') {
					childSetting.value = parentSetting.value ? true : false;
					childSetting.onSettingChange = function (value) {
						me.setSectionEnabled(section, value);
					};
				} else if (parentSetting.value && !$.isInit(childSetting.value)) {
					childSetting.value = parentSetting.value[childSetting.name];
				}
			}

			var appendChildTo = section;
			if(parentSetting.type == 'cards') {
				var card = $('<div class="ui fluid card">');
				if(insertAfter) {
					card.insertAfter(insertAfter);
					var insertAfterSetting = $(insertAfter).data('setting');
					if($.isInit(insertAfterSetting.subGroup)) {
						childSetting.subGroup = insertAfterSetting.subGroup + 1;
						childSetting.inserted = true;
					}
					if($.isInit(insertAfterSetting.showNumber) && insertAfterSetting.showNumber !== true) {
						childSetting.showNumber = insertAfterSetting.showNumber + 1;
						this.addToCornerCountFromCard(card, 1);
					}
				} else {
					card.appendTo(appendTo);
				}
				card.data('setting', childSetting);
				appendChildTo = $('<div class="content">').appendTo(card);

				if(childSetting.actions) {
					var buttons = $('<div class="ui bottom attached buttons">');
					for (let i = 0; i < childSetting.actions.length; i++) {
						buttons.append(this.createCardAction(childSetting, childSetting.actions[i], section, appendTo, card));
					}

					card.append(buttons);
				}
				if(childSetting.cornerAction) {
					card.append(this.createCornerAction(childSetting, childSetting.cornerAction, card));
				}

				if($.isInit(childSetting.showNumber) && childSetting.showNumber !== false && $.isInit(childSetting.subGroup)) {
					var number;
					if(childSetting.showNumber !== true) {
						number = childSetting.showNumber;
					} else {
						number = childSetting.subGroup;
					}

					var numberLabel = $('<div class="ui floating circular green label itemNumberLabel">').text(number + 1);
					// Have to use setAttribute since even style[] = '500rem !important' doesn't work
					numberLabel[0].setAttribute('style', 'border-radius: 500rem !important; left: 0.5em;');
					card.append(numberLabel);
				}
			}

			this.createSetting(childSetting, appendChildTo);
		},
		createCardAction: function(setting, action, section, appendTo, card) {
			var button = $('<div class="ui button">').text(action.name);

			let me = this;
			if(action.onClick == 'add') {
				button.click(function() {
					me.createSubSetting(setting.parent, me.duplicateSetting(setting), section, appendTo, card);
					me.refreshRules();

					if(setting.cornerAction) {
						card.append(me.createCornerAction(setting, setting.cornerAction, card));
					}
				});
			}

			return button;
		},
		duplicateSetting: function(setting) {
			var duplicateSetting = {
				actions: setting.actions,
				cornerAction: setting.cornerAction,
				defaultValue: setting.defaultValue,
				description: setting.description,
				group: setting.group,
				id: setting.id,
				name: setting.name,
				options: setting.options,
				parent: setting.parent,
				type: setting.type,
				showNumber: setting.showNumber
			};

			if(setting.id == (setting.name + setting.subGroup)) {
				delete duplicateSetting.id;
			}
			if(setting.settings) {
				duplicateSetting.settings = [];
				for(let i = 0; i < setting.settings.length; i++) {
					duplicateSetting.settings.push(this.duplicateSetting(setting.settings[i]));
				}
			}

			return duplicateSetting;
		},
		createCornerAction: function(setting, action, card) {
			var button = $('<a class="ui tiny corner label">');
			$('<i class="icon">').addClass(action.icon).appendTo(button);
			if(action.color) {
				button.addClass(action.color);
			}
			if(action.name) {
				button.popup({
					content: action.name
				});
			}

			if(action.onClick == 'delete') {
				let me = this;
				button.click(function() {
					me.removeChanges.push(card.data('setting'));
					me.addToCornerCountFromCard(card, -1);
					card.remove();
				});
			}

			return button;
		},
		addToCornerCountFromCard: function(startCard, increment) {
			var nextCard = startCard.next();
			while(nextCard.length && nextCard.hasClass('card')) {
				let label = nextCard.children('.itemNumberLabel');
				if(label.length) {
					var oldLabel = parseInt(label.text());
					if(!isNaN(oldLabel)) {
						label.text(oldLabel + increment);
					}
				}

				nextCard = nextCard.next();
			}
		},
		addRangeBound: function(field, rangeSetting, subSetting, range, index) {
			var fields = $('<div class="three fields">');

			var lowerBoundField = $('<div class="field rangeBoundsField">').appendTo(fields);
			var lowerBoundName = rangeSetting.id + '-lower' + index;
			var lowerBoundInput = $('<input type="text" autocomplete="nope" />')
				.attr('name', lowerBoundName)
				.appendTo(lowerBoundField);
			if(range) {
				lowerBoundInput.val(range[0]);
			}
			fields.append('-');

			var upperBoundField = $('<div class="field rangeBoundsField">').appendTo(fields);
			var upperBoundName = rangeSetting.id + '-upper' + index;
			var upperBoundInput = $('<input type="text" autocomplete="nope" />')
				.attr('name', upperBoundName)
				.appendTo(upperBoundField);
			if(range) {
				upperBoundInput.val(range[1]);
			}
			fields.append('=');

			var rangeResultName = rangeSetting.id + '-result' + index
			this.createSubSetting(rangeSetting, $.extend(true, {
				id: rangeResultName,
				value: range ? range[2] : null,
				customRule: ''
			}, subSetting), fields);
			var subSettingClass = subSetting.type + 'Field';
			fields.find('.' + subSettingClass).removeClass(subSettingClass).addClass('rangeResultField');

			if(!range) {
				fields.find('input').on('change', function () {
					var changes = me.getChangeForRangePart(fields);
					if(changes) {
						// Create new one of these
						me.addRangeBound(field, rangeSetting, subSetting, null, index + 1);

						fields.find('input').off('change');
					}
				});
			}

			this.rules[lowerBoundName] = {
				identifier: lowerBoundName,
				rules: [
					{type: 'rangeValidation'}
				]
			};
			this.rules[upperBoundName] = {
				identifier: upperBoundName,
				rules: [
					{type: 'rangeValidation'}
				]
			};
			this.rules[rangeResultName] = {
				identifier: rangeResultName,
				rules: [
					{type: 'rangeValidation'}
				]
			};

			field.append(fields);
		},
		getFieldForSetting: function(setting) {
			return $('#settings-' + setting.id);
		},
		getFieldByName: function(name, groupName) {
			var scope = $(this);
			if(groupName) {
				scope = scope.find('#settings-' + groupName);
			}

			return scope.find('#settings-' + name)[0];
		},
		setSettingByName: function(name, value, groupName) {
			let field = this.getFieldByName(name, groupName);
			var setting = field.setting;

			if(['text', 'positiveInt', 'positiveFloat', 'hiddenText', 'url', 'email'].indexOf(setting.type) !== -1) {
				$(field).find('input').val(value);
				this.setCurrentSetting(setting, value);
			} else if (setting.type == 'largeText') {
				$(field).find('textarea').text(value);
				this.setCurrentSetting(setting, value);
			} else if(setting.type == 'dropdown') {
				field.setSelected(value);
			} else if(setting.type == 'checkbox') {
				field.setChecked(value);
			} else if(setting.type == 'inc/dec') {
				field.updateValue(value);
			}
		},
		addSetting: function(setting, parentSetting) {
			if(parentSetting) {
				if(parentSetting.settings.indexOf(setting) == -1) {
					parentSetting.settings.push(setting);
				}

				let section = this.getFieldForSetting(parentSetting);
				this.createSubSetting(parentSetting, setting, section, section);
			} else {
				this.settings.push(setting);
				this.createSetting(setting, $(this));
			}

			this.refreshRules();
		},
		addSettingAt: function(setting, parentSetting, index) {
			if(parentSetting) {
				if(parentSetting.settings.indexOf(setting) == -1) {
					parentSetting.settings.push(setting);
				}

				let section = this.getFieldForSetting(parentSetting);
				this.createSubSetting(parentSetting, setting, section, section);
			} else {
				this.settings.push(setting);
				this.createSetting(setting, $(this));
			}

			let field = $(this).find('#settings-' + setting.id);
			$(field).detach();
			$(field).insertAfter($(this).find('.field').eq(index));

			this.refreshRules();
		},
		removeSetting: function(setting) {
			var parentSettings = this.settings;
			if(setting.parent) {
				parentSettings = setting.parent.settings;
			}

			parentSettings.removeItem(setting);

			let field = this.getFieldForSetting(setting);
			if(field) {
				$(field).remove();
			}
		},
		removeSettingsByNames: function(names) {
			for(let i = 0; i < names.length; i++) {
				this.removeSettingByName(names[i]);
			}
		},
		removeSettingByName: function(name) {
			var setting = this.getSettingByName(name);
			if(setting) {
				this.removeSetting(setting);
			}
		},
		getSettingByName: function(name, scope) {
			if(!scope) {
				scope = this;
			}

			for(let i = 0; i < scope.settings.length; i++) {
				var setting = scope.settings[i];
				if(setting.id == name || setting.name == name) {
					return setting;
				} else if(setting.settings) {
					var found = this.getSettingByName(name, setting);
					if(found) {
						return found;
					}
				}
			}

			return null;
		},
		showSettingsByNames: function(names) {
			for(let i = 0; i < names.length; i++) {
				var name = names[i];
				this.showSettingByName(name);
			}

			this.refreshRules();
		},
		showSettingByName: function(name) {
			var setting = this.getSettingByName(name);
			if(setting) {
				this.showSetting(setting);
			} else {
				$.fireErrorReport(null, 'Invalid Setting', 'Unable to find a setting by the name ' + name);
			}
		},
		showSetting: function(setting, section) {
			setting.hidden = false;
			if(!section) {
				section = this.getFieldForSetting(setting);
			}

			section.addClass('visible').removeClass('hidden');

			if(this.hiddenRules[setting.id]) {
				this.rules[setting.id] = this.hiddenRules[setting.id];
				delete this.hiddenRules[setting.id];
			}

			if($.isInit(this.hiddenSettings[setting.id])) {
				this.currentSettings[setting.id] = this.hiddenSettings[setting.id];
				delete this.hiddenSettings[setting.id];
			}
		},
		hideSettingsByNames: function(names) {
			for(let i = 0; i < names.length; i++) {
				var name = names[i];
				this.hideSettingByName(name);
			}

			this.refreshRules();
		},
		hideSettingByName: function(name) {
			var setting = this.getSettingByName(name);
			if(setting) {
				this.hideSetting(setting);
			} else {
				$.fireErrorReport(null, 'Invalid Setting', 'Unable to find a setting by the name ' + name);
			}
		},
		hideSetting: function(setting, section) {
			setting.hidden = true;
			if(!section) {
				section = this.getFieldForSetting(setting);
			}

			section.removeClass('visible').addClass('hidden');

			if(this.rules[setting.id]) {
				this.hiddenRules[setting.id] = this.rules[setting.id];
				delete this.rules[setting.id];
			}

			if($.isInit(this.currentSettings[setting.id])) {
				this.hiddenSettings[setting.id] = this.currentSettings[setting.id];
				delete this.currentSettings[setting.id];
			}
		},
		setFieldsByNameEnabled: function(names, enabled) {
			let me = this;
			names.forEach(function(name) {
				me.setFieldByNameEnabled(name, enabled);
			});
		},
		setFieldByNameEnabled: function(name, enabled) {
			let field = this.getFieldByName(name);
			this.setFieldEnabled(field, enabled);			
		},
		setFieldEnabled: function(field, enabled) {
			var fields = $(field);
			if ($(this).hasClass('incDecSetting')) {
				fields = fields.add($(field).find('.button'));
			} else if ($(this).hasClass('checkbox')) {
				fields = fields.add($(field).find('.checkboxSetting'));
			} else if ($(field).hasClass('colorField')) {
				// TODO: Figure out and implement
				// $(this).attr('disabled', !enabled);
				// $(this).spectrum(enabled ? 'enable' : 'disable');
			} else {
				$(field).find('input').attr('disabled', !enabled);
			}

			if(enabled) {
				fields.removeClass('disabled');
			} else {
				fields.addClass('disabled');
			}
		},
		setSectionEnabled: function(section, enabled) {
			section.find('.field > .checkboxSetting, .field.incDecSetting, .field textarea, .field input, .field .ui.dropdown').each(function () {
				var childSetting = $(this).data('setting');
				if (childSetting && childSetting.name != 'enabled') {
					var field;
					if ($(this).hasClass('field')) {
						field = $(this);
					} else {
						field = $(this).closest('.field');
					}

					// TODO: Rewrite to use setFieldEnabled after doing some testing of it
					var fields = field;
					if ($(this).hasClass('incDecSetting')) {
						fields = fields.add($(this).find('.button'));
					} else if ($(this).hasClass('checkbox')) {
						fields = fields.add($(this));
					} else if ($(field).hasClass('colorField')) {
						$(this).attr('disabled', !enabled);
						$(this).spectrum(enabled ? 'enable' : 'disable');
					} else {
						$(this).attr('disabled', !enabled);
					}

					if (enabled) {
						fields.removeClass('disabled');
					} else {
						fields.addClass('disabled');
					}
				}
			});
		},
		setCurrentSetting: function (setting, value, options = {}) {
			if (setting.group) {
				if (setting.name == 'enabled') {
					if (value) {
						if (this.backedUpSettings[setting.group]) {
							this.currentSettings[setting.group] = this.backedUpSettings[setting.group];
						} else {
							var group = {};
							var settings = setting.parent.settings;
							for (let i = 0; i < settings.length; i++) {
								if (settings[i].name != 'enabled') {
									group[settings[i].name] = settings[i].value;
								}
							}

							this.currentSettings[setting.group] = group;
						}
					} else {
						this.backedUpSettings[setting.group] = this.currentSettings[setting.group];
						this.currentSettings[setting.group] = null;
					}
				} else {
					this.currentSettings[setting.group][setting.id] = value;
				}
			} else {
				this.currentSettings[setting.id] = value;
			}

			if(options.triggerCallbacks !== false) {
				if(setting.onSettingChange) {
					setting.onSettingChange.call(this, value);
				}
				if(this.onSettingChange) {
					this.onSettingChange(setting, value);
				}
			}
		},
		validateForm: function() {
			if (!$(this).form('validate form')) {
				return false;
			}

			return true;
		},
		getChanges: function () {
			if (!this.validateForm()) {
				return false;
			}

			var changes = [];

			let me = this;
			this.formDOM.find('.checkboxSetting').each(function () {
				let value = $(this).checkbox('is checked') ? true : false;
				var setting = $(this).data('setting');

				if ((!setting.values && (setting.value === true | setting.value == 'true') != value) ||
					(setting.values && setting.value !== value) || (setting.value === '' && $.isInit(setting.defaultValue))) {
					changes.push(me.getChangeForSetting(setting, value));
				}
			});
			this.formDOM.find('.incDecSetting').each(function () {
				if (this.setting.value != this.value) {
					changes.push(me.getChangeForSetting(this.setting, this.value));
				}
			});
			this.formDOM.find('.largeTextField textarea, .positiveIntField input, .positiveFloatField input, .colorField input, .textField input').each(function () {
				var setting = $(this).data('setting');
				let value = this.value;
				var settingValue = setting.value;
				if(setting.type == 'positiveInt') {
					if(value) {
						value = parseInt(value);
					}
					if(settingValue && typeof settingValue === 'string') {
						settingValue = parseInt(settingValue);
					}
				} else if(setting.type == 'positiveFloat') {
					value = parseFloat(value);

					if(settingValue && typeof settingValue === 'string') {
						settingValue = parseFloat(settingValue);
					}
				}

				if (settingValue !== value) {
					changes.push(me.getChangeForSetting(setting, value));
				}
			});
			this.formDOM.find('.dateTimeField').each(function () {
				var setting = $(this).data('setting');
				var picker = $(this).data('flatpickr');

				if(picker) {
					var date = (picker.selectedDates ? picker.selectedDates[0] : picker.selectedDateObj);
					let value = date ? date.toISOString() : null;
					if(setting.value != value) {
						changes.push(me.getChangeForSetting(setting, value));
					}
				}
			});
			this.formDOM.find('.dropdownField .ui.dropdown').each(function() {
				if($(this).hasClass('loading')) {
					return;
				}

				var setting = $(this).data('setting');
				let value = $(this).dropdown('get value');
				if(setting.allowAdditions) {
					var hiddenValue = $(this).find('input.search').val();
					if(hiddenValue) {
						value = hiddenValue;
					}
				}

				var finalValue = value;
				if (setting.value != value) {
					if(setting.multiple) {
						var startValues = [];
						if(setting.value) {
							startValues = setting.value.split(',');
						}
						var endValues = [];
						if(value) {
							endValues = value.replace(/&quot;/g, '"').split(',');
						}
						for (let i = 0; i < startValues.length; i++) {
							let value = startValues[i];
							var index = endValues.indexOf(value);
							if(index != -1) {
								// Execute in a loop so we can be sure to remove duplicates
								while((index = endValues.indexOf(value)) != -1) {
									endValues.splice(index, 1);
								}

								while((index = startValues.indexOf(value)) != -1) {
									startValues.splice(index, 1);
									i--;
								}
							}
						}

						if(setting.saveAll) {
							var change = me.getChangeForSetting(setting, finalValue);
							changes.push(change);
						} else {
							if (endValues.length) {
								var addChanges = me.getChangeForSetting(setting, endValues.join(','));
								addChanges.type = 'add';
								changes.push(addChanges);
							}
							if(startValues.length) {
								var deleteChanges = me.getChangeForSetting(setting, startValues.join(','));
								deleteChanges.type = 'remove';
								changes.push(deleteChanges);
							}
						}
					} else {
						changes.push(me.getChangeForSetting(setting, value.replace(/&quot;/g, '"')));
					}
				}
			});
			this.formDOM.find('.tagPickerField').each(function () {
				var setting = $(this).data('setting');
				var input = $(this).find('input')[0];
				let value = input.value;
				if(value) {
					value = value.split(',');
				} else {
					value = [];
				}

				if (!$(value).equals(setting.value) && (setting.value || value.length)) {
					changes.push(me.getChangeForSetting(setting, value));
				}
			});

			this.formDOM.find('.rangeField').each(function() {
				var setting = $(this).data('setting');

				var ranges = [];
				$(this).find('.three.fields').each(function() {
					var range = me.getChangeForRangePart(this);
					if(range) {
						ranges.push(range);
					}
				});

				if(!$.arrayEquals(ranges, setting.value)) {
					changes.push(me.getChangeForSetting(setting, ranges));
				}
			});

			this.formDOM.find('.radioField').each(function () {
				let value = $(this).find('.checkbox input').toArray().filter(function(input) {
					return input.checked
				})[0].value;
				var setting = $(this).data('setting');

				if(setting.value != value) {
					changes.push(me.getChangeForSetting(setting, value));
				}
			});

			for(let i = 0; i < this.removeChanges.length; i++) {
				var removeSetting = this.removeChanges[i];
				var change = this.getChangeForSetting(removeSetting, null);
				change.id = 'remove';
				changes.push(change);
			}

			// Remove any hidden fields - except those hidden by accordions closing
			this.formDOM.find('.field.hidden:not(.transition)').each(function() {
				var setting = $(this).data('setting');

				if(setting) {
					for (let i = 0; i < changes.length; i++) {
						var change = changes[i];
						if (change.id == setting.id) {
							changes.splice(i, 1);
							i--;
						}
					}
				}
			});

			if(this.hardCodedChanges) {
				$.merge(changes, this.hardCodedChanges);
			}

			return changes;
		},
		getChangeForRangePart: function(fields) {
			var range = [];

			$(fields).find('input').each(function() {
				let value = parseFloat(this.value);
				if (!isNaN(value)) {
					range.push(value);
				}
			});

			if (range.length === 3) {
				return range;
			} else {
				return null;
			}
		},
		getChangeForSetting: function(setting, value) {
			var change = {
				id: setting.id,
				value: value
			};

			if(setting.group) {
				change.group = setting.group;

				if($.isInit(setting.subGroup)) {
					change.subGroup = setting.subGroup;

					if(setting.inserted) {
						change.inserted = true;
					}
				}
			}

			return change;
		},
		getChangesAsObject: function() {
			let changes = this.getChanges();
			let obj = {};
			changes.forEach(change => {
				obj[change.id] = change.value;
			});

			return obj;
		},
		applyChanges: function (changes) {
			for (let i = 0; i < changes.length; i++) {
				this.applyChange(changes[i]);
			}

			this.hardCodedChanges = [];
		},
		applyChange: function (change, settings) {
			if (!settings) {
				settings = this.settings;
			}

			for (let i = 0; i < settings.length; i++) {
				var setting = settings[i];
				if (setting.type == 'section' || setting.type == 'cards') {
					if (this.applyChange(change, setting.settings)) {
						return true;
					}
				} else if ($.isArray(setting)) {
					if (this.applyChange(change, setting)) {
						return true;
					}
				} else if (change.id == setting.id && change.group == setting.group && change.subGroup == setting.subGroup) {
					var value;
					if(change.type) {
						value = [];
						if(setting.value) {
							value = setting.value.split(',');
						}

						if(change.type == 'add') {
							$.merge(value, change.value.split(','));
						} else if(change.type == 'remove') {
							var removes = change.value.split(',');
							for(var j = 0; j < removes.length; j++) {
								value.removeItem(removes[j]);
							}
						}
						value = value.join(',');
					} else {
						value = change.value;
					}

					setting.value = value;
					if(change.group) {
						if(this.currentSettings[change.group]) {
							this.currentSettings[change.group][change.id] = value;
						}
					} else {
						this.currentSettings[setting.id] = value;
					}

					if (this.updateObject) {
						var updateObj = this.updateObject;
						if(setting.group) {
							updateObj = updateObj[setting.group];

							if($.isInit(setting.subGroup)) {
								updateObj = updateObj[setting.subGroup];
							}
						}
						updateObj[setting.id] = value;
					}
					return true;
				}
			}

			return false;
		},
		refreshRules: function() {
			$(this).form({
				fields: this.rules
			});
		},
		getSettings: function() {
			return this.currentSettings;
		},
		removeChanges: [],
		hardCodedChanges: []
	});

	if(!options) {
		options = {};
	}

	this.onSettingChange = options.onSettingChange;

	if(options.autoFormatColumns) {
		// Break everything into sections
		var sections = {};
		for(let i = 0; i < settings.length; i++) {
			let setting = settings[i];

			if(typeof setting.extras == 'string') {
				var extras = JSON.parse(setting.extras);
				$.extend(setting, extras);
			}

			if(!sections[setting.section]) {
				sections[setting.section] = {
					type: 'section',
					settings: [],

					sortSection: parseInt(setting.section)
				};
			}

			let section = sections[setting.section];
			if(setting.type == 'header') {
				section.description = setting.description;

				if(setting.value == 'true') {
					section.collapsed = true;
				}
				if(setting.noGrouping) {
					section.noGrouping = true;
				}
			} else {
				section.settings.push(setting);
			}
		}

		// Reformat sections in settings
		var newSettings = [];
		for(let i in sections) {
			let section = sections[i];
			if(section.collapsed && options && options.hideCollapsed) {
				continue;
			}
			section.id = 'section-' + i;

			newSettings.push(section);
		}
		if(options.autoSortColumns) {
			newSettings.sort((a, b) => a.sortSection - b.sortSection);
		}

		settings = newSettings;
	}
	if(options.styledAccordions) {
		this.styledAccordions = options.styledAccordions;
	}

	var form = $(this);
	let me = this;
	this.formDOM = form;
	this.settings = settings;
	this.originalSettings = {};
	this.currentSettings = {};
	this.backedUpSettings = {};
	this.rules = {};
	this.hiddenRules = {};
	this.hiddenSettings = {};

	var appendTo = form;
	if(options.groupInFields) {
		appendTo = $('<div class="fields">').addClass($.numToWord(options.groupInFields)).appendTo(form);
	}
	for(let i = 0; i < settings.length; i++) {
		let setting = settings[i];
		this.createSetting(setting, appendTo);
	}

	if(options.formRules) {
		this.rules = $.extend(true, {}, this.rules, options.formRules);
	}
	form.addClass('settings-builder');
	this.refreshRules();

	form.on('submit', function() {
		// Want exactly one submit button (more than one means we have no idea what the other one is)
		if(options.onSubmit) {
			options.onSubmit.apply(me, arguments);
		} else {
			var submitButton = form.find('.submit.primary.button');
			if (submitButton.length === 1) {
				submitButton.click();
			}
		}
		return false;
	});

	// TODO: Find all uses of this and convert over
	if(options.cancel) {
		options.onCancel = options.cancel;
	}
	if(options.onCancel || options.onDone) {
		$('<div class="ui button">').text(i18n.t('misc.cancel')).click(function(){
			if(options.onCancel) {
				options.onCancel();
			}
			if(options.onDone) {
				options.onDone();
			}
		}).appendTo(form);
	}

	if(options.saveUrl || options.onSaveAjax) {
		var onSave = options.onSave;

		options.onSave = function(changes, success, error) {
			var postData = {};
			if(options.postObject) {
				var obj = {};
				for(let i = 0; i < changes.length; i++) {
					var change = changes[i];
					var changeObj = obj;
					if(change.group) {
						if(!obj[change.group]) {
							obj[change.group] = {};
						}

						changeObj = obj[change.group];

						if($.isInit(change.subGroup)) {
							if(!changeObj[change.subGroup]) {
								changeObj[change.subGroup] = {};
							}
							changeObj = changeObj[change.subGroup];
						}
					}
					changeObj[change.id] = change.value;
				}
				postData[options.postObject] = obj;
			} else {
				postData.settings = changes;
			}
			if (options.posts) {
				for (let i in options.posts) {
					postData[i] = options.posts[i];
				}
			}

			if(options.onBeforePostData) {
				options.onBeforePostData.call(me, postData);
			}

			if(options.loader) {
				options.loader.addClass('active');
			}
			var ajax;
			if(options.saveUrl) {
				ajax = {
					url: options.saveUrl,
					type: 'post'
				};
			} else if(options.onSaveAjax) {
				ajax = $.extend({
					dataType: 'json'
				}, options.onSaveAjax);
			}

			ajax.data = postData;
			ajax.success = function() {
				success();

				if(onSave) {
					onSave.apply(me, arguments);
				}
				
				if(options.loader) {
					options.loader.removeClass('active');
				}
			};
			ajax.error = function() {
				error.apply(this, arguments);

				if(options.loader) {
					options.loader.removeClass('active');
				}
			};

			$.ajax(ajax);
		};
	}

	if(options.onSave) {
		var saveText = i18n.t('misc.save');
		if(options.onSaveLabel) {
			saveText = options.onSaveLabel;
		}

		$('<div class="ui submit primary button">').text(saveText).click(function () {
			if(!me.validateForm()) {
				return false;
			}

			form.find('.warning.message').remove();
			var changes = me.getChanges();

			if (!changes.length) {
				if(options.onCancel || options.onDone) {
					if(options.onCancel) {
						options.onCancel();
					}

					if(options.onDone) {
						options.onDone();
					}
				} else {
					form.append('<div class="ui warning message visible"><div class="header">' + i18n.t('misc.noChangesToSave') + '</div></div>');
				}
				return false;
			}

			var button = $(this);
			button.addClass('loading');
			options.onSave.call(me, changes, function() {
				button.removeClass('loading');
				me.applyChanges(changes);

				if(options.onDone) {
					options.onDone();
				}
			}, function() {
				button.removeClass('loading');
				if(options.onError) {
					options.onError.apply(options, arguments);
				} else {
					$.Alert('Error', 'Failed to save settings');
				}
			});
			return false;
		}).appendTo(form);
	}

	if(options.updateObject) {
		this.updateObject = options.updateObject;
	}

	return this;
};