// Defines a PageLayout object, which holds a single page
const LONG_DATE_FORMAT = 'MMMM D, YYYY';
const SHORT_DATE_FORMAT = 'M/D/YYYY';
const TIME_FORMAT = 'hh:mm A';

$.FlowLayout = function(options) {
	if(!options) {
		options = {};
	}

	var DEFAULT_CELL_PADDING = 0.05;
	var DEFAULT_CANVAS_SIDE_PADDING = 0.5;

	var wrapper = document.createElement('div');
	wrapper.className = 'flowPage';

	$.extend(wrapper, {
		isClass: function () {
			if (this.page) {
				return this.page.type == 'class' || this.page.type == 'classOverflow';
			} else {
				return false;
			}
		},
		isClassSizeAvailable: function() {
			if(this.isClass()) {
				var pageSet = this.getPageSet();
				return !pageSet || pageSet.isClassSizeAvailable();
			} else {
				return false;
			}
		},
		getPage: function () {
			return this.page;
		},
		getRootPage: function() {
			let rootPage = this.getPage();
			if(rootPage && rootPage.getRootPage) {
				rootPage = rootPage.getRootPage();
			}

			return rootPage;
		},
		setPage: function (page, forceSet, extras) {
			if(page && page.viewingVersion && page.alternativeVersions[page.viewingVersion]) {
				page = page.alternativeVersions[page.viewingVersion];
			}

			if (this.page == page && !forceSet) {
				return;
			}
			let focusedElement = null;
			let secondaryFocusedElements = [];
			if(this.page === page) {
				focusedElement = $(this).find('.flowContentFocused')[0];
				secondaryFocusedElements = $(this).find('.flowLayoutSecondaryFocused').toArray();
			}

			this.removeCurrentLayout();
			this.setLabelError(false);
			this.page = page;

			this.largeCellUser = -1;
			if(page && page.hasPrimarySubject && page.hasPrimarySubject()) {
				this.primarySubjectIndex = 0;
			} else {
				this.primarySubjectIndex = null;
			}
			if (this.childLayout) {
				this.childLayout.parentLayout = null;
				this.childLayout = null;
			}
			if (this.parentLayout) {
				this.parentLayout.childLayout = null;
				this.parentLayout = null;
			}
			if (page != null) {
				$(this).show();
				this.visible = true;
				this.setEditable(this.mainEditable, !page.getLocked(), true);

				let layout = this.getLayout();
				if (page.type == 'class' && layout && !layout.cell) {
					layout = $.extend(true, {}, $.DEFAULT_CLASS_LAYOUT);
				}

				if (this.blockingLoad) {
					this.stopLoading(false);
				}
				if (layout) {
					this.setLayout(layout, {
						firstLayout: ((!extras || !extras.blockFirstLayout) ? true : false),
						keepGroupedEvents: (extras ? extras.keepGroupedEvents : undefined),
						triggerOverflowPrompt: (extras ? extras.triggerOverflowPrompt : undefined)
					});

					this.gridLines.setSettings(this.gridLineSettings);
				} else {
					// Make sure we have a height
					this.setupInchDimensions();
					this.setupOuterSize();

					// Default to a hard coded ratio
					if (!this.ratio) {
						// Setup width/height + ratio for starting sizes
						this.setRatio($(this).height() / this.inchHeight);

						var margin = this.ratio * this.inchBleed.top - 1;
						// Height is wrapper - (margins - 2) for 1px borders
						$(this.container).css({
							'margin': margin,
							'height': $(this).height() - (margin * 2),
							'width': $(this).width() - (margin * 2)
						});
					}

					this.addFrames();
					this.addTexts();
					this.postAddedContent();
					this.updatePageMargins();
					this.gridLines.setEnabled(false);
				}

				this.updateTheme();

				if (page.type == 'classOverflow' && this.parent) {
					var parent = this.parent.getPreviousVisiblePage(this);
					this.parentLayout = parent;
					if (parent) {
						parent.childLayout = this;
					}
				}

				if (!this.fixedSize) {
					if (page.updatePageLabel) {
						page.updatePageLabel();
					}

					this.setLabel(page.getPageLabel());
					this.setCommentsBar(page.isCommentsVisible(), page.isCommentsEditable());
				}

				if(this.editable) {
					this.updateAllUsersSelection();
				}

				if(this.shouldHideInsidePageBorder || page.shouldHideInsidePageBorder()) {
					$(this).addClass('hideInsidePageBorder');
				} else {
					$(this).removeClass('hideInsidePageBorder');
				}

				// If we are refreshing the existing page, keep this focused
				if(focusedElement) {
					let refocusElement = $(this).find('#' + $(focusedElement).attr('id'))[0];
					if(refocusElement) {
						refocusElement.setFocused(true, true);

						if(focusedElement.selectionPosition && refocusElement.selectionPosition !== undefined) {
							refocusElement.selectionPosition = focusedElement.selectionPosition;
							refocusElement.selectionLength = focusedElement.selectionLength;
							refocusElement.selectionLine = focusedElement.selectionLine;
							refocusElement.updateCursorPosition();
						}
					}

					secondaryFocusedElements.forEach(secondaryElement => {
						let refocusElement = $(this).find('#' + $(secondaryElement).attr('id'))[0];
						if(refocusElement) {
							refocusElement.setSecondaryFocused(true);

							if(secondaryElement.selectionPosition && refocusElement.selectionPosition !== undefined) {
								refocusElement.selectionPosition = secondaryElement.selectionPosition;
								refocusElement.selectionLength = secondaryElement.selectionLength;
								refocusElement.selectionLine = secondaryElement.selectionLine;
								refocusElement.updateCursorPosition();
							}
						}
					});
				}
				this.setupCustomUserLabel();
			} else {
				this.setTitle(null);
				this.setLayout({});
				this.setTheme(null);
				this.setLabel(null);

				if (this.blockingLoad) {
					this.stopLoading(false);
				}
				this.gridLines.setEnabled(false);
			}

			$(this.parent ? this.parent.pages : this).removeClass('dragging-onto dragging-spread');
		},
		refreshPage: function(extras) {
			if(this.page) {
				this.setPage(this.page, true, extras);
			}
		},
		// NOTE: This refresh should only update what NEEDs to be changed in order to not distrubt the users current view
		refreshSimplePage: function() {
			let page = this.getPage();
			if(!page) {
				return;
			}
			
			this.updatePageNumber(page.getPageNumber());
		},
		setupOuterSize: function() {
			if(this.fixedSize) {
				return;
			}

			var grandParent = $(this).parent().parent();
			// Temp set overflow hidden so we don't end up with growing canvas with 90% browser zoom
			grandParent[0].style.overflow = 'hidden';
			// User can resize window so we end up with a negative width/height here
			let outerWidth = grandParent.outerWidth();
			let outerWidthPadding = 60;
			if(outerWidth < 600) {
				outerWidthPadding = 20;
			}
			var parentWidth = Math.max(10, outerWidth - outerWidthPadding);
			var parentHeight = Math.max(10, grandParent.outerHeight() - 6);
			var calcWidth = parentHeight * (this.inchWidth / this.inchHeight);

			var parentPages = 1, filledPages = 1;
			if (this.parent) {
				parentPages = this.parent.pages.length;

				filledPages = this.parent.pages.filter(function(page) {
					return !!page.page;
				}).length;
			}
			var zoomFactor = this.zoom / 100;

			// If this would be too wide, recalculate
			var calcHeight = parentHeight;
			if ((calcWidth * parentPages) > parentWidth) {
				var oldWidth = calcWidth;

				calcWidth = parentWidth / parentPages;
				calcHeight = parentHeight * (calcWidth / oldWidth);
			}
			$(this).height(calcHeight * zoomFactor);
			$(this).width(calcWidth * zoomFactor);
			grandParent[0].style.overflow = '';

			// Turn off automatic vertical centering when scrolling
			var allowScroll = false;
			if((calcHeight * zoomFactor) > parentHeight) {
				$(this).parent().css({
					'align-items': 'initial',
					'align-self': 'start',
					'padding-bottom': '1.2em'
				});
				allowScroll = true;
			} else {
				$(this).parent().css({
					'align-items': '',
					'align-self': '',
					'padding-bottom': '',
					'margin-left': '',
					'margin-right': ''
				});
			}

			if((calcWidth * filledPages * zoomFactor) > parentWidth) {
				$(this).parent().css({
					'justify-items': 'initial',
					'justify-content': 'initial'
				});

				$(grandParent).css({
					'padding-left': '8em',
					'justify-content': 'start'
				});

				allowScroll = true;
			} else {
				$(this).parent().css({
					'justify-items': '',
					'justify-content': ''
				});

				$(grandParent).css({
					'padding-left': '',
					'justify-content': ''
				});
			}

			if(allowScroll) {
				$(grandParent).css({
					overflow: 'auto'
				});
			} else {
				$(grandParent).css({
					overflow: ''
				});
			}
		},

		loadLayout: function (definition, onComplete, options = {}) {
			// Delay so everything is smooth
			let layout = this;
			if (layout.parentLayout) {
				layout = layout.parentLayout;
			}
			layout.startLoading();

			var startPage = layout.getPage();
			window.setTimeout(function () {
				// If definition is passed in, update page definition
				if (definition) {
					let rootPage = layout.getPage();
					if(rootPage != startPage) {
						layout.stopGroupedEvents();
						layout.stopLoading();
						return;
					}

					if (rootPage.getRootPage) {
						rootPage = rootPage.getRootPage();
					}

					// Check if there exists a mask already to apply to new layout
					var oldLayout = $.extend(true, {}, rootPage.getLayout());
					definition = $.extend(true, {}, definition);
					if (oldLayout && oldLayout.cell && definition.cell) {
						layout.initLayoutDefinition(definition);
						if (oldLayout.cell.mask) {
							definition.cell.mask = oldLayout.cell.mask;
						}

						// Update this.cells
						layout.getCellSizes(definition);

						if (oldLayout.cellSize && definition.cell.default) {
							definition.cell.default = oldLayout.cellSize;
						} else if (oldLayout.cellCols) {
							// When coming from a different layout we want to make sure we have some starting info as part of the layout
							let cells = layout.getCellSizes(definition);
							var baseDefinition = cells[definition.cell.default];
							if(baseDefinition) {
								$.extend(true, definition.cell, baseDefinition);
							}

							definition.cell.default = null;
							layout.calculateCellsToColumns(definition, oldLayout.cellCols, oldLayout.cellRows, false);
						}

						var effects = layout.getSubjectEffects();
						if(effects) {
							definition = layout.updateLayoutAfterEffects(definition, null, effects) || definition;
						}

						if(rootPage.shouldKeepGridSize()) {
							if ((!definition.grid || $.getObjectCount(definition.grid) === 0) && oldLayout.grid) {
								definition.grid = $.extend(true, {}, oldLayout.grid);
							}
						}
						// If we are dropping a saved layout we need to make sure we aren't saving grid size info from saved layout
						else {
							delete definition.grid;
						}

						if(oldLayout.name && definition.name) {
							if(rootPage.shouldKeepNameOrder()) {
								if(oldLayout.name.order) {
									definition.name.order = oldLayout.name.order;
								}
								for(let i = 2; oldLayout.name['order' + i]; i++) {
									definition.name['order' + i] = oldLayout.name['order' + i];
								}
							}
						}
					}
					let hasNullTitle = definition.title === null;
					layout.loadLayoutPageProperties(rootPage, definition);
					if(rootPage.clearAllSubjectCellDataByNames) {
						rootPage.clearAllSubjectCellDataByNames(['imgWrapperPosition', 'imgWrapperSize']);
					}

					var pageSet = layout.getPageSet();
					let stopGroupedEvents = true;
					if(pageSet && pageSet.setLayout) {
						oldLayout = $.extend(true, {}, pageSet.getLayout());
						pageSet.setLayout(definition);

						layout.setLayout(pageSet.getLayout(), {
							keepGroupedEvents: false
						});

						if ($.userEvents && JSON.stringify(oldLayout) != JSON.stringify(definition)) {
							$.userEvents.addEvent({
								context: ['pageSet', 'layout'],
								action: 'update',
								args: [oldLayout, definition]
							});
						}
					} else {
						rootPage.setLayout(definition);
						if (rootPage.setLargeCellPosition) {
							rootPage.setLargeCellPosition(null);
							layout.largeCellUser = -1;
						}
						if(rootPage.resetBlankTitle && !hasNullTitle) {
							rootPage.resetBlankTitle(definition.title);
						}

						let page = layout.getPage();
						if(page == rootPage) {
							layout.setLayout(rootPage.getLayout(), {
								keepGroupedEvents: false,
								triggerOverflowPrompt: options.triggerOverflowPrompt
							});
						} else {
							let overflowPage = rootPage.getOverflowPage();
							while(overflowPage) {
								overflowPage.setKids?.(null);
								overflowPage.setMaxCells?.(null);
								overflowPage.setViableCells?.(null);
								overflowPage = overflowPage.getOverflowPage();
							}
							layout.parent.updatePagesAfterChange(true, () => {
								if(!layout.parent?.userPromptedForRollback) {
									layout.stopGroupedEvents();
								}
							}, {
								pageOptions: {
									keepGroupedEvents: false,
									triggerOverflowPrompt: options.triggerOverflowPrompt
								},
								skipStopGroupedEvents: options.triggerOverflowPrompt
							});
							stopGroupedEvents = false;
						}

						if ($.userEvents && JSON.stringify(oldLayout) != JSON.stringify(definition)) {
							$.userEvents.addEvent({
								context: [rootPage, 'layout'],
								action: 'update',
								args: [oldLayout, definition]
							});
						}
					}

					if(!layout.parent?.userPromptedForRollback && stopGroupedEvents) {
						layout.stopGroupedEvents();
					}
				} else {
					// Otherwise just apply existing one
					layout.setLayout(layout.getLayout(), {
						triggerOverflowPrompt: options.triggerOverflowPrompt
					});
				}
				layout.updateTheme();

				if (onComplete) {
					onComplete.call(layout);
				}

				let page = layout.getPage();
				if (page.updatePageLabel) {
					page.updatePageLabel();
					layout.setLabel(page.getPageLabel());
				}

				// Handle child linkage for everything but classes which already do it separately
				if(page.type.indexOf('class') == -1 && page.getOverflowPage) {
					var overflowPage = page.getOverflowPage();
					if(overflowPage) {
						// New page added
						if(!layout.childLayout) {
							var nextLayout = layout.parent.getNextVisiblePage(layout);
							if(nextLayout) {
								layout.childLayout = nextLayout;
								nextLayout.parentLayout = layout;

								nextLayout.setPage(overflowPage);
							}
						}
					} else if(layout.childLayout) {
						// Existing page removed
						// This should only update the overflow page
						layout.parent.updatePagesAfterChangeImmediately();
					}
				}

				layout.stopLoading();
			}, 100);
		},
		loadLayoutPageProperties: function(page, definition) {
			var properties = [
				{
					name: 'theme',
					setter: 'setTheme'
				},
				{
					name: 'studentLabelCSS',
					setter: 'setStudentLabelCSSStyles'
				},
				{
					name: 'studentMask',
					setter: 'setStudentMask'
				},
				{
					name: 'pageMargins',
					setter: 'setPageMargins'
				},
				{
					name: 'subjectEffects',
					setter: 'setSubjectEffects'
				},
				{
					name: 'backgroundSettings',
					setter: 'setBackgroundSettings'
				},
				{
					name: 'title',
					setter: 'setTitle'
				}
			];

			properties.forEach(function(prop) {
				var name = prop.name;
				var setter = prop.setter;

				if(typeof definition[name] != 'undefined' && page[setter]) {
					let value = definition[name];
					if(name === 'title' && value && !value.lines) {
						value = {
							lines: value
						};
					}

					page[setter](value);
					delete definition[name];
				}
			});

			var groupedElements = this.getGroupedElementsFromLayout(definition);
			var images = null;
			if (definition.frames) {
				images = definition.frames;
				definition.frames = null;
			} else if (definition.candids) {
				images = definition.candids;
				definition.candids = null;
			} else if(definition.images) {
				images = definition.images;
				definition.images = null;
			}

			if(images) {
				if($.isArray(images)) {
					var newImages = {};
					for (let i = 0; i < images.length; i++) {
						var image = images[i];
						var id = $.getGuid();
						image.id = id;
						newImages[id] = image;
					}

					images = newImages;
				} else {
					images = this.updateWithUniqueIds(images, groupedElements);
				}

				page.jsonReplace('candids', images);
			}

			if(definition.texts) {
				var texts = definition.texts;
				if ($.isArray(texts)) {
					var newTexts = {};
					for (var j = 0; j < texts.length; j++) {
						let text = texts[j];
						text.id = $.getGuid();
						newTexts[text.id] = text;
					}
					texts = newTexts;
				} else {
					texts = this.updateWithUniqueIds(texts, groupedElements);
				}
				
				page.jsonReplace('texts', texts);
				definition.texts = null;
			}

			if(definition.extras && $.isPlainObject(definition.extras) && $.getObjectCount(definition.extras) > 0) {
				for(var name in definition.extras) {
					page.setExtraProperty(name, definition.extras[name]);
				}
				definition.extras = null;
			}
		},
		getLayout: function () {
			var page, layout;
			if (this.page && this.page.getLayout()) {
				page = this.page;
				layout = this.page.getLayout({
					includeWhiteSpace: this.includeWhiteSpace
				});
			} else if (this.getPageSet() && this.getPageSet().getLayout) {
				layout = this.getPageSet().getLayout();
				if (!layout) {
					return null;
				}

				page = this.getPage();
				if (page && page.getStudentMask && layout.cell) {
					layout.cell.mask = page.getStudentMask();
				}
			}

			if (page && page.getLargeCellPosition && page.getLargeCellPosition() && layout.largeCell) {
				var largeCellPosition = page.getLargeCellPosition();
				if (largeCellPosition && $.isInit(largeCellPosition.col) && layout.largeCell.col != 'center') {
					layout.largeCell.col = largeCellPosition.col;

					if (typeof layout.largeCell.col === 'number' && layout.largeCell.col < 0) {
						layout.largeCell.col = 0;
					}
				}

				layout.largeCell.row = largeCellPosition.row;
				if (typeof layout.largeCell.row === 'number' && layout.largeCell.row < 0) {
					layout.largeCell.row = 0;
				}
			}

			return layout;
		},
		initLayoutDefinition: function(definition) {
			if (!definition.schema) {
				definition.schema = 1;
			}
			if(definition.schema >= 3) {
				if(!$.isInit(definition.sidePadding)) {
					let updated = false;
					if(definition.lockColumns) {
						// Names with side quotes only need a little bit of padding
						definition.sidePadding = 0.1;
						updated = true;
					} else if(definition.row && definition.row.position) {
						if(!$.isInit(definition.row.insidePadding)) {
							definition.row.insidePadding = DEFAULT_CANVAS_SIDE_PADDING;
							definition.row.outsidePadding = 0.3;
							updated = true;
						}
					} else {
						definition.sidePadding = DEFAULT_CANVAS_SIDE_PADDING;
						updated = true;
					}

					if(definition.schema >= 4 && this.inchSafeSpace && updated) {
						let maxSafeSpace = Math.max(this.inchSafeSpace.left, this.inchSafeSpace.right);
						if(definition.sidePadding) {
							// Do not limit side padding too much because it dramatically reduces how many subjects per page we can fit in a standard layout
							definition.sidePadding = Math.max(0.4, definition.sidePadding - maxSafeSpace);
						} else if(definition.row?.insidePadding) {
							definition.row.insidePadding = Math.max(0, definition.row.insidePadding - maxSafeSpace);
							definition.row.outsidePadding = Math.max(0, definition.row.outsidePadding - maxSafeSpace);
						}
					}
				}
			}
		},
		setLayout: function (definition, extras) {
			if(!extras) {
				extras = {};
			}

			this.definition = definition;
			this.initLayoutDefinition(definition);

			var me = this;
			this.setupInchDimensions(definition);

			// Remove old layout
			this.removeCurrentLayout();

			let page = this.getPage();

			// Set width to obey the ratio absolutely
			this.setupOuterSize();
			this.canvasBorderWidth = this.canvasMargins.getBorderWidth();

			// Setup ratio based off of how wide it is
			this.setRatio($(this).getFloatStyle('height') / this.inchHeight);

			// Get what the bleed for this page should be
			this.container.setBleed(this.inchBleed);

			this.updatePageMargins();

			// Add title to top
			if (!this.titleDiv) {
				this.titleDiv = new $.FlowLayoutTitle(this, function (title, isUserAction) {
					let page = me.getPage();
					if (page) {
						if(title !== null) {
							title = $.extend(true, {}, title);
						}

						me.startGroupedEvents();
						page.setTitle(title);
						if(isUserAction) {
							me.canvas.checkUpdateRowCount({
								triggerOverflowPrompt: true
							});
						}
						if (me.childLayout) {
							// Change child text only if it was the same as parent before!
							var overflowPage = me.childLayout.getPage();

							let title = page.getTitle();
							if (title && title.style) {
								title = title.style;
							}

							var overflowTitle = overflowPage.getTitle();
							if (overflowTitle && overflowTitle.style) {
								overflowTitle = overflowTitle.style;
							}

							if (overflowPage && title == overflowTitle) {
								me.childLayout.setTitle(page.getTitle());
							}
						}

						if(!me.parent?.userPromptedForRollback) {
							me.stopGroupedEvents();
						}
					}
				});
				$(this.titleDiv).attr('id', this.id + 'Title');
				this.canvas.appendChild(this.titleDiv);
			}
			if(page) {
				var pageTitle = page.getTitle();
				if(!pageTitle && definition.title) {
					pageTitle = definition.title;
					var pageStyleThemePart = page.getThemeStylePart();
					if(pageStyleThemePart && pageStyleThemePart.title) {
						var newPageTitle = page.updateTextObjectThemeStyles(pageTitle, {}, pageStyleThemePart.title);
						if(newPageTitle) {
							pageTitle = {
								lines: newPageTitle
							};
						}
					}

					delete definition.title;
				}
				this.titleDiv.updateDefaultFontSize();
				this.setTitle(pageTitle);
			}

			let pageSet = this.getPageSet()
			if (page && page.type != 'cover' && pageSet?.getPageNumberPosition) {
				if (!this.pageNumberDiv) {
					this.setupPageNumber();
				}
			}

			if (page && page.type != 'cover' && page.type != 'insideCover' && this.pageNumberDiv && page.showPageNumber() && pageSet?.getPageNumberPosition) {
				this.updatePageNumber(page.getPageNumber());
			} else {
				$(this.pageNumberDiv).hide();
			}

			let cells = this.getCellSizes(definition);
			this.updateCellDefinitionIfEmpty(definition, page, cells);

			// Setup student label css bundle
			if (extras && extras.studentLabelCSS) {
				this.canvas.studentLabelCSS = extras.studentLabelCSS;
			} else if(page && page.getStudentLabelCSS && page.getStudentLabelCSS()) {
				var tmpBundle = page.getStudentLabelCSS();
				if($.isPlainObject(tmpBundle) && !tmpBundle.css) {
					page.studentLabelCSS = tmpBundle = page.createNewStudentLabelCSS(tmpBundle);
				}
				
				if(this.editable) {
					this.canvas.studentLabelCSS = tmpBundle;
				} else {
					this.canvas.studentLabelCSS = new $.CSSBundle(tmpBundle.onChange, $.extend(true, {}, tmpBundle.css));
				}
			} else {
				this.canvas.studentLabelCSS = new $.CSSBundle();
			}

			// Setup grid structure
			if (definition.cell && !definition.grid) {
				definition.grid = {};
			}
			var grid = definition.grid;
			this.setupDefinitionForUse(definition);

			// Always recalculate vertical/horizontal sizes.  It is a quick operation anyways and is needed for margin changes of pages not visible
			if (definition.cell && definition.cell.size != 'fit') {
				$(this.canvas).removeClass('maxCanvas');
				this.calculateCells(definition);
			} else {
				$(this.canvas).addClass('maxCanvas');
			}
			if(definition.cell && definition.cell.size == 'fit' && grid && grid.horizontal) {
				delete grid.horizontal;
				delete grid.vertical;
			}
			this.canvas.setGrid(grid);

			if(definition.cell && definition.cell.mask) {
				var mask = definition.cell.mask;
				if(mask['clip-path'] == 'url(#Mask-1-0_8)') {
					$.appendToGlobalSVGDefinition('<clipPath id="Mask-1-0_8" clipPathUnits="objectBoundingBox"><rect x="0" y="0" width="1" height="1" rx="0.100px" ry="0.080px"/></clipPath>');
				}
			}

			if(definition.largeCell) {
				this.canvas.largeCell = $.extend({}, definition.largeCell);

				// Make sure that col is either 0 or -1
				if(typeof this.canvas.largeCell.col == 'object') {
					this.canvas.largeCell.col = this.canvas.largeCell.col[this.side];
				}
			} else {
				this.canvas.largeCell = null;
			}

			// Setup individual cell structure
			this.addCellsToLayout(definition);
			if(definition.primarySubjectPoseFrame) {
				this.addPrimarySubjectPoseFrame(definition.primarySubjectPoseFrame);
			}
			cells = $(this.canvas).find('.flowCell:not(.rowHeader)');

			var groupedElements = this.getGroupedElementsFromLayout(definition);
			var frames = null;
			if (definition.frames) {
				if ($.isArray(definition.frames)) {
					frames = {};
					for (let i = 0; i < definition.frames.length; i++) {
						var frame = definition.frames[i];
						var id = $.getGuid();
						frame.id = id;
						frames[id] = frame;
					}
				} else {
					frames = this.updateWithUniqueIds(definition.frames, groupedElements);
				}
			} else if (definition.candids) {
				frames = this.updateWithUniqueIds(definition.candids, groupedElements);
			} else if(definition.images) {
				frames = this.updateWithUniqueIds(definition.images, groupedElements);
			}
			this.addFrames(cells, frames, definition);

			var texts = definition ? this.updateWithUniqueIds(definition.texts, groupedElements) : null;
			this.addTexts(cells, texts);
			if(page) {
				this.addLockedTexts(page.getLockedTexts(this.side));
			}

			this.updateMovementLocked();
			this.postAddedContent();

			// Make sure to save the nulling of definition.texts saves
			var forceLayoutSave = false;
			if (definition.texts) {
				definition.texts = null;
				forceLayoutSave = true;
			}

			if (page) {
				if(page.getKids) {
					let firstLayout = (extras && extras.firstLayout) ? false : true;
					if(extras?.triggerOverflowPrompt) {
						this.addUsersWithRollbackPrompt({
							firstLayout
						});
					} else {
						this.addUsers(null, firstLayout);
					}
				}

				if (this.getPageSet() && this.getPageSet().getLayout) {
					// TODO: What should we do here?
				} else {
					// Make sure to use this.definition in case it was updated through something like removeTitleBreak
					page.setLayout(this.definition, forceLayoutSave);
				}
				if (page.getStudentLabelCSS) {
					if (this.editable || !page.getStudentLabelCSS()) {
						page.setStudentLabelCSS(this.canvas.studentLabelCSS);
					}
				}

				this.applySubjectEffects();
				this.applyGlobalEffects();
			}
			if (this.childLayout) {
				// Placeholder
				this.childLayout.setLayout(definition, {
					triggerOverflowPrompt: extras?.triggerOverflowPrompt,
					firstLayout: true
				});
			}

			if(extras.keepGroupedEvents !== false && !this.parent?.userPromptedForRollback) {
				this.stopGroupedEvents();
			}
			if (this.onLayoutChange) {
				this.onLayoutChange();
			}
		},
		applyGlobalEffects: function() {
			if(this.page && this.page.forceGrayscale()) {
				$(this).addClass('forceGrayscale');
			} else {
				$(this).removeClass('forceGrayscale');
			}
		},
		getGroupedElementsFromLayout: function(definition) {
			var groupedElements = [];
			var groups = ['images', 'candids', 'frames', 'texts'];
			groups.forEach(function(group) {
				if(!definition[group]) {
					return;
				}

				for(var id in definition[group]) {
					var object = definition[group][id];
	
					if(object && object.groupedElements) {
						groupedElements.push(object.groupedElements);
					}
				}
			});

			return groupedElements;
		},
		updateWithUniqueIds: function(objects, groupedElements) {
			if(!objects || !$.isPlainObject(objects)) {
				return objects;
			}
			
			for(var id in objects) {
				var object = objects[id];

				var newId = $.getUniqueId();
				object.id = newId;
				objects[newId] = object;

				(groupedElements || []).forEach(function(groupedElements) {
					let index = groupedElements.indexOf(id);
					if(index !== -1) {
						groupedElements[index] = newId;
					}
				});

				delete objects[id];
			}

			return objects;
		},
		setRatio: function(ratio) {
			this.ratio = ratio;

			this.container.setRatio(this.ratio);
			this.canvasMargins.setRatio(this.ratio);
			this.canvas.setRatio(this.ratio);
			this.extraSubjectGrids.forEach(function(subjectGrid) {
				subjectGrid.setRatio(ratio);
			});
			if (this.titleDiv) {
				this.titleDiv.setRatio(ratio);
			}
			if (this.pageNumberDiv) {
				this.pageNumberDiv.setRatio(ratio);
			}
			if(this.gridLines) {
				this.gridLines.setRatio(ratio);
			}
		},
		getCellSizes: function(definition) {
			let cells = definition.cells;
			if (!cells && definition.cell) {
				if(definition.schema < 3) {
					cells = {
						Small: {
							width: 1.0,
							height: 1.225,
							padding: DEFAULT_CELL_PADDING,
							name: 'bottom',
							nameSize: 6
						},
						Medium: {
							width: 1.25,
							height: 1.5375,
							padding: DEFAULT_CELL_PADDING,
							name: 'bottom',
							nameSize: 8
						},
						Large: {
							width: 1.5,
							height: 1.85,
							padding: DEFAULT_CELL_PADDING,
							name: 'bottom',
							nameSize: 10
						}
					};

					if (definition.row && definition.row.insidePadding) {
						cells.Small.width = 0.96;
						cells.Small.height = 1.175;
					}
				} else {
					cells = {
						Small: {
							columns: 8
						},
						Medium: {
							columns: 6
						},
						Large: {
							columns: 5
						}
					};

					if (definition.row) {
						for(let size in cells) {
							cells[size].columns--;
						}
					}

					var pageSet = this.getPageSet();
					if(pageSet && pageSet.getLayoutDimensions && pageSet.getLayoutDimensions()) {
						var layoutDimensions = pageSet.getLayoutDimensions();

						var layoutRatio = layoutDimensions.cropWidth / layoutDimensions.cropHeight;
						if(layoutRatio > 1) {
							for(let size in cells) {
								cells[size].columns++;
							}
						}
					}
				}
			}
			this.cells = cells;

			return cells;
		},
		updateCellDefinitionIfEmpty: function(definition, page, cells) {
			if (definition.cell && (!definition.cell.size || definition.cell.size != 'fit') && (definition.cell.default || !definition.cell.width || !definition.cell.height) && page.getSize) {
				let size = page.getSize();

				if (size && cells[size]) {
					definition.cellSize = size;
				} else {
					size = definition.cellSize = definition.cell.default;
				}

				definition.cell = this.cloneCell(definition.cell, cells[definition.cellSize]);
				if(definition.cell.columns) {
					this.calculateCellsToColumns(definition, definition.cell.columns, definition.cell.rows, false);
				}
			}
		},
		cloneCell: function(cloneCell, baseCell, extraProperties) {
			var newCell = {};
			var properties = ['subjectSwappable', 'subjectMovable', 'subjectResizable', 'mask', 'alignCells', 'leftPadding', 'rightPadding', 'bottomPadding', 'topPadding', 'ratio', 'resizeWrongAspectRatio',
				'nameOrder', 'teacherPrefixOrder'];
			for(let i = 2; i < 10; i++) {
				properties.push('nameOrder' + i);
				properties.push('teacherPrefixOrder' + i);
			}
			if(extraProperties) {
				$.merge(properties, extraProperties);
			}
			if(properties) {
				for(let i = 0; i < properties.length; i++) {
					var prop = properties[i];
					newCell[prop] = cloneCell[prop];
				}
			}
			$.extend(newCell, baseCell);

			return newCell;
		},
		setupInchDimensions: function(definition) {
			var width = $.PAGE_WIDTH;
			var height = $.PAGE_HEIGHT;
			var bleed = {
				left: $.PAGE_BLEED,
				right: $.PAGE_BLEED,
				top: $.PAGE_BLEED,
				bottom: $.PAGE_BLEED
			};
			var safeSpace = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};
			var whiteSpace = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};

			var pageSet = this.getPageSet();
			var layoutDimensions = null;
			if(pageSet && pageSet.getLayoutDimensions && pageSet.getLayoutDimensions()) {
				layoutDimensions = pageSet.getLayoutDimensions();
			}
			if (definition && definition.grid && definition.grid.width) {
				var grid = $.sanitizeNumbersAsStrings(definition.grid);
				width = grid.width;
				height = grid.height;

				if($.isInit(grid.bleed)) {
					if($.isPlainObject(grid.bleed)) {
						$.extend(bleed, grid.bleed);
					} else {
						bleed = {
							left: grid.bleed,
							right: grid.bleed,
							top: grid.bleed,
							bottom: grid.bleed
						};
					}

					if(definition.grid.bleedOutsideDimensions) {
						width += bleed.left + bleed.right;
						height += bleed.top + bleed.bottom;
					}
				}

				if($.isInit(grid.safeSpace)) {
					$.extend(safeSpace, grid.safeSpace);
				}
				if($.isInit(grid.whiteSpace)) {
					$.extend(whiteSpace, grid.whiteSpace);
				}
			} else if(layoutDimensions) {
				var outerDimensions = pageSet.getOuterDimensions(this.side, {
					includeWhiteSpace: this.includeWhiteSpace
				});
				width = outerDimensions.width;
				height = outerDimensions.height;
				bleed = outerDimensions.bleed;
				safeSpace = outerDimensions.safeSpace;
				whiteSpace = outerDimensions.whiteSpace;
			}

			var hiddenBleed = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};

			var hideBleed = this.hideBleed;
			if(layoutDimensions && layoutDimensions.hideBleed && this.editable) {
				hideBleed = true;
			}
			if(hideBleed === true) {
				let page = this.getPage();
				if(page && page.getOverrideHideAllBleed) {
					hideBleed = page.getOverrideHideAllBleed();
				}
			}

			if(hideBleed === true) {
				width = width - (bleed.left + bleed.right) + safeSpace.left + safeSpace.right;
				height = height - (bleed.top + bleed.bottom) + safeSpace.top + safeSpace.bottom;
				
				hiddenBleed = {
					left: bleed.left - safeSpace.left,
					right: bleed.right - safeSpace.right,
					top: bleed.top - safeSpace.top,
					bottom: bleed.bottom - safeSpace.bottom
				};
				bleed = $.extend(true, {}, safeSpace);
			} else if($.isPlainObject(hideBleed)) {
				for(var side in hideBleed) {
					if(!hideBleed[side]) {
						continue;
					}

					if(side === 'left' || side === 'right') {
						width = width - bleed[side] + safeSpace[side];
					} else if(side === 'top' || side === 'bottom') {
						height = height - bleed[side] + safeSpace[side];
					}

					hiddenBleed[side] = bleed[side] - safeSpace[side];
					bleed[side] = safeSpace[side];
				}
			}

			this.inchWidth = width;
			this.inchHeight = height;
			this.inchContentSize = {
				width: width - bleed.left - bleed.right,
				height: height - bleed.top - bleed.bottom
			};
			this.inchBleed = bleed;
			this.inchSafeSpace = safeSpace;
			this.inchHiddenBleed = hiddenBleed;
			this.inchWhiteSpace = whiteSpace;
		},

		setSize: function(cellSize, onComplete) {
			this.canvas.setSize(cellSize, onComplete);
		},

		saveLayoutChange: function(layoutUpdate) {
			let layout = $.extend(true, {}, this.getLayout());
			$.extend(true, layout, layoutUpdate);

			let page = this.getRootPage();
			if(layout.cell && layout.cell.size == 'fit') {
				this.calculateCellsToFit(layout,  page.getKids(), true);
			}

			page.setLayout(layout, true, true);
			this.updateCellDefinition();
		},
		updateCellDefinition: function(definition, options = {}) {
			let page = this.getPage();

			let rootPage = page;
			if(page.getRootPage) {
				rootPage = page.getRootPage();
			}

			if(definition) {
				rootPage.setLayout(definition, true);
			} else {
				definition = this.getLayout();
			}

			if(page == rootPage || (this.parentLayout && this.parentLayout.getPage() == rootPage)) {
				let layout = this;
				if (this.parentLayout) {
					layout = this.parentLayout;
				}

				// Update what is displayed
				this.setupDefinitionForUse(definition, {
					leaveStudentLabelCSS: true
				});

				layout.canvas.updateCellDefinition(definition);
				if(layout.childLayout) {
					layout.childLayout.canvas.updateCellDefinition(definition);
				}

				this.definition = definition;
				if(options.triggerOverflowPrompt) {
					layout.addUsersWithRollbackPrompt({
						childLayout: layout.childLayout
					});
				} else {
					layout.addUsers();

					if(layout.childLayout) {
						layout.childLayout.addUsers(null, false);
					}
				}
			} else {
				var childLayout = this.childLayout;
				var parentLayout = this.parentLayout;

				// Can't really calculate this correctly without just loading each page up in order
				var loadPage = rootPage;
				while(page != loadPage && loadPage) {
					this.setPage(loadPage, false, {
						blockFirstLayout: true,
						triggerOverflowPrompt: options.triggerOverflowPrompt,
						keepGroupedEvents: options.triggerOverflowPrompt === true ? false : undefined
					});
					loadPage = loadPage.getOverflowPage();
				}

				let updatePagesOptions = {
					pageOptions: {
						triggerOverflowPrompt: options.triggerOverflowPrompt
					},
					skipStopGroupedEvents: true
				};

				if(!loadPage) {
					this.parent.updatePagesAfterChange(true, undefined, updatePagesOptions);
				} else if(parentLayout) {
					this.parent.updatePagesAfterChange(parentLayout.getPage(), undefined, updatePagesOptions);
				} else if(childLayout) {
					this.parent.updatePagesAfterChange(childLayout.getPage(), undefined, updatePagesOptions);
				} else {
					this.parent.updatePagesAfterChange(undefined, undefined, updatePagesOptions);
				}
			}
		},
		setupDefinitionForUse: function (definition, options) {
			if(!options) {
				options = {};
			}

			if (definition.cell) {
				if(definition.name) {
					definition.cell.nameOrder = definition.name.order;
					for(let i = 2; $.isInit(definition.name['order' + i]); i++) {
						definition.cell['nameOrder' + i] = definition.name['order' + i];
					}
					definition.cell.teacherPrefixOrder = definition.name.teacherPrefixOrder;
					for(let i = 2; $.isInit(definition.name['teacherPrefixOrder' + i]); i++) {
						definition.cell['teacherPrefixOrder' + i] = definition.name['teacherPrefixOrder' + i];
					}
					definition.cell.nameHeight = definition.name.height;
					definition.cell.nameAlign = definition.name.align;
					definition.cell.overflowLabel = definition.name.overflowLabel;
					definition.cell.nameHeightFix = definition.name.nameHeightFix;
					if(options.leaveStudentLabelCSS !== true) {
						definition.cell.studentLabelCSS = this.canvas.studentLabelCSS;
					}
					definition.cell.primaryPose = definition.primaryPose;
					definition.cell.texts = definition.cellTexts;

					if (definition.schema == 1) {
						definition.cell.autoTitleCase = true;
					}
				}

				if(definition.cell.alignCells) {
					if(definition.cell.alignCells === 'center') {
						if(!definition.row) {
							definition.row = {};
						}

						definition.row.centered = true;
					} else if(definition.row && definition.row.centered === true) {
						definition.row.centered = false;
					}
				}
				definition.cell.ratio = definition.cell.ratio || 0.8;
			}
			if(definition.row) {
				if(definition.row.position && definition.name) {
					definition.row.nameOrder = definition.name.order;
					for(let i = 2; $.isInit(definition.name['order' + i]); i++) {
						definition.row['nameOrder' + i] = definition.name['order' + i];
					}
					definition.row.teacherPrefixOrder = definition.name.teacherPrefixOrder;
					for(let i = 2; $.isInit(definition.name['teacherPrefixOrder' + i]); i++) {
						definition.row['teacherPrefixOrder' + i] = definition.name['teacherPrefixOrder' + i];
					}
					definition.row.nameHeight = definition.name.height;
					definition.row.nameAlign = definition.name.align;
					definition.row.overflowLabel = definition.name.overflowLabel;
					definition.row.studentLabelCSS = this.canvas.studentLabelCSS;
					if(definition.cell && definition.cell.name !== 'none') {
						definition.cell.name = null;
					}

					if (!definition.row.nameSize) {
						definition.row.nameSize = definition.cell.nameSize;
					}
				}

				if(definition.row.class) {
					if(definition.cell.alignCells) {
						definition.row.class = definition.row.class.replace(/(outside|inside|left|right|center)/i, definition.cell.alignCells);
					}
				}
			}

			if(definition.row) {
				if(definition.row.centered) {
					if(!$.getProjectSetting('centerLastRow', true)) {
						definition.row.centered = false;
						definition.row.cacheCentered = true;
					}
				} else if(definition.row.cacheCentered && $.getProjectSetting('centerLastRow', true)) {
					definition.row.centered = true;
					delete definition.row.cacheCentered;
				}
			}

			// Do font sizes after ratio is determined
			if (definition.name) {
				var fontSize = (definition.cell && definition.cell.nameSize) ? definition.cell.nameSize : definition.name.size;
				fontSize = fontSize * $.PRODUCTION_RATIO / $.FONT_MULTIPLIER;

				if(!this.canvas.studentLabelCSS) {
					this.canvas.studentLabelCSS = new $.CSSBundle();
				}
				this.canvas.studentLabelCSS.setCSS('font-size', fontSize + 'pt', true);
			}
		},
		calculateCells: function() {
			this.canvas.calculateCells.apply(this.canvas, arguments);
		},
		calculateCellsToColumns: function() {
			this.canvas.calculateCellsToColumns.apply(this.canvas, arguments);
		},
		
		getCanvasInchWidth: function(definition) {
			var width = this.inchWidth;
			if($.isPlainObject(this.inchBleed)) {
				if(this.inchBleed.left) {
					width -= this.inchBleed.left;
				}
				if(this.inchBleed.right) {
					width -= this.inchBleed.right;
				}
			} else {
				width -= this.inchBleed * 2;
			}

			var pageMargins = this.getPageMargins();
			if (pageMargins) {
				if(pageMargins.horizontal) {
					width -= pageMargins.horizontal * 2;
				}

				if(pageMargins.left) {
					width -= pageMargins.left;
				}
				if(pageMargins.right) {
					width -= pageMargins.right;
				}
				if(pageMargins.outside) {
					width -= pageMargins.outside;
				}
				if(pageMargins.gutter) {
					width -= pageMargins.gutter;
				}
			}
			if(definition.row) {
				if(definition.row.insidePadding) {
					width -= definition.row.insidePadding;
				} else if(definition.row.sidePadding) {
					width -= definition.row.sidePadding;
				}

				if(definition.row.outsidePadding) {
					width -= definition.row.outsidePadding;
				} else if(definition.row.sidePadding) {
					width -= definition.row.sidePadding;
				}
			}

			if(definition.sidePadding) {
				width -= definition.sidePadding * 2;
			}

			return width;
		},
		getCanvasInchHeight: function(definition) {
			var height = this.inchHeight;
			if($.isPlainObject(this.inchBleed)) {
				if(this.inchBleed.top) {
					height -= this.inchBleed.top;
				}
				if(this.inchBleed.bottom) {
					height -= this.inchBleed.bottom;
				}
			} else {
				height -= this.inchBleed * 2;
			}

			var pageMargins = this.getPageMargins();
			if (pageMargins) {
				if(pageMargins.vertical) {
					height -= pageMargins.vertical * 2;
				}

				if(pageMargins.top) {
					height -= pageMargins.top;
				}
				if(pageMargins.bottom) {
					height -= pageMargins.bottom;
				}
			}

			if (this.pageNumberDiv && (!definition.grid || definition.grid.horizontalDecrement != 1)) {
				var pageSet = this.getPageSet();
				var allowOverlap = pageSet.canSubjectsOverlapPageNumber && pageSet.canSubjectsOverlapPageNumber();

				// We are doing height + only padding bottom
				// We do not want to force padding top since it can easily lead to large gaps where not really necessary
				if(!allowOverlap && this.pageNumberDiv.instance && this.pageNumberDiv.calculatedRenderedHeight && this.pageNumberDiv.style.display != 'none') {
					// Use calculated height so rounding errors don't cause browser differences!
					var pageNumberSize = this.pageNumberDiv.calculatedRenderedHeight + $(this.pageNumberDiv).getFloatStyle('paddingBottom');
					height -= (pageNumberSize / this.ratio);
				}
			} else {
				height -= 0.01;
			}

			$(this).find('.flowTitle').each(function () {
				if(this.instance && this.calculatedRenderedHeight && this.style.display != 'none') {
					// Use calculated height so rounding errors don't cause browser differences!
					var titleHeight = this.calculatedRenderedHeight + $(this).getFloatStyle('paddingBottom') + $(this).getFloatStyle('paddingTop');
					height -= (titleHeight / this.ratio);
				}
			});

			return height;
		},
		getPageNumberHeight: function() {
			if(this.pageNumberDiv) {
				// We are doing height + only padding bottom
				// We do not want to force padding top since it can easily lead to large gaps where not really necessary
				if(this.pageNumberDiv.instance && this.pageNumberDiv.calculatedRenderedHeight && this.pageNumberDiv.style.display != 'none') {
					// Use calculated height so rounding errors don't cause browser differences!
					return this.pageNumberDiv.calculatedRenderedHeight + $(this.pageNumberDiv).getFloatStyle('paddingBottom');
				}
			} else {
				return 0;
			}
		},
		getPageNumberInchHeight: function() {
			return this.getPageNumberHeight() / this.ratio;
		},
		calculateCellsToFit: function(definition, users, blockRecalculate) {
			return this.canvas.calculateCellsToFit(definition, users, blockRecalculate);
		},

		addCellsToLayout: function(definition) {
			this.canvas.addCellsToLayout(definition);
		},

		removeCurrentLayout: function () {
			this.canvas.removeCurrentGrid();
			while(this.extraSubjectGrids.length) {
				let subjectGrid = this.extraSubjectGrids.pop();
				subjectGrid.destroy();
				$(subjectGrid).remove();
			}
			while(this.frames.length > 0) {
				var frame = this.frames.pop();
				frame.destroy();
				if(frame.parentNode === this.container) {
					this.container.removeChild(frame);
				} else {
					$(frame).remove();
				}
			}
			while(this.texts.length > 0) {
				let text = this.texts.pop();
				text.destroy();
				if(text.parentNode === this.container) {
					this.container.removeChild(text);
				} else {
					$(text).remove();
				}
			}

			$(this.container).find('.flowFreeText, .flowLayoutFrame').each(function() {
				$.fireErrorReport(null, 'Have to manually remove bogus element', null, {
					page: wrapper.getSnapshot(),
					instance: this.instance
				});

				try {
					this.destroy();
				} catch(e) {
					console.error('Failed to destroy bogus element', this);
				} finally {
					$(this).remove();
				}
			});

			if (this.titleDiv) {
				this.titleDiv.setTitle(null);
			}
			if (this.pageNumberDiv) {
				$(this.pageNumberDiv).hide();
			}

			$(this).removeClass('classPlaceholder');
		},
		
		updateMovementLocked: function () {
			let page = this.getPage();
			if (page) {
				var locked = page.getFreeMovementLocked();
				var isEditable = !locked && this.editable;
				this.setMovementLocked(locked);

				if(this.titleDiv) {
					this.titleDiv.setEditable(isEditable);
				}

				this.updatePageMarginsEditable(page);
				this.canvas.setEditable(isEditable);
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setEditable(isEditable);
				});
			}
		},
		setMovementLocked: function(locked) {
			this.frames.forEach(function(frame) {
				frame.setMovementLocked(locked);
			});

			this.texts.forEach(function(text) {
				text.setMovementLocked(locked);
			});
		},

		getMovableContent: function() {
			return $(this.frames).add(this.texts).not('.flowLockedText');
		},
		getContent: function(id) {
			if(id) {
				return this.getContent().filter(function() {
					return this.instance && this.instance.id == id;
				})[0];
			} else {
				return $(this.frames).add(this.texts);
			}
		},

		getFrame: function(id) {
			for(let i = 0; i < this.frames.length; i++) {
				var frame = this.frames[i];
				if(frame.definition.id == id) {
					return frame;
				}
			}

			return null;
		},
		getFrames: function() {
			return $(this.frames);
		},
		refreshFrames: function() {
			while (this.frames.length > 0) {
				var frame = this.frames.pop();
				frame.destroy();
				this.container.removeChild(frame);
			}
			this.addFrames();
			this.postAddedContent();
		},
		refreshFrame: function(id) {
			let page = this.getPage();
			let definition = page.getCandid(id, false);
			var frame = this.getFrame(id);
			if(frame) {
				if(definition) {
					frame.refreshFromOriginalInstance(definition);
					return frame;
				} else {
					this.removeFrame(frame, false);
					return null;
				}
			} else {
				if(definition) {
					return this.addFrame(definition, false);
				}
			}
		},
		addFrames: function (cells, frames, definition) {
			let page = this.getPage();
			if (!page) {
				return;
			}

			if (!frames) {
				frames = page.getCandids();
			}

			if (frames) {
				if ($.isArray(frames)) {
					var newCandids = {};
					for (var j = 0; j < frames.length; j++) {
						var candid = frames[j];
						candid.id = $.getGuid();
						newCandids[candid.id] = candid;
					}
					frames = newCandids;
				}

				this.addFramesSet(this.filterFramesSet(frames), cells);
				if (definition) {
					if (definition.frames) {
						definition.frames = null;
						page.jsonReplace('candids', frames);
						page.setLayout(definition, true);
					} else if (definition.candids) {
						definition.candids = null;
						page.jsonReplace('candids', frames);
						page.setLayout(definition, true);
					} else if (definition.images) {
						definition.images = null;
						page.jsonReplace('candids', frames);
						page.setLayout(definition, true);
					}
				}
			}
		},
		filterFramesSet: function(frames) {
			return frames;
		},
		addFramesSet: function(frames, cells, definition) {
			for(var id in frames) {
				var frame = frames[id];
				if(!frame) {
					continue;
				}

				var frameDefinition;
				if(typeof frame.cell == 'string') {
					frameDefinition = definition[frame.cell];
				} else {
					frameDefinition = frame.cell;
				}
				if (frameDefinition && (!frame.width || !frame.height)) {
					frame.width = frameDefinition.width;
					frame.height = frameDefinition.height;
					frame.padding = frameDefinition.padding;
				}
				frame = new $.FlowLayoutFrame(this, frame, this.ratio, this.getContentSettings('image'));
				frame.extraOffset = this.canvasBorderWidth;
				this.container.appendChild(frame);
				this.frames.push(frame);
				if(definition && (definition.frames || definition.candids)) {
					delete frames[id]['cell'];
				}

				if(cells) {
					frame.hideOverlap(cells);
				}
			}
		},
		addFrame: function (definition, save) {
			if(!definition.id) {
				definition.id = $.getUniqueId();
			}
			if(!$.isInit(definition.zIndex)) {
				definition.zIndex = this.getMaxZIndex() + 1;
			}

			var frame = new $.FlowLayoutFrame(this, definition, this.ratio, this.getContentSettings('image'));
			frame.extraOffset = this.canvasBorderWidth;
			this.container.appendChild(frame);
			this.frames.push(frame);
			if(save !== false) {
				this.getPage().addCandid(definition);
			}

			this.onFlowAdd(frame);
			frame.onAdd();
			return frame;
		},
		removeFrame: function (frame, save) {
			this.frames.removeItem(frame);
			frame.destroy();
			$(frame).remove();
			frame.onRemove();

			this.onFlowRemove(frame);
			if(save !== false) {
				let page = this.getPage();
				if(page) {
					page.removeCandid(frame.definition.id);
				}
			}
		},
		removeFrameByInstance: function(instance, save) {
			var frame = this.getFrame(instance.id);
			if(frame) {
				this.removeFrame(frame, save);
			}
		},
		refreshTexts: function() {
			while (this.texts.length > 0) {
				let text = this.texts.pop();
				text.destroy();
				this.container.removeChild(text);
			}
			this.addTexts();
			this.postAddedContent();
		},
		refreshFilteredTexts: function(filter) {
			let updated = false;
			this.texts.forEach(text => {
				if(filter(text)) {
					updated = true;
					text.refreshInstance();
				}
			});

			if(updated) {
				this.postAddedContent();
			}
		},
		refreshText: function(id) {
			let page = this.getPage();
			let definition = page.getText(id, false);
			for(let i = 0; i < this.texts.length; i++) {
				let text = this.texts[i];
				if(text.definition.id == id) {
					if(definition && definition.lines) {
						text.setChangedInstance(definition);
						return text;
					} else {
						this.removeText(text, false);
						return null;
					}
				}
			}

			if(definition) {
				return this.addText(definition, false);
			}
		},
		addTexts: function (cells, texts) {
			let page = this.getPage();
			if (!page) {
				return;
			}

			if (!texts) {
				texts = page.getTexts();
			} else {
				if(!$.isArray(texts)) {
					page.jsonReplace('texts', texts);
				}
			}

			if ($.isArray(texts)) {
				var newTexts = {};
				for (var j = 0; j < texts.length; j++) {
					let text = texts[j];
					text.id = $.getGuid();
					newTexts[text.id] = text;
				}
				texts = newTexts;
				page.jsonReplace('texts', texts);
			}

			this.addTextsSet(this.filterTextsSet(texts), cells);
		},
		filterTextsSet: function(texts) {
			return texts;
		},
		addTextsSet: function(texts, cells) {
			// Make sure to grab a unique ID for each
			for(var id in texts) {
				let text = texts[id];
				if(!text) {
					continue;
				}
				
				text.id = id;
				var newTextDiv = this.getTextDiv(text, false);
				if(newTextDiv) {
					this.texts.push(newTextDiv);

					if(cells) {
						newTextDiv.hideOverlap(cells);
					}
				}
			}
		},
		getText: function(id) {
			for(let i = 0; i < this.texts.length; i++) {
				let text = this.texts[i];
				if(text.instance.id == id) {
					return text;
				}
			}

			return null;
		},
		getTexts: function() {
			return $(this.texts);
		},
		addLockedTexts: function(lockedTexts) {
			this.lockedTexts = [];
			var documentFragment = document.createDocumentFragment();
			for(let i = 0; i < lockedTexts.length; i++) {
				let definition = lockedTexts[i];

				let div = this.getLockedTextDiv(definition, documentFragment);
				this.texts.push(div);
				this.lockedTexts.push(div);
			}
			this.container.appendChild(documentFragment);

			for(let i = 0; i < this.lockedTexts.length; i++) {
				let div = this.lockedTexts[i];
				div.delayedPostRenderLines();
			}
		},
		getLockedTextDiv: function(definition, documentFragment) {
			var editTools;
			let page = this.getPage();
			if(page && page.getLockedOptions) {
				editTools = page.getLockedOptions(definition.type);
			}

			if(!documentFragment) {
				documentFragment = this.container;
			}

			var lockedSettings = null;
			if(page.getLockedSettings) {
				lockedSettings = page.getLockedSettings();
			}
			var textEditable = !!(this.editable && definition.editable);

			var svgConstructor = definition.svgConstructor || $.FlowLayoutSVGFree;
			return new svgConstructor($.extend({
				addClass: 'flowLockedText',
				wrapper: this,
				appendTo: documentFragment,
				ratio: this.ratio,
				extraOffset: this.canvasBorderWidth,
				editable: this.editable,
				textEditable: textEditable,
				movable: false,
				resizable: false,
				rotatable: false,
				isLockedText: true,
				defaultFontSizes: [6, 7, 8, 9, 10, 11, 12],
				editTools: editTools,
				wordWrapText: textEditable,
				getDynamicFields: function(allFields) {
					return wrapper.getDynamicFields(allFields);
				},
				getDynamicFieldRep: function(field) {
					if(this.instance.subject) {
						return wrapper.getDynamicFieldRep.call(wrapper, field, this.instance.subject);
					} else {
						return null;
					}
				},
				onChangeSelectedText: function (selection, name, value) {
					if(this.textEditable) {
						return;
					}

					let page = this.wrapper.getPage();
					if(page.setLockedFontStyle) {
						var type = page.setLockedFontStyle(this.instance, name, value);
						if(type == 'forceRefresh') {
							wrapper.refreshLockedTexts(true);
						} else {
							wrapper.setLockedTextStyle(type, name, value, true);
						}
					}
				},
				onChangeInstanceProperty: function(name, value) {
					if(!this.textEditable) {
						return;
					}

					let page = this.wrapper.getPage();
					if(!page || !wrapper.editable || (this.instance.pageId && this.instance.pageId !== page.id)) {
						return;
					}
					
					page.setLockedTextProperty(this.instance.id, name, value);
				}
			}, this.getContentSettings(), lockedSettings), definition);
		},
		refreshLockedTexts: function(propogate) {
			if(propogate) {
				if(this.parentLayout) {
					this.parentLayout.refreshLockedTexts();
				}
			}

			this.lockedTexts.forEach(function(textDiv) {
				textDiv.flushDelayedSaveQueue();
			});

			var newLockedTexts = this.getPage().getLockedTexts(this.side) || [];
			for(let i = 0; i < this.lockedTexts.length; i++) {
				var textDiv = this.lockedTexts[i];
				let text = textDiv.instance;

				let index = newLockedTexts.indexOfMatch(text, 'id');
				if(index == -1) {
					textDiv.destroy();
					this.container.removeChild(textDiv);
					this.texts.removeItem(textDiv);
					if(this.lockedTexts.removeItem(textDiv)) {
						i--;
					}
				} else {
					var newText = newLockedTexts[index];
					textDiv.setInstance(newText);
					newLockedTexts.splice(index, 1);
				}
			}

			var documentFragment = document.createDocumentFragment();
			var newLockedTextDivs = [];
			for(let i = 0; i < newLockedTexts.length; i++) {
				let definition = newLockedTexts[i];
				let div = this.getLockedTextDiv(definition, documentFragment);
				this.texts.push(div);
				this.lockedTexts.push(div);
				newLockedTextDivs.push(div);
			}
			this.container.appendChild(documentFragment);

			for(let i = 0; i < newLockedTextDivs.length; i++) {
				let div = newLockedTextDivs[i];
				div.delayedPostRenderLines();
			}

			if(propogate) {
				if(this.childLayout) {
					this.childLayout.refreshLockedTexts();
				}

				this.parent.updatePagesAfterChange();
			}
		},
		setLockedTextStyles: function(type, css, propogate) {
			for(let i in css) {
				this.setLockedTextStyle(type, i, css[i], propogate);
			}
		},
		setLockedTextStyle: function(type, name, value, propogate) {
			$(this).find('.flowLockedText').each(function() {
				if(this.instance.type == type) {
					this.addStyleToEntireInstance(name, value, true);
				}
			});

			if(propogate) {
				if (this.childLayout) {
					this.childLayout.setLockedTextStyle(type, name, value);
				}
				if (this.parentLayout) {
					this.parentLayout.setLockedTextStyle(type, name, value);
				}
			}
		},
		addText: function (definition, save) {
			if (!definition.schema && definition.text) {
				definition.schema = $.FlowLayoutSVG.MAX_SCHEMA;
			}
			if(!definition.id) {
				definition.id = $.getUniqueId();
			}
			if(!$.isInit(definition.zIndex)) {
				definition.zIndex = this.getMaxZIndex() + 1;
			}

			let text = this.getTextDiv(definition, false);
			this.container.appendChild(text);
			text.position();
			this.texts.push(text);
			if(save !== false) {
				this.getPage().addText(definition.id, definition.position, null, definition);
			}

			if (text.hideOverlap($(this.container).find('.flowCell:not(.rowHeader)'))) {
				this.addUsers();
			}
			return text;
		},
		removeText: function (text, save) {
			this.texts.removeItem(text);
			text.destroy();
			$(text).remove();

			this.onFlowRemove(text);
			if(save !== false) {
				this.getPage().removeText(text.definition.id);
			}
		},
		removeTextByInstance: function(instance, save) {
			let text = this.getText(instance.id);
			if(text) {
				this.removeText(text, save);
			}
		},
		createNewText: function (position, extras) {
			extras = $.extend(true, {
				autoFocus: true
			}, extras);
			let page = this.getPage();
			if (!page) {
				page = new $.YearbookCandidPage();
				page.setLayout({});
				this.getPageSet().addPage(page);
				this.setPage(page);
			}

			// Translated position
			var offset = $(this.container).offset();
			var x = position.left - offset.left;
			var y = position.top - offset.top;
			position.left = x / this.ratio;
			position.top = y / this.ratio;

			// Detect when we are dropping text in the spine and auto rotate it
			if(page.doesAllowContentCompletelyInBleed?.() && page.doesOverflowTextToLinkedLayouts?.() && (
				(position.left < 0 && position.left < -this.inchSafeSpace.left)
				||
				(position.left > (this.inchContentSize.width + this.inchSafeSpace.right))
			)) {
				if(!extras.textSettings) {
					extras.textSettings = {};
				}
				// Help the user out and center the spine text where it is supposed to go
				if(position.left < 0) {
					position.left = -this.inchBleed.left;
				} else {
					position.left = this.inchContentSize.width + this.inchBleed.right;
				}
				extras.textSettings.transform = 'rotate(90deg)';
			}

			// If QR code we want the position to match exactly where we dropped it
			if(extras.textSettings?.manualSize) {
				position.left = position.left - extras.textSettings.manualSize.width / 2;
				position.top = position.top - extras.textSettings.manualSize.height / 2;
			}

			var extraStyles = this.getDefaultTextStyles();
			var newText = page.addText($.getUniqueId(), position, $.extend({}, extraStyles, extras.textStyles), $.extend({
				zIndex: (this.getMaxZIndex() + 1)
			}, this.extraContentProperties, extras.textSettings));
			var startingLine = $.extend(true, {}, newText.lines);
			var newTextDiv = this.getTextDiv(newText, true);
			this.texts.push(newTextDiv);

			// When dragging a text box we want it centered around our cursor
			if(extras.centerOnPoint && !extras.textSettings?.manualSize) {
				const textWidth = $(newTextDiv).width() / this.ratio;
				const textHeight = $(newTextDiv).height() / this.ratio;
				let newLeft = newTextDiv.instance.position.left - textWidth / 2;
				let newTop = newTextDiv.instance.position.top - textHeight / 2;
				
				let pageSet = this.getPageSet();
				let containTextInParent = (!pageSet || pageSet.doesContainTextInParent());

				// Detect when we should update left/top to not be in bleed or safe space
				if(containTextInParent && !page.doesOverflowTextToLinkedLayouts?.()) {
					if(newLeft < 0) {
						newLeft = 1 / this.ratio;
					} else if(newLeft + textWidth > this.inchContentSize.width) {
						newLeft = this.inchContentSize.width - textWidth - 1 / this.ratio;
					}

					if(newTop < 0) {
						newTop = 1 / this.ratio;
					} else if(newTop + textHeight > this.inchContentSize.height) {
						newTop = this.inchContentSize.height - textHeight - 1 / this.ratio;
					}
				}
				
				newTextDiv.changeInstanceProperty('position', {
					left: newLeft,
					top: newTop
				});
				newTextDiv.setupPosition(newTextDiv.instance.position);
			}
			if (page.type == 'empty') {
				page.propertyChange('type', 'candid');
			}

			// Update subject placement
			this.onFlowAdd(newTextDiv);

			if(extras.autoFocus) {
				newTextDiv.setFocused(true);
				newTextDiv.setSelection(0, 0, newTextDiv.getInstanceLength());

				newTextDiv.createdAt = new Date().getTime();
				var triggered = false;
				newTextDiv.registerOnFocusChanged(function(focused) {
					if(!triggered && !focused && $(this.instance.lines).objectEquals(startingLine) && (!$.isArray(this.instance.lines) || this.instance.lines.length === 1)) {
						let text = this;
						window.setTimeout(function() {
							if (!text.destroyed) {
								wrapper.removeText(text, true);
								text.onRemovePropogate();
							}
						}, 10);
					}
					triggered = true;
				});
			}
			this.parent.setLastCreatedText(newTextDiv);

			return newTextDiv;
		},
		getDefaultTextStyles: function() {
			var extraStyles = {};

			var MAX_EDGE_LENGTH = Math.max(this.inchWidth, this.inchHeight);
			var startingFontSize = $.FlowLayoutSVGUtils.getClosestMatchingFontSize(MAX_EDGE_LENGTH / 11 * 14);
			extraStyles['font-size'] = startingFontSize + 'pt';

			// Inherit from default theme if there is one
			var themeStyles = this.getThemeTextStyles(null, 'randomText');
			if(themeStyles) {
				$.extend(extraStyles, themeStyles);
			}

			// Inherit from last text object created
			var lastCreatedText = this.parent.getLastCreatedText();
			if(lastCreatedText) {
				var lastCreatedTextStyles = lastCreatedText.getDominantStyles();
				if(lastCreatedTextStyles['font-size'] == 'auto') {
					delete lastCreatedTextStyles['font-size'];
				} else if($.FlowLayoutSVGUtils.isFontBarcode(lastCreatedTextStyles.fontFamily)) {
					delete lastCreatedTextStyles.fontFamily;
				}

				$.extend(extraStyles, lastCreatedTextStyles);
			}

			return extraStyles;
		},
		getThemeTextStyles: function(pageType, textType) {
			// Inherit from default theme if there is one
			var pageSet = this.getPageSet();
			let theme = pageSet.getTheme();
			if(theme && theme.extras) {
				var themeExtras = theme.extras;
				if(!pageType) {
					pageType = this.page.getThemeStylePartName();
				}
				if(themeExtras[pageType]) {
					var partExtras = themeExtras[pageType];
					return partExtras[textType];
				}
			}

			return null;
		},
		getTextDiv: function(definition, autoFocus) {
			var textDiv;
			if(definition.text) {
				// We migrated away from these 6+ years ago.  Shouldn't be anymore of these by this point
				return null;
			} else {
				let page = this.getPage();
				var pageSet = this.getPageSet();
				var containTextInParent = (!pageSet || pageSet.doesContainTextInParent());
				var wordWrapTextBounds = this.container;
				if(!containTextInParent) {
					wordWrapTextBounds = this;
				}

				var extraEditTools = [];
				if(page.getExtraTextEditTools) {
					extraEditTools = page.getExtraTextEditTools();
				}

				textDiv = new $.FlowLayoutSVGFree($.extend({
					wrapper: this,
					appendTo: this.container,
					ratio: this.ratio,
					extraOffset: this.canvasBorderWidth,
					editable: this.editable,
					containInParent: containTextInParent,
					editTools: function() {
						if(this.instance.locked && this.canToggleLock) {
							return [this.getToggleLockButton()];
						}			

						var tools;
						if(this.hasBarcode()) {
							tools = [
								'align',
								'barcode-type',
								'barcode-line-width',
								'text-colors',
								'z-index',
								'copy'
							];

							if(this.hasQRCode()) {
								tools.removeItem('barcode-line-width');
							}
						} else {
							tools = [
								'styles',
								'align-all',
								'font-family',
								'font-size',
								'text-colors',
								'line-height',
								'z-index',
								'effects'
							];

							if(this.hasScodix() && page.hasScodixFoilText?.()) {
								tools.removeItem('text-colors');
								tools.removeItem('effects');
								tools.push('curve');
							}
						}

						if(((this.secondaryFocusedElements.length && !this.secondaryFocusedElements.find(secondaryElement => secondaryElement.wrapper !== this.wrapper)) || this.primaryFocusedElement) && !this.keepLayoutSizedTogether) {
							tools.push(this.getGroupAlignmentButton());
						}

						if(!this.hasBarcode()) {
							var tagGroup = {
								addClass: 'icon',
								group: [
									'applyToAll',
									'copy'
								]
							};
							if(this.canToggleLock) {
								tagGroup.group.push(this.getToggleLockButton());
							}
							tools.push(tagGroup);
							$.merge(tools, extraEditTools);
						}
						if(((this.secondaryFocusedElements.length && !this.secondaryFocusedElements.find(secondaryElement => secondaryElement.wrapper !== this.wrapper)) || this.primaryFocusedElement) && !this.keepLayoutSizedTogether) {
							tools.push(this.getGroupButton());
						}
						tools.push('remove');

						return tools;
					},
					canHaveBarcodes: page.getCanHaveBarcodes(),
					wordWrapTextBounds: wordWrapTextBounds,
					getDynamicFields: function(allFields) {
						return wrapper.getDynamicFields(allFields);
					},
					getDynamicFieldRep: function() {
						return wrapper.getDynamicFieldRep.apply(wrapper, arguments);
					},
					onChangeInstanceProperty: function(name, value) {
						let page = this.wrapper.getPage();
						if(!page || !wrapper.editable) {
							return;
						}
						
						if(!page.setTextProperty(this.instance.id, name, value)) {
							// With multi users this now completely valid if removed while in the middle of typing
							console.error('Failed to find text id ' + this.instance.id, {
								id: this.instance.id,
								texts: page.getTexts(),
								this: this.instance,
								snapshot: this.wrapper.getSnapshot()
							});
						}
					}
				}, this.getContentSettings('text')), definition);

				// The qr code needs to be big enough to be scanned in the printed product
				if(textDiv.hasQRCode()) {
					textDiv.minimumBoxBounds = 50;
				}
			}

			return textDiv;
		},
		
		postAddedContent: function() {
			// Need everything to be there in order to see how wide of a box to draw
			this.getContent().each(function() {
				this.applyGroupBorders();
			});
		},

		getContentElementById: function(id) {
			return $(this).find('.flowContent').filter(function() {
				return this.instance && this.instance.id == id;
			})[0];
		},
		getContentElementsByIds: function(ids) {
			return $(this).find('.flowContent').filter(function() {
				return this.instance && ids.indexOf(this.instance.id) !== -1;
			}).toArray();
		},
		getContentElements: function(filter) {
			var elements = $(this).find('.flowContent:visible').not('.ui-draggable-dragging');
			if(filter) {
				elements = elements.filter(filter);
			}
			
			return elements.toArray();
		},
		getContentElementsAtPosition: function(position, filter) {
			return this.getContentElements(filter).filter(element => {
				return element.isWithinPosition(position);
			});
		},
		getTopContentElementAtPosition: function(position, filter) {
			let elements = this.getContentElementsAtPosition(position, filter);
			if(!elements.length) {
				return null;
			}

			let topElement = elements[0];
			let maxZIndex = $.isInit(topElement.instance.zIndex) ? topElement.instance.zIndex : 0;
			for(let i = 1; i < elements.length; i++) {
				let element = elements[i];
				let elementZIndex = $.isInit(element.instance.zIndex) ? element.instance.zIndex : 0;
				if(elementZIndex > maxZIndex) {
					topElement = element;
					maxZIndex = elementZIndex;
				}
			}

			return topElement;
		},

		setPageMargins: function (margins, updateDisplay) {
			let page = this.getPage();
			if (page && page.setPageMargins) {
				page.setPageMargins(margins);

				if(updateDisplay !== false) {
					this.updatePageMargins();
				}
				this.canvas.updateLayoutAfterPageMargins();
			}

			this.gridLines.refreshLines();

			if(this.childLayout) {
				this.childLayout.updatePageMargins();
			}
		},
		getPageMargins: function () {
			var pageMargins;
			var pageSet = this.getPageSet();
			if (pageSet && pageSet.getPageMargins) {
				pageMargins = pageSet.getPageMargins();
			}

			let page = this.getPage();
			if (page && page.getPageMargins) {
				// Want page's margins to override pageSet's margins
				if (pageMargins) {
					pageMargins = $.extend({}, pageMargins, page.getPageMargins());
				} else {
					pageMargins = page.getPageMargins();
				}
			}

			if (pageMargins) {
				if(($.isInit(pageMargins.left) || $.isInit(pageMargins.right)) && $.isInit(pageMargins.horizontal)) {
					delete pageMargins.horizontal;
				}
				if(($.isInit(pageMargins.top) || $.isInit(pageMargins.bottom)) && $.isInit(pageMargins.vertical)) {
					delete pageMargins.vertical;
				}

				return $.extend({}, pageMargins);
			} else {
				return null;
			}
		},
		updatePageMargins: function () {
			let page = this.getPage();
			var margins = this.getPageMargins();

			this.updatePageMarginsEditable(page);
			this.canvasMargins.setMargins(margins);

			// See if we have page specific margin sizes
			if(page && page.getMaxMargins) {
				this.canvasMargins.setMaxMargins(page.getMaxMargins());
			}
		},
		updatePageMarginsEditable: function(page) {
			var canLayoutEditMargins = this.editable && $.getProjectSetting('allowMarginChanges', true);
			var canModelEditMargins = (this.getPageSet() && this.getPageSet().setPageMargins) || (page && page.setPageMargins);
			var pageLocked = page && page.getFreeMovementLocked();
			this.canvasMargins.setEditable(!!(canLayoutEditMargins && canModelEditMargins && !pageLocked));
			this.extraSubjectGrids.forEach(function(subjectGrid) {
				subjectGrid.setEditable(wrapper.editable && !pageLocked);
			});
		},
		updateTheme: function () {
			// Try to apply page, then global theme
			let page = this.getPage();
			if (page) {
				let theme = page.getTheme();
				if (!theme && this.getPageSet()) {
					theme = this.getPageSet().getTheme();
				}
				if (theme && theme.type !== 'empty') {
					this.setTheme(theme);
				} else {
					// This is needed or else lone background with no global theme won't be removed
					this.setTheme(null);
				}

				if (page.updatePageLabel) {
					page.updatePageLabel();
					this.setLabel(page.getPageLabel());
				}
			} else {
				this.setTheme(null);
			}
		},
		getActiveThemePart: function (theme) {
			if(!theme) {
				return null;
			}

			if (theme.type == 'themes' || (!$.isInit(theme.type) && !$.isInit(theme['Background']))) {
				var background = this.getActivePartName(theme);
				return theme[background];
			} else {
				var part;
				if(theme.type == 'project-background') {
					var pageSet = this.getPageSet();
					if(pageSet.projectBackgroundCdnUrl) {
						part = {
							id: pageSet.projectBackgroundId,
							cdnUrl: pageSet.projectBackgroundCdnUrl
						};
					} else if(pageSet.projectBackgroundId) {
						part = {
							id: pageSet.projectBackgroundId
						};
					} else {
						return null;
					}
				} else {
					part = $.extend(true, {}, theme['Background']);
					if (typeof part == 'string') {
						part = {
							id: part
						};
					}
				}

				let page = this.getPage();
				var settings = page.getExtraProperty(this.getBackgroundSettingKey());
				if(settings) {
					for (var setting in settings) {
						var value = settings[setting];
						if (value == 'false') {
							value = false;
						} else if (value == 'true') {
							value = true;
						}
						part[setting] = value;
					}
				}

				return part;
			}
		},
		getActivePartName: function (theme) {
			var name = this.page.getThemePartName();
			if(name == 'Preview') {
				return name;
			}

			var side;
			if (this.page.side) {
				if (this.page.side == 'front') {
					side = 'Left';
				} else {
					side = 'Right';
				}
			} else {
				if (this.side) {
					side = this.side;
				} else if (this.id == 'leftPage') {
					side = 'Left';
				} else {
					side = 'Right';
				}
			}

			return name + ' ' + side;
		},
		setTheme: function (theme, updating) {
			if (!this.backgroundImage) {
				this.backgroundImageWrapper = $('<div class="flowPageBackground"></div>').appendTo(this);
				this.backgroundImage = $('<img>').appendTo(this.backgroundImageWrapper);
			}
			if(this.userLabelError && this.userLabelError.includes && this.userLabelError.includes('Background ')) {
				this.setLabelError(false);
			}

			if (theme && this.page) {
				var themePart = this.getActiveThemePart(theme);

				var url;
				if(themePart && $.isInit(themePart.id)) {
					if(this.forcedDPI) {
						var width = this.inchWidth * this.forcedDPI;

						url = $.getPlicThumbnail(themePart, {
							w: Math.min(2000, width)
						});
					} else if (themePart.cdnUrl) {
						url = themePart.cdnUrl;
					} else {
						url = $.getPlicThumbnail(themePart, {
							w: this.defaultBackgroundSize
						});
					}

					$(this.backgroundImage).attr('src', url).show();
					this.applyThemeSettings(this.backgroundImage, themePart);
					this.checkThemePartValid(themePart);
				} else {
					$(this.backgroundImage).attr('src', '').hide();
				}
			} else {
				$(this.backgroundImage).attr('src', '').hide();
			}

			var backgroundCSS = {
				left: '',
				top: '',
				width: '',
				height: ''
			};
			let hiddenLeft = 0, hiddenTop = 0, hiddenWidth = 100, hiddenHeight = 100;
			if(this.inchHiddenBleed) {
				hiddenLeft = -(this.inchHiddenBleed.left / this.inchWidth);
				hiddenTop = -(this.inchHiddenBleed.top / this.inchHeight);
				hiddenWidth = ((this.inchHiddenBleed.left + this.inchHiddenBleed.right) / this.inchWidth + 1);
				hiddenHeight = ((this.inchHiddenBleed.top + this.inchHiddenBleed.bottom) / this.inchHeight + 1);
			}
			if(themePart && themePart.crop) {
				backgroundCSS.width = (themePart.crop.width + (hiddenWidth - 1)) * 100 + '%';
				backgroundCSS.height = (themePart.crop.height + (hiddenHeight - 1)) * 100 + '%';
				backgroundCSS.left = (Math.min(0, themePart.crop.left) + hiddenLeft) * 100 + '%';
				backgroundCSS.top = (Math.min(0, themePart.crop.top) + hiddenTop) * 100 + '%';
			} else if(this.inchHiddenBleed) {
				backgroundCSS.left = hiddenLeft * 100 + '%';
				backgroundCSS.top = hiddenTop * 100 + '%';
				backgroundCSS.width = hiddenWidth * 100 + '%';
				backgroundCSS.height = hiddenHeight * 100 + '%';
			}
			$(this.backgroundImage).css(backgroundCSS);

			let page = this.getPage();
			if(theme && page && page.getBackgroundOnlyInContainer && page.getBackgroundOnlyInContainer()) {
				$(this.backgroundImageWrapper).css({
					left: ((this.inchBleed.left / this.inchWidth) * 100) + '%',
					top: ((this.inchBleed.top / this.inchHeight) * 100) + '%',
					width: ((1 - (this.inchBleed.left + this.inchBleed.right) / this.inchWidth) * 100) + '%',
					height: ((1 - (this.inchBleed.top + this.inchBleed.bottom) / this.inchHeight) * 100) + '%'
				});
			} else {
				$(this.backgroundImageWrapper).css({
					left: '',
					top: '',
					width: '',
					height: ''
				});
			}

			this.saveTheme(theme);
		},
		checkThemePartValid: function(themePart) {
			let pageSet = this.getPageSet();
			if(!themePart.photoWidth || !themePart.photoHeight || !pageSet) {
				return;
			}

			let photoWidth = themePart.photoWidth;
			let photoHeight = themePart.photoHeight;
			if(themePart.crop) {
				photoWidth = photoWidth * (1 / themePart.crop.width);
				photoHeight = photoHeight * (1 / themePart.crop.height);
			}
			let photoAspectRatio = photoWidth / photoHeight;

			let backgroundSize = this.getBackgroundInchSize();
			let layoutAspectRatio = backgroundSize.width / backgroundSize.height;
			let minimumResolution = $.getStudioSetting('minimumBackgroundDPI', 50);
			let minWidth = Math.round(backgroundSize.width * minimumResolution);
			let minHeight = Math.round(backgroundSize.height * minimumResolution);
			if(photoWidth < minWidth || photoHeight < minHeight) {
				this.setLabelError('Background is too small at ' + Math.floor(photoWidth) + ' x ' + Math.floor(photoHeight) + ' (needs to be a minumum of ' + minWidth + ' x ' + minHeight + ')', false);
			} else if(!$.isWithinDiff(photoAspectRatio, layoutAspectRatio, 0.05)) {
				this.setLabelError('Background has the wrong aspect ratio of ' + photoAspectRatio.toFixed(2) + ' when it should be ' + layoutAspectRatio.toFixed(2) + '.  Please upload a new one or crop it from the orange gear option in the corner and the clicking on the Backgrounds option.', false);
			}
		},
		getBackgroundInchSize: function() {
			let width = this.inchWidth;
			let height = this.inchHeight;

			let page = this.getPage();
			if(page && page.getBackgroundOnlyInContainer && page.getBackgroundOnlyInContainer()) {
				width  = this.inchWidth - this.inchBleed.left - this.inchBleed.right;
				height = this.inchHeight - this.inchBleed.top - this.inchBleed.bottom;
			}

			return {
				width: width,
				height: height
			};
		},
		isThemePartTwoPageSpread: function(themePart) {
			let photoWidth = themePart.photoWidth;
			let photoHeight = themePart.photoHeight;
			if(!photoWidth || !photoHeight) {
				return false;
			}

			let photoAspectRatio = photoWidth / photoHeight;
			let layoutAspectRatio = (this.inchWidth * 2) / this.inchHeight;

			return $.isWithinDiff(photoAspectRatio, layoutAspectRatio, 0.05);
		},
		saveTheme: function(theme) {
			// Only try to change theme if one is set
			if(this.page && theme) {
				if(theme.type == 'themes' || (!$.isInit(theme.type) && !$.isInit(theme['Background']))) {
					// Reset the current background if one exists
					this.page.setTheme(null);
				} else {
					this.page.setTheme(theme.name ? theme.name : theme);
				}
			}
		},
		applyThemeSettings: function (backgroundImage, settings) {
			var filter = '';
			if (settings.opacity) {
				$(backgroundImage).css('opacity', settings.opacity / 100.0);
			} else {
				$(backgroundImage).css('opacity', '');
			}

			if (settings.flipX || settings.flipY) {
				var transform = [];

				if (settings.flipX) {
					transform.push('scaleX(-1)');
				}
				if (settings.flipY) {
					transform.push('scaleY(-1)');
				}

				$(backgroundImage).css('transform', transform.join(' '));
			} else {
				$(backgroundImage).css('transform', '');
			}

			if($(this).hasClass('forceGrayscale')) {
				// Want to add manual grayscale to theme dialog but rely on forceGrayscale for in layout
				if($(backgroundImage).css('filter') !== 'grayscale(1)') {
					filter += 'grayscale(1) ';
				}
			} else {
				if(settings.grayscale) {
					filter += 'grayscale(100%) ';
				}

				if(settings.hue) {
					filter += 'hue-rotate(' + settings.hue + 'deg) ';
				}

				if(settings.brightness && settings.brightness != 100) {
					filter += 'brightness(' + (settings.brightness  / 100) + ') ';
				}
				if(settings.contrast !== undefined && settings.contrast !== null && settings.contrast != 100) {
					filter += 'contrast(' + settings.contrast + '%) ';
				}
				if(settings.saturation && settings.saturation != 100) {
					filter += 'saturate(' + settings.saturation + '%) ';
				}
			}

			$(backgroundImage).css({
				filter: filter,
				'-webkit-filter': filter
			});
		},
		getOpenThemeSettingsTheme: function(page, pageSet) {
			let theme = page.getTheme();
			var settingsOnPart = false;
			if (!theme && pageSet) {
				theme = pageSet.getTheme();
				settingsOnPart = true;
			}

			return {
				theme,
				settingsOnPart
			};
		},
		openThemeSettings: function () {
			var me = this;
			let page = this.getPage();
			var pageSet = this.getPageSet();
			let { theme, settingsOnPart } = this.getOpenThemeSettingsTheme(page, pageSet);
			var themePart = this.getActiveThemePart(theme);
			if(!themePart) {
				return;
			}
			let backgroundSettingKey = this.getBackgroundSettingKey();
			var settings;
			if (settingsOnPart) {
				settings = themePart;
			} else {
				settings = page.getExtraProperty(backgroundSettingKey, {});
			}

			var url;
			if (themePart.cdnUrl) {
				url = themePart.cdnUrl;
			} else {
				url = $.getPlicThumbnail(themePart, {w: 400});
			}

			if (settings) {
				settings = $.extend(true, {}, settings);
			} else {
				settings = {};
			}

			let modal = $.ImageSettingsDialog([
				{
					description: 'Settings',
					type: 'section',
					settings: [
						{
							name: 'grayscale',
							description: 'Grayscale',
							type: 'checkbox',
							value: settings.grayscale ? settings.grayscale : false
						},
						{
							name: 'opacity',
							description: 'Opacity: %value%%',
							type: 'inc/dec',
							range: [5, 100],
							value: settings.opacity ? settings.opacity : 100,
							inc: 5
						},
						{
							name: 'saturation',
							description: '%value%% Saturation',
							type: 'inc/dec',
							range: [5, 200],
							inc: 5,
							value: settings.saturation,
							defaultValue: 100
						},
						{
							name: 'contrast',
							description: '%value%% Contrast',
							type: 'inc/dec',
							range: [10, 160],
							inc: 5,
							minDisplay: 'Min',
							value: settings.contrast,
							defaultValue: 100
						},
						{
							name: 'brightness',
							description: '%value%% Brightness',
							type: 'inc/dec',
							range: [10, 200],
							inc: 5,
							minDisplay: 'Min',
							value: settings.brightness,
							defaultValue: 100
						},
						{
							name: 'hue',
							description: '%value%\u00B0 Hue',
							type: 'inc/dec',
							range: [-180, 180],
							inc: 15,
							value: settings.hue,
							defaultValue: 0
						},
						{
							name: 'flipX',
							description: 'Flip Horizontally',
							type: 'checkbox',
							value: settings.flipX ? settings.flipX : false
						},
						{
							name: 'flipY',
							description: 'Flip Vertically',
							type: 'checkbox',
							value: settings.flipY ? settings.flipY : false
						}
					]
				}
			], {
				title: 'Backgrounds Settings',
				wrapperDim: window.innerHeight / 2,
				image: {
					url: url
				},
				onShow: function() {
					if(!settingsOnPart) {
						this.initImageCrop();
					}
				},
				initImageCrop: function() {
					let backgroundSize = me.getBackgroundInchSize();
					let aspectRatio = backgroundSize.width / backgroundSize.height;
					// max-width with super wide images breaks the cropper from having a correct aspect ratio
					$(this.img).css('max-width', '');
					if(themePart.photoWidth && themePart.photoHeight && (themePart.photoWidth / themePart.photoHeight) > 1.8) {
						$(this).addClass('large');
					}

					this.cropper = new Cropper(this.img, {
						checkCrossOrigin: false,
						guides: false,
						background: false,
						autoCropArea: 1,
						rotatable: true,
						zoomable: false,
						aspectRatio: aspectRatio,
						viewMode: 1,
						minCropBoxWidth: 40,
						minCropBoxHeight: 40,
						ready: () => {
							this.startingContainerData = $.extend(true, {}, this.cropper.containerData);
							
							// Apply img settings to cropped images as well
							let settings = modal[0].settingsForm.currentSettings;
							let img = $(this).parent().find('img');
							me.applyThemeSettings(img, settings);

							let existingCrop = this.getScaledCrop();
							if(existingCrop) {
								this.cropper.setCropBoxData(existingCrop);
							}
						},
						crop: () => {
							// Crop transform overwrites the transform scale we give in applyThemeSettings
							let settings = modal[0].settingsForm.currentSettings;
							if(settings.flipX || settings.flipY) {
								let cropViewImg = this.cropper.viewBoxImage;
								let cropTransform = cropViewImg.style.transform;

								if(settings.flipX) {
									cropTransform += ' scaleX(-1)';
								}
								if(settings.flipY) {
									cropTransform += ' scaleY(-1)';
								}
								cropViewImg.style.transform = cropTransform;
							}
						}
					});
				},
				getPercentCrop: function() {
					var rawCrop = this.cropper.getCropBoxData();
					var container = this.startingContainerData || this.cropper.getContainerData();

					return {
						left: $.reduceFloatPrecision(rawCrop.left / container.width),
						top: $.reduceFloatPrecision(rawCrop.top / container.height),
						width: $.reduceFloatPrecision(rawCrop.width / container.width),
						height: $.reduceFloatPrecision(rawCrop.height / container.height)
					};
				},
				getScaledCrop: function() {
					if(settings.crop) {
						let existingCrop = $.extend(true, {}, settings.crop);

						let width = existingCrop.width;
						let height = existingCrop.height;

						existingCrop.width = 1 / existingCrop.width;
						existingCrop.height = 1 / existingCrop.height;
						existingCrop.x = Math.abs(existingCrop.left / width);
						existingCrop.y = Math.abs(existingCrop.top / height);

						var container = this.cropper.getContainerData();
						var scaledCrop = {
							left: existingCrop.x * container.width,
							top: existingCrop.y * container.height,
							width: existingCrop.width * container.width,
							height: existingCrop.height * container.height
						};

						return scaledCrop;
					} else {
						return null;
					}
				},
				initImageSettings: function (img) {
					this.img = img[0];
					me.applyThemeSettings(img, this.settingsForm.originalSettings);
				},
				onSettingChange: function (setting, value) {
					var img = $(this).parent().find('img');

					me.applyThemeSettings(img, this.currentSettings);
					if(modal[0].cropper) {
						modal[0].cropper.setCropBoxData(modal[0].cropper.getCropBoxData());
					}
				},
				onSettingsApplied: function (allSettings, changes) {
					changes.forEach(change => {
						settings[change.id] = change.value;
					});

					if(this.startingContainerData) {
						let crop = this.getPercentCrop();
						let width, height, left, top;
						width = (1 / crop.width);
						height = (1 / crop.height);
						left = -(crop.left * width);
						top = -(crop.top * height);

						settings.crop = {
							width: width,
							height: height,
							top: top,
							left: left
						};
					}

					if (themePart == theme.Background || theme.type == 'backgrounds' || theme.type === 'project-background') {
						page.extraPropertyChange(backgroundSettingKey, settings, true);
					} else {
						// Figure out if there is a flip side to the theme and apply it
						for (var name in theme) {
							// Don't do this for the covers
							if (theme[name] == themePart && name.indexOf('Cover') == -1) {
								var flipName;
								if (name.indexOf('Left') != -1) {
									flipName = name.replace('Left', 'Right');
								} else {
									flipName = name.replace('Right', 'Left');
								}

								var flipTheme = theme[flipName];
								if (flipTheme) {
									for (let i = 0; i < changes.length; i++) {
										var change = changes[i];
										flipTheme[change.id] = change.value;
									}
								}

								break;
							}
						}

						var themePartName = me.getActivePartName();
						var newTheme = $.extend(true, {}, theme);
						newTheme[themePartName] = settings;
						pageSet.setTheme(newTheme);
					}

					if (me.parent) {
						me.parent.updateTheme();
					} else {
						me.updateTheme();
					}
				}
			});
		},
		clearTheme: function () {
			this.parent.setTheme(null);
		},
		clearBackground: function () {
			let page = this.getPage();
			page.setTheme(null);
			this.updateTheme();

			page.updatePageLabel();
			this.setLabel(page.getPageLabel());
		},

		refreshTitle: function() {
			let page = this.getPage();
			let title = page.getTitle();
			this.setTitle(title, false);
			this.canvas.checkUpdateRowCount();

			$(this.canvas).find('.flowTitle').each(function() {
				if(this.extraTitleId) {
					title = page.getExtraTitle(this.extraTitleId);

					if(title) {
						this.setTitle(title, false);
					}
				}
			});

			if (this.childLayout) {
				this.childLayout.refreshTitle();
			}
		},
		setTitle: function (title, save) {
			// Only do something if title div is defined
			if (this.titleDiv) {
				this.titleDiv.setTitle(title);

				if (this.page && save !== false) {
					this.page.setTitle(title);
				}
			}
		},

		hideFrameOverflow: function() {
			let cells = this.getAllCells();
			var frames = $(this.container).children('.flowContent');

			var changed = false;
			frames.each(function () {
				if(this.hideOverlap(cells)) {
					changed = true;
				}
			});

			return changed;
		},
		addUsers: function(users, allowOverflow, params) {
			let page = this.getPage();
			this.setLabelError(false);
			if(users && page) {
				$.fireErrorReport(null, 'Invalid addUsers call', 'addUsers(users) should be depreciated');
			} else if(page && page.getKids) {
				if(page.getClass() && page.getKids()) {
					users = page.getKids();
					$(this).removeClass('classPlaceholder');

					if(page.getRootPage && page.getRootPage() === page && page.getOverflowPage) {
						var overflowPage = page.getOverflowPage();
						while(overflowPage) {
							overflowPage.setKids(null, false);
							overflowPage = overflowPage.getOverflowPage();
						}
					}
				} else {
					$(this).addClass('classPlaceholder');
					this.canvas.clearUsers();
					return;
				}
			} else {
				return;
			}

			let layout = this.getLayout();
			// Layout is null when we are focused on the title during transition
			if(!layout) {
				return;
			}

			if(this.titleDiv) {
				this.titleDiv.extraBatch = null;
			}

			var groupsArray = [];
			if(layout.separateGrids && users.length) {
				for(let i = wrapper.extraSubjectGrids.length; i < layout.separateGrids.length; i++) {
					var extraGrid = new $.FlowLayoutSubjectGridEditable({
						flowLayout: wrapper,
						ratio: this.ratio,
						editable: this.editable && !page.getFreeMovementLocked()
					});
					wrapper.extraSubjectGrids.push(extraGrid);
					this.canvasMargins.appendChild(extraGrid);
				}

				var canvasCells = this.canvas.getCells();
				layout.separateGrids.forEach(function(separateGrid, index) {
					var removeUsers = users.filter(function(user) {
						return !!user[separateGrid.field];
					});

					if(removeUsers.length) {
						groupsArray.push(removeUsers);
						users = users.filter(function(user) {
							return removeUsers.indexOf(user) === -1;
						});

						let subjectGrid = wrapper.extraSubjectGrids[index];
						subjectGrid.setCustomSize(separateGrid, index);
						subjectGrid.hideOverlap(canvasCells);

						if(!separateGrid.studentLabelCSS) {
							separateGrid.studentLabelCSS = new $.CSSBundle();
						} else if(!separateGrid.studentLabelCSS.setCSS) {
							separateGrid.studentLabelCSS = new $.CSSBundle(null, separateGrid.studentLabelCSS);
						}
						subjectGrid.studentLabelCSS = separateGrid.studentLabelCSS;
					} else {
						let subjectGrid = wrapper.extraSubjectGrids[index];
						wrapper.extraSubjectGrids.splice(index, 1);
						$(subjectGrid).remove();
					}
				});
			} else if(this.extraSubjectGrids.length) {
				while(this.extraSubjectGrids.length) {
					let subjectGrid = this.extraSubjectGrids.pop();
					subjectGrid.destroy();
					$(subjectGrid).remove();
				}
			}

			if(this.largeCellUser >= 0 && this.definition && this.definition.primarySubjectPoseFrame) {
				var deleteIndex = this.largeCellUser;
				users = users.filter(function(user, index) {
					return index !== deleteIndex;
				});
			}

			this.canvas.addUsers(users, allowOverflow, params);
			if(groupsArray.length) {
				groupsArray.forEach(function(group, index) {
					let subjectGrid = wrapper.extraSubjectGrids[index];
					subjectGrid.addUsers(group, allowOverflow, params);
				});
			}
		},
		hardRefreshFitLayout: function(layout, flowFrames) {
			if(!flowFrames) {
				flowFrames = this.canvas.getBlockingContent();
			}

			flowFrames.each(function () {
				this.removeHidden();
			});
		
			var users = this.getPage().getKids();

			var groupsArray = [];
			if(layout.separateGrids && users.length) {
				layout.separateGrids.forEach(function(separateGrid, index) {
					var removeUsers = users.filter(function(user) {
						return !!user[separateGrid.field];
					});

					if(removeUsers.length) {
						groupsArray.push(removeUsers);
						users = users.filter(function(user) {
							return removeUsers.indexOf(user) === -1;
						});
					}
				});
			}

			this.calculateCellsToFit(layout, users);
			this.canvas.addCellsToLayout(layout);
			
			groupsArray.forEach(function(group, index) {
				var separateLayout = $.extend(true, {}, layout);
				let subjectGrid = wrapper.extraSubjectGrids[index];
				subjectGrid.calculateCellsToFit(separateLayout, group);
				subjectGrid.addCellsToLayout(separateLayout);
			});
		},

		swapOrShift: function (draggedCell, droppedCell) {
			let page = this.getPage();
			// Get kids returns a copy of array
			var users = page.getClass().subjects;
			if (!users) {
				return;
			}

			let cells = $(this.canvas).find('.flowCell:not(.rowHeader)');
			var draggedUser = draggedCell.user['First Name'];
			var droppedUser = droppedCell.user['First Name'];
			var draggedIndex = cells.index(draggedCell);
			var droppedIndex = cells.index(droppedCell);

			var me = this;
			// Only allow place before for large cells
			if ($(droppedCell).hasClass('largeCell')) {
				$('<div class="ui modal"><i class="close icon"></i><div class="header">What would you like to do?</div><div class="content"><div class="description">Would you like to place ' + draggedUser + ' before ' + droppedUser + '?</div></div></div>')
					.append('<div class="actions"><div class="ui deny button">Cancel</div><div class="ui approve button">Place Before</div></div>')
					.modal({
						onApprove: function () {
							me.shiftUser(draggedCell, droppedCell, draggedIndex > droppedIndex, true);
						},
						onHidden: function () {
							$(this).remove();
						}
					}).modal('show');
			} else {
				$('<div class="ui modal"><i class="close icon"></i><div class="header">What would you like to do?</div><div class="content"><div class="description">Would you like to swap ' + draggedUser + ' with ' + droppedUser + ' or place ' + draggedUser + ' before ' + droppedUser + '?</div></div></div>')
					.append('<div class="actions"><div class="ui deny button">Swap</div><div class="ui approve button">Place Before</div></div>')
					.modal({
						onDeny: function () {
							me.swapUsers(draggedCell, droppedCell);
						},
						onApprove: function () {
							let nextCell = droppedCell.getNextCell();

							me.shiftUser(draggedCell, droppedCell, nextCell != null && $(nextCell).hasClass('largeCell') && draggedIndex > droppedIndex);
						},
						onHidden: function () {
							$(this).remove();
						}
					}).modal('show');
			}
		},
		swapUsers: function (draggedCell, droppedCell) {
			let page = this.getPage();
			var draggedUser = draggedCell.user;
			var droppedUser = droppedCell.user;
			if (!draggedUser || !droppedUser || !page) {
				return;
			}

			// Swap teacher definition
			var draggedTeacher = draggedUser['Teacher Priority'];
			draggedUser['Teacher Priority'] = droppedUser['Teacher Priority'];
			droppedUser['Teacher Priority'] = draggedTeacher;

			page.swapStudents(draggedUser, droppedUser);

			// Switch the users of the cells
			draggedCell.setUser(droppedUser);
			droppedCell.setUser(draggedUser);
			this.updateLabelCSS();
		},
		shiftUser: function (draggedCell, droppedCell, incrementTeacherCell, incrementDroppedUser) {
			let page = this.getPage();
			var draggedUser = draggedCell.user;
			var droppedUser = droppedCell.user;

			if(incrementTeacherCell) {
				var cellColumns = this.canvas.rows[0].cells.length - 1;
				var largeCellPosition = page.getLargeCellPosition();
				if(!largeCellPosition) {
					largeCellPosition = {
						col: this.canvas.largeCell.col == -1 ? cellColumns : 0,
						row: 0
					};
				}
				var startCellPosition = $.extend(true, {}, largeCellPosition);
				largeCellPosition = $.extend(true, {}, largeCellPosition);
				if(largeCellPosition.col === -1) {
					largeCellPosition.col = cellColumns;
				}
				largeCellPosition.col++;

				let nextCell = droppedCell.getNextCell(false);
				if(!nextCell && largeCellPosition.row < (this.canvas.rows.length - 2)) {
					largeCellPosition.row++;
					largeCellPosition.col = 0;
				}

				page.setLargeCellPosition($.extend(true, {}, largeCellPosition));
				$.extend(this.canvas.largeCell, largeCellPosition);

				if($.userEvents) {
					$.userEvents.addEvent({
						context: [page, 'largeCellPosition'],
						action: 'update',
						args: [
							{
								col: startCellPosition.col,
								row: startCellPosition.row
							},
							{
								col: largeCellPosition.col,
								row: largeCellPosition.row
							}
						]
					});
				}
			}
			if(incrementDroppedUser) {
				let nextCell = droppedCell.getNextCell();
				while($(nextCell).hasClass('largeCell')) {
					nextCell = nextCell.getNextCell();
				}

				if(nextCell && nextCell.user) {
					droppedUser = nextCell.user;
				}
			}
			if(!draggedUser || !droppedUser) {
				console.warn('Invalid dragged cells: ', draggedCell, droppedCell);
				return;
			}
			page.shiftStudent(draggedUser, droppedUser);

			let layout = this;
			if (layout.parentLayout) {
				layout = layout.parentLayout;
				// Want to force addUsers() to call for child.  Bad HACK!
				page.getRootPage().layoutChanged = false;
			}
			layout.addUsers();
		},
		updateOverflowPages: function (page, users, startsFromRoot, params) {
			// Just update existing overflow pages
			var currentPage = page, overflowPage = currentPage.getOverflowPage();
			var addedPage = false;
			while(users.length) {
				// Might need to start adding additional pages
				var userSpots = this.canvas.maxUsers;
				if (!overflowPage) {
					if(this.editable && params.allowCreatingPages !== false) {
						if(params.onCreatePage) {
							// Just dump all kids on last overflow page so we don't lose any
							let kids = [];
							$.merge(kids, currentPage.getKids());
							$.merge(kids, users);
							currentPage.setKids(kids, true);

							params.onCreatePage({
								success: () => {
									this.addUsers();
								}
							});
						} else {
							if (!userSpots && startsFromRoot && currentPage.getViableCells && currentPage.getViableCells()) {
								userSpots = currentPage.getViableCells();
							}
							if (userSpots === 0) {
								console.error('Infinite recursion detected due to userSpots == 0');
								if (window.Bugsnag) {
									try {
										window.Bugsnag.notify('Infinite recursion', 'Infinite recursion detected due to userSpots == 0', {
											snapshot: this.getSnapshot()
										}, 'error');
									} catch (e) {
										console.error(e);
									}
								}
								break;
							}

							overflowPage = currentPage.createOverflowPage(users.slice(0, userSpots));
							var isPageAdded = this.getPageSet().addPage(overflowPage, currentPage.getPageNumber() - 1);
							if(isPageAdded) {
								currentPage.setOverflowPage(overflowPage);
								addedPage = true;
							} else {
								break;
							}
						}
					} else if(currentPage.getRootPage && currentPage.getRootPage() != currentPage) {
						// Just dump all kids on last overflow page so we don't lose any
						let kids = [];
						$.merge(kids, currentPage.getKids());
						$.merge(kids, users);

						currentPage.setKids(kids, true);
					}
				} else {
					if (overflowPage.getViableCells && $.isInit(overflowPage.getViableCells())) {
						userSpots = overflowPage.getViableCells();
					}
					// If we are starting from the root and we already know how many kids go in this spot, try to use that number
					else if (startsFromRoot && overflowPage.getKids()) {
						// Only use that number if there is another overflow page past here
						// Otherwise if we just ran out of kids at 5, this page will never go beyond 5
						if (!page.layoutChanged && overflowPage.getOverflowPage()) {
							userSpots = overflowPage.getKids().length;
						}
					} else if (page.getMaxCells && page.getMaxCells()) {
						userSpots = page.getMaxCells();
					}
					overflowPage.setKids(users.slice(0, userSpots), true);
				}

				// Cleanup for next round
				if(overflowPage) {
					currentPage = overflowPage;
					users = users.slice(userSpots);

					overflowPage = currentPage.getOverflowPage();
				} else {
					// This should only be reached if non-editable
					break;
				}
			}

			// Only allow anything to be remvoed if we are editing
			if(this.editable && currentPage.getOverflowPage()) {
				var removePage = currentPage.getOverflowPage();
				let index = this.getPageSet().removePageCascade(removePage);
				if(index !== false) {
					currentPage.setOverflowPage(null);

					$.setSingleTimeout.call(this, 'onOverflowPageRemove', function() {
						this.checkOverflowPageWithContent(removePage, index);
					}, 10);
				}
			}

			if (addedPage) {
				this.onOverflowPageAdded();
			}
		},
		onOverflowPageAdded: function() {
			if (this.isPastPageLimit()) {
				var showError = true;
				// Check if we can remove any pages
				var pageSet = this.getPageSet();
				if(this.editable && pageSet && pageSet.removeEmptyPage) {
					// pageSet.removeEmptyPage();
					showError = this.isPastPageLimit();
				}

				if(showError) {
					// $.Alert('Error', 'Another page for your class has been added.  You are past your allocated page limit and will not be able to submit this book.');
				}
			}
		},
		setOverflowPage: function (overflowPage) {
			let page = this.getPage();
			if (overflowPage) {
				var overflowLayout = this.parent.getNextVisiblePage(this);
				// Overflow is null if next page is not visible
				if (overflowLayout != null) {
					overflowLayout.setPage(overflowPage);

					// Setup child/parent relationship
					this.childLayout = overflowLayout;
					overflowLayout.parentLayout = this;
				}
			} else if (page.getOverflowPage()) {
				// Remove old overflow pages
				var removePage = page.getOverflowPage();
				let index = this.getPageSet().removePageCascade(removePage);
				if(index !== false) {
					page.setOverflowPage(null);
					this.updateChildLayout(index);

					$.setSingleTimeout.call(this, 'onOverflowPageRemove', function() {
						this.checkOverflowPageWithContent(removePage, index);
					}, 10);
				}
			}

			this.flushDelayedSaves();
		},
		checkOverflowPageWithContent: function(overflowPage, index) {
			if(overflowPage.shouldPromptToSave && overflowPage.shouldPromptToSave()) {
				this.promptToSaveOverflowPage(overflowPage, index);
			}
		},
		promptToSaveOverflowPage: function(overflowPage, index) {
			var content = $('<div class="content">').text('You deleted an overflow page with content on it.  Do you want to keep this page as a candid page?');
			var previewBox = $('<div style="margin-left: auto; margin-right: auto; margin-bottom: 1em;">').prependTo(content);
			var layoutPreview = new $.LayoutPreview({
				previewBox: previewBox,
				previewWidth: 300
			});
			layoutPreview.setLayout({
				definition: overflowPage.createLayoutTemplate({
					stripCandids: false
				})
			});

			var me = this;
			$('<div class="ui small modal">')
				.append('<i class="close icon">')
				.append($('<div class="header">').text('Keep Content Page?'))
				.append(content)
				.append('<div class="actions"><div class="ui deny button">No</div><div class="ui approve primary button">Yes</div></div>')
				.modal({
					onApprove: function() {
						me.saveOverflowPageAsCandid(overflowPage, index);
					},
					onHidden: function() {
						$(this).remove();
					}
				}).modal('show');
		},
		saveOverflowPageAsCandid: function(overflowPage, index) {
			var starterSettings = {};
			for(var id in overflowPage) {
				if(['pageSet', 'id', 'type', 'updateRootPageProperties', 'db', 'lockOption', 'pageLabel', 'parentPage', 'studentLabelCSS'].indexOf(id) === -1 && typeof overflowPage[id] !== 'function') {
					starterSettings[id] = overflowPage[id];
				}
			}

			var newPage = new $.YearbookCandidPage(starterSettings);
			this.getPageSet().addPage(newPage, index - 1);

			this.parent.updatePagesAfterChangeImmediately();
		},
		updateChildLayout: function(index) {
			if (this.childLayout) {
				if(!$.isInit(index)) {
					index = this.getPage().getPageNumber();
				}

				var nextPage = null;

				// We have a next page we should be displaying
				if (this.getPageSet().getTotalPages() > index) {
					nextPage = this.getPageSet().getPage(index);
				}

				this.childLayout.setPage(nextPage);
				this.childLayout = null;
			}
		},
		setLabelCSS: function (name, value, updateFamily) {
			if(this.canvas.studentLabelCSS.getCSS(name) === value && updateFamily !== false) {
				return;
			}

			this.canvas.studentLabelCSS.setCSS(name, value);
			this.extraSubjectGrids.forEach(function(subjectGrid) {
				subjectGrid.studentLabelCSS.setCSS(name, value);
			});

			if(name == 'font-size-multiplier') {
				this.updateCellDefinition();
			} else {
				this.canvas.setLabelCSS(name, value);

				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setLabelCSS(name, value);
				});
			}

			if(updateFamily !== false) {
				if (this.childLayout) {
					this.childLayout.setLabelCSS(name, value, false);
				}
				if (this.parentLayout) {
					this.parentLayout.setLabelCSS(name, value, false);
				}
			}
		},
		updateLabelCSS: function(updateFamily) {
			this.canvas.updateLabelCSS();

			if(updateFamily) {
				if(this.childLayout) {
					this.childLayout.updateLabelCSS();
				}
				if(this.parentLayout) {
					this.parentLayout.updateLabelCSS();
				}
			}
		},
		setCellTextCSS: function(id, name, value, propogate) {
			let page = this.getPage();

			if(this.side == 'Right' && name === 'align' && ['left', 'right'].indexOf(value) !== -1 && ['inside', 'outside'].indexOf(this.definition.cellTexts[0].lines.align) !== -1) {
				value = value === 'left' ? 'right' : 'left';
			}

			// If we save on the propogations then we will save the right page flip from above
			if(propogate) {
				var obj = $.extend(true, {}, page.getSubjectCellDataValue('global', id));
				if(!obj) {
					obj = {};
				}
				obj[name] = value;
				page.setSubjectCellDataValue('global', id, obj);
			}

			this.canvas.setCellTextCSS(id, name, value);
			if(propogate) {
				if (this.childLayout) {
					this.childLayout.setCellTextCSS(id, name, value, false);
				}
				if (this.parentLayout) {
					this.parentLayout.setCellTextCSS(id, name, value, false);
				}
			}
		},
		updateCellTextCSS: function(id, propogate) {
			this.canvas.updateCellTextCSS(id);
			this.extraSubjectGrids.forEach(function(subjectGrid) {
				subjectGrid.updateCellTextCSS(id);
			});

			if(propogate) {
				if (this.childLayout) {
					this.childLayout.updateCellTextCSS(id, false);
				}
				if (this.parentLayout) {
					this.parentLayout.updateCellTextCSS(id, false);
				}
			}
		},
		updateCellDisplay: function(cellId, subjectId, propogate) {
			this.getCells().each(function() {
				if(this.user && this.user.id == subjectId) {
					if(cellId === 'imgWrapperPosition') {
						this.updateSubjectImagePosition();
					} if(cellId === 'imgWrapperSize') {
						this.updateSubjectImageSize();
					} else {
						this.updateCellTextCSS(cellId);
					}
				}
			});

			if(propogate) {
				if (this.childLayout) {
					this.childLayout.updateCellDisplay(cellId, subjectId, false);
				}
				if (this.parentLayout) {
					this.parentLayout.updateCellDisplay(cellId, subjectId, false);
				}
			}
		},
		getAllCells: function() {
			let cells = this.canvas.getCells();

			this.extraSubjectGrids.forEach(function(subjectGrid) {
				cells = cells.add(subjectGrid.getCells());
			});

			return cells;
		},
		getCells: function() {
			return this.canvas.getCells();
		},
		getLargeCells: function() {
			return this.canvas.getLargeCells();
		},
		getUsedCells: function() {
			return this.canvas.getUsedCells();
		},

		setupPageNumber: function () {
			var me = this;
			var side = this.side ? (this.side.toLowerCase() + 'Page') : this.id;

			var defaultFontSize = 14;
			var pageSet = this.getPageSet();
			if(pageSet && pageSet.getDefaultPageNumberFontSize) {
				defaultFontSize = pageSet.getDefaultPageNumberFontSize();
			}
			
			this.pageNumberDiv = $.FlowLayoutSVGEdge($.extend({
				id: side + 'Number',
				addClass: 'flowPageNumber',
				wrapper: this,
				ratio: this.ratio,
				container: this.container,
				editable: this.editable,
				textEditable: false,
				mirrorSides: $(this).attr('id') == 'rightPage',
				snapPositions: {
					bottom: true
				},
				defaultFontSize: Math.round(defaultFontSize * (this.inchHeight / 11.25)),
				onSnapChange: function (position) {
					me.getPageSet().setPageNumberPosition(position);
					me.parent.updatePageNumberPosition();
				},
				editTools: [
					'styles',
					'font-family',
					'font-size',
					'padding-multipler',
					'text-colors',
					'effects',
					{
						icon: 'edit',
						popup: 'Activate to give this page number different styles than the rest of your Book',
						isActive: () => {
							let page = this.getPage();
							if(page.pageNumberCSS) {
								return true;
							} else {
								return false;
							}
						},
						onClick: () => {
							let page = this.getPage();
							if(page.pageNumberCSS) {
								page.clearPageNumberCSS();
							} else {
								page.setPageNumberCSS($.extend(true, {}, me.getPageSet().getPageNumberCSS().css));
							}

							me.parent.updatePageNumberCSS();
						}
					}
				],
				onChangeSelectedText: function (selection, name, value) {
					var pageNumberCSS = me.getPageNumberCSS();
					pageNumberCSS.setCSS(name, value);

					me.parent.updatePageNumberCSS();
				},
				onUpdatePaddingMultiplier: function() {
					me.updatePageNumberPosition();
				}
			}, this.getContentSettings()));
			$(this.pageNumberDiv).removeClass('flowContent');
			$(this.container).append(this.pageNumberDiv);
		},

		setPageNumber: function (pageNumber) {
			let page = this.getPage();
			page.setPageNumber(pageNumber);
			this.updatePageNumber(pageNumber);
		},
		updatePageNumber: function (pageNumber) {
			if (this.pageNumberDiv) {
				if (typeof pageNumber == 'undefined') {
					pageNumber = this.getPage().getPageNumber();
				}

				$(this.pageNumberDiv).show();

				let pageNumberCSS = $.extend(true, {}, this.getPageNumberCSS().css);

				let extraProps = {};
				['border', 'opacity', 'curve'].forEach(prop => {
					if(pageNumberCSS[prop] !== undefined) {
						extraProps[prop] = pageNumberCSS[prop];
						delete pageNumberCSS[prop];
					}
				});
				this.pageNumberDiv.setInstance({
					lines: $.extend({
						text: pageNumber - $.PAGE_OFFSET
					}, pageNumberCSS),
					...extraProps
				});

				this.updatePageNumberCSS();
				this.updatePageNumberPosition();
			}
		},
		updatePageNumberPosition: function () {
			if (this.pageNumberDiv && this.pageNumberDiv.style.display !== 'none') {
				var position = this.getPageSet().getPageNumberPosition();

				if (this.side == 'Right') {
					if (position.indexOf('left') != -1) {
						position = position.replace('left', 'right');
					} else {
						// Can be center, but won't change anything at this point
						position = position.replace('right', 'left');
					}
				}

				this.pageNumberDiv.snapToPosition(position);
			}
		},
		updatePageNumberCSS: function () {
			if (this.pageNumberDiv) {
				var pageSet = this.getPageSet();
				var padding = pageSet.getPageNumberPadding();
				var pageNumberCSS = this.getPageNumberCSS();
				if(pageNumberCSS && pageNumberCSS.css.paddingMultiplier) {
					padding *= (parseInt(pageNumberCSS.css.paddingMultiplier) / 2.0);
				}

				this.pageNumberDiv.setPadding(padding);
			}
		},
		getPageNumberCSS: function() {
			let page = this.getPage();
			if(page && page.pageNumberCSS) {
				return page.pageNumberCSS;
			} else {
				return this.getPageSet().getPageNumberCSS();
			}
		},
		isValidDrop: function (source) {
			let page = this.getPage();
			var isPageLocked = page && (page.getFreeMovementLocked() || page.getLocked());
			// Dragging class layout on class
			if (source.hasClass('class') && !isPageLocked) {
				return true;
			}
			// Moving candid around
			else if (source.hasClass('frame')) {
				return true;
			}
			// Candid picture dropping on candid or class layout, or blank page which is treated like a candid
			else if (source.hasClass('candidPicture') && !isPageLocked) {
				return true;
			}
			// Allow theme/covers on valid pages
			else if (!isPageLocked && (source.hasClass('themes') || source.hasClass('backgrounds'))) {
				return true;
			}
			// Allow candid layouts on blank pages
			else if (source.hasClass('candid') && !isPageLocked && (!page || page.type != 'classOverflow')) {
				return true;
			}
			// Allow classes to be dragged around
			else if (source.hasClass('classButton') && !source.hasClass('active') && !isPageLocked) {
				return true;
			}
			// Allow misc template types
			else if ((source.hasClass('autograph') || source.hasClass('index') || source.hasClass('adTemplate')) && !isPageLocked) {
				return true;
			}
			// Allow Page Size
			else if (source.hasClass('page-size')) {
				return true;
			}
			// Allow composites
			else if (source.hasClass('composites') || source.hasClass('compositeTemplate')) {
				return true;
			}
			// Allow subject masks
			else if (source.hasClass('subjectMask') && page && page.setStudentMask && !isPageLocked) {
				return true;
			}
			else if(source.hasClass('classOptions') && page && page.mergeLayoutOptions && !isPageLocked) {
				return true;
			}
			else if(source.hasClass('pageOptions') && page && !isPageLocked) {
				return true;
			}
			else if ((source.hasClass('subjectPhoto') || source.hasClass('newText') || source.hasClass('candidPlaceholder') || source.hasClass('dynamicGraphic') || source.hasClass('newShape')) && page && !isPageLocked) {
				return true;
			}
			else if(source.hasClass('newPage')) {
				return true;
			}
			// Block everything else
			else {
				return false;
			}
		},
		isValidLockedDrop: function(source) {
			let page = this.getPage();
			if(!page) {
				return false;
			} else if(page.type != 'insideCover' && page.canThemeWhileLocked && page.canThemeWhileLocked() && source.hasClass('themes')) {
				return true;
			} else {
				return false;
			}
		},
		getNewFrame: function (position, orig, event) {
			var imgRatio = $(orig).width() / $(orig).height();
			// For super wide/tall images the natural width/height of the thumbnail is going to be off but a lot closer than the further rounded size we have in the sidebar div
			if(orig[0].naturalWidth) {
				imgRatio = orig[0].naturalWidth / orig[0].naturalHeight;
			}
			var x, y, width, height;
			if (this.getPage().type == 'class') {
				var closestCell = $(this).find('.flowCell:not(.rowHeader)').getClosestElement(position);

				if(closestCell) {
					position = closestCell.position();

					var cellHeight = closestCell.outerHeight(true);
					var cellWidth = closestCell.outerWidth(true);
					var imgHeight = orig.height();
					var rows = Math.max(1, Math.round(imgHeight / cellHeight));

					height = (rows * cellHeight) / this.ratio;
					width = height * imgRatio;

					var columns = width / cellWidth;
					var diff = Math.ceil(columns) * cellWidth - width;
					position.left += (diff / 2);
				} else {
					width = orig.width() / this.ratio;
					height = orig.height() / this.ratio;
				}
			} else {
				width = orig.width() / this.ratio;
				height = width / imgRatio;

				if(orig[0].sizeIncrement) {
					width *= orig[0].sizeIncrement;
					height *= orig[0].sizeIncrement;
				}
			}

			x = position.left / this.ratio;
			y = position.top / this.ratio;

			var candidPicture = orig.closest('.candidPicture');
			var photoObj = candidPicture.data('photo');
			var frameDefinition = $.extend({
				id: $.getGuid(),
				width: width,
				height: height,
				x: x,
				y: y,
				photo: photoObj.id,
				photo_name: photoObj.upload_file_name,
				photoWidth: photoObj.width,
				photoHeight: photoObj.height,
				photoVersion: photoObj.version_id,
				photoVersions: photoObj.version_ids,
				existingUrl: orig.attr('src'),
				zIndex: (this.getMaxZIndex() + 1)
			}, this.extraContentProperties);
			if(photoObj.displayName) {
				frameDefinition.displayName = photoObj.displayName;
			}
			if(photoObj.useGreenScreenBackground) {
				frameDefinition.chroma_key = photoObj.chroma_key;
				frameDefinition.useGreenScreenBackground = photoObj.useGreenScreenBackground;
			}

			if(event && event.ctrlKey) {
				frameDefinition.behindCells = true;
			}

			return frameDefinition;
		},
		onFlowChange: function (content, helper, forceFreshSlate) {
			var me = this;

			// Make sure to only have on handler queued to run at any given time
			if(this.delayedChange) {
				window.clearTimeout(this.delayedChange);
				// Make sure a onFlowChange after a onFlowStop does not remove this - ie: from resize and recalculating word wrap
				if(this.onFlowChangeForceFreshSlate) {
					forceFreshSlate = this.onFlowChangeForceFreshSlate;
				}
			}
			this.onFlowChangeForceFreshSlate = forceFreshSlate;

			// Use a delay to keep UI smoother
			this.delayedChange = window.setTimeout(function () {
				var start = new Date().getTime();
				let cells = me.getAllCells();
				var invisibleCells = cells.filter('.invisibleCell').removeClass('invisibleCell');
				me.onFlowChangeForceFreshSlate = undefined;

				// In fit calculation we want to remove blocks and recalculate on every move
				var changes;
				if(forceFreshSlate) {
					var flowFrames = me.canvas.getBlockingContent();
					let layout = me.getLayout();
					if(layout && layout.cell && layout.cell.size == 'fit') {
						me.hardRefreshFitLayout(layout, flowFrames);
					} else {
						flowFrames.each(function () {
							if(this.hideOverlap) {
								this.hideOverlap(cells);
							}
						});
					}
					changes = true;
				} else {
					changes = content.hideOverlap(cells, helper);
				}

				if (changes) {
					// We want to stay fairly live reactive, but creating/deleting pages as we move stuff around is distracting and a pain when dealing with multiple users
					me.onFlowPendingChanges = true;
					me.addUsers(null, undefined, {
						allowCreatingPages: false
					});
				} else if (invisibleCells.length) {
					invisibleCells.addClass('invisibleCell');

					// Make sure that we are not overlapping visible cells
					var valid = true;
					var behindFrames = cells.filter('.behindFrame');
					let page = me.getPage();
					var contentOverlapSubjectLabels = !page || page.doesContentOverlapSubjectLabels();
					behindFrames.each(function () {
						$.each($.unique($(this).data('behindFrames') || $()), function () {
							var overlaps = $(this).overlaps(cells, {
								includeBaseLabelHeight: !contentOverlapSubjectLabels,
								ratio: wrapper.ratio
							});
							if (overlaps.not('.invisibleCell, .behindFrame').length) {
								valid = false;
								return false;
							}
						});
					});

					// NOTE: Having this section in each behindFrames check makes it horribly slow on grouped teacher composites
					if(!valid) {
						me.addUsers();
					}
				}

				me.delayedChange = null;
				me.lastFlowChangeTime = (new Date().getTime() - start);
			}, 0);
		},
		onFlowStop: function (content) {
			let layout = this.getLayout();
			if(layout && layout.cell && layout.cell.size == 'fit') {
				this.onFlowChange(content, null, true);
			} else if(this.onFlowPendingChanges) {
				this.onFlowPendingChanges = false;
				this.addUsersWithRollbackPrompt();
			}

			let page = this.getPage();
			$.setSingleTimeout.call(this, 'onFlowStopUpdate', function() {
				if(page && page === this.getPage() && page.refreshLockedTextsOnMove() && !$(content).hasClass('flowLockedText')) {
					this.refreshLockedTexts();
				}
			}, 0);
		},
		onFlowAdd: function(content, options = {}) {
			if(content.hideOverlap(this.getCells()) || options.forceAddUsers) {
				this.addUsersWithRollbackPrompt();
			}

			let page = this.getPage();
			if(page && page.refreshLockedTextsOnMove() && !$(content).hasClass('flowLockedText')) {
				this.refreshLockedTexts();
			}
			content.checkOverflowToLinkedLayouts?.();
		},
		onFlowRemove: function(content) {
			let page = this.getPage();
			if(page && page.refreshLockedTextsOnMove() && !$(content).hasClass('flowLockedText')) {
				this.refreshLockedTexts();
			}

			let layout = this.getLayout();
			if(layout && layout.cell && layout.cell.size == 'fit') {
				content.removeHidden();
				this.onFlowChange(content, null, true);
			} else {
				if(content.removeHidden()) {
					this.addUsers();
				}
			}
		},

		addUsersWithRollbackPrompt: function(options = {}) {
			let prompted = false;
			this.addUsers(null, options.firstLayout, {
				onCreatePage: (!$.userExtras.composerSettings || !$.isTruthy($.userExtras.composerSettings.doNotPromptForOverflow)) ? (options) => {
					this.parent.userPromptedForRollback = true;
					prompted = true;
					let choice = $('<div class="ui checkbox">');
					choice.append('<input type="checkbox">');
					choice.append('<label>Don\'t ask me again</label>');
					choice.checkbox();
					let extraContent = $('<p/>').add(choice);

					// Make sure we only keep the latest rollback prompt in case of calling multiple times from overflow chaining
					if(this.parent.rollbackPrompt && $(this.parent.rollbackPrompt).isAttached()) {
						$(this.parent.rollbackPrompt).remove();
					}

					let buttonClicked = false;
					let modal =$('<div class="ui small modal">')
						.append('<i class="close icon">')
						.append($('<div class="header">').text('Add new page'))
						.append($('<div class="content">').text('This action caused a new overflow page to be created.  Do you want to proceed?').append(extraContent))
						.append('<div class="actions"><div class="ui deny button">' + i18n.t('misc.cancel') + '</div><div class="ui approve primary button">' + i18n.t('misc.ok') + '</div></div>')
						.modal({
							onApprove: () => {
								try {
									if(!buttonClicked) {
										this.keepEventsGroupedLonger();

										options.success();
										if(choice.checkbox('is checked')) {
											if(!$.userExtras.composerSettings) {
												$.userExtras.composerSettings = {};
											}
								
											$.userExtras.composerSettings.doNotPromptForOverflow = true;
											$.ajax({
												url: 'ajax/saveUserExtra.php',
												dataType: 'json',
												data: {
													name: 'composerSettings',
													value: $.userExtras.composerSettings
												},
												type: 'POST'
											});
										}
										buttonClicked = true;
										this.stopGroupedEvents();
									}
								} finally {
									this.parent.userPromptedForRollback = false;
								}
							},
							onDeny: () => {
								try {
									this.keepEventsGroupedLonger();
									if(!buttonClicked) {
										if($.userEvents) {
											$.userEvents.undoLastEvent();
										}
										buttonClicked = true;
										this.stopGroupedEvents();
									}
								} finally {
									this.parent.userPromptedForRollback = false;
								}
							},
							onHide: () => {
								try {
									// IF we click out without explicitly pressing something, treat it as they wanted to keep it
									// Clicking cancel should really be the exception and not the norm
									if(!buttonClicked) {
										options.success();
										buttonClicked = true;
										this.stopGroupedEvents();
									}
								} finally {
									this.parent.userPromptedForRollback = false;
								}
							},
							onHidden: function() {
								$(this).remove();
							}
						}).modal('show');
					
					this.parent.rollbackPrompt = modal[0];
				} : null
			});

			if(options.childLayout) {
				if(prompted) {
					options.childLayout.addUsers(null, false, {
						allowCreatingPages: false
					});
				} else {
					options.childLayout.addUsersWithRollbackPrompt();
				}
			}
		},

		outputProductionPage: function () {
			var me = this;
			let page = this.getPage();
			let rootPage = page;
			if (rootPage.getRootPage) {
				rootPage = rootPage.getRootPage();
			}
			var content = $(this);

			if ($.getGETParams().debugPrinceStart) {
				let page = parseInt($.getGETParams().debugPrinceStart) + $.PAGE_OFFSET;
				if (isNaN(page) || page == this.getPage().getPageNumber()) {
					$('body > .pusher, #header, .modals').remove();
					$('body').css('overflow', 'visible');
					// eslint-disable-next-line
					asdf;
				}
			}

			$(this).find('img').FlushImageLoadQueue();

			// Just absolutely position all cells since Prince can't seem to do it right
			var canvas = content.find('.flowPageCanvas').eq(0);
			var wholePageRect = content[0].getBoundingClientRect();
			var containerRect = content.find('.flowPageContainer')[0].getBoundingClientRect();
			content.find('.flowCell:not(.hiddenCell, .invisibleCell)').filter(function () {
				return this.user != null || $(this).hasClass('rowHeader');
			}).each(function () {
				var cellRect = this.getBoundingClientRect();
				var clone = $(this).clone();
				clone.css({
					position: 'absolute',
					left: (cellRect.left - containerRect.left) + 'px',
					top: (cellRect.top - containerRect.top) + 'px',
					margin: ''
				}).appendTo(canvas);

				if($(this).hasClass('rowHeader')) {
					clone.find('.flowSVGContent').each(function(i) {
						this.svgEditor = $(this).find('svg')[0];
						this.ratio = me.ratio;
					});
				} else {
					// Want to do in reverse order so positioning is still correct
					var originalSVGElements = $(this).find('.flowSVGContent').get().reverse();
					$(clone.find('.flowSVGContent').get().reverse()).each(function(i) {
						// Honestly having trouble tracking what this code even does
						// This whole chunk of code breaks rotated text - might have to actually fix this but for now this works
						if(this.style.transform) {
							return;
						}
						
						var originalSVGElement = originalSVGElements[i];
						var svgRect = originalSVGElement.getBoundingClientRect();

						var left = svgRect.left - cellRect.left;
						var top = svgRect.top - cellRect.top;
						var width = $(this).getFloatStyle('width');
						if(!width) {
							width = svgRect.width;
						}
						if($(this).hasClass('wrapLabel')) {
							left -= 0.5;
							width += 1;
						}

						$(this).css({
							position: 'absolute',
							left: left + 'px',
							top: top + 'px',
							width: width + 'px',
							marginLeft: 0
						});
						this.svgEditor = $(this).find('svg')[0];
						this.ratio = originalSVGElement.ratio;
					});

					clone[0].maskedBorder = true;
					clone[0].inchWidth = this.definition.width;
					clone[0].inchHeight = this.definition.height;
					clone[0].definition = this.definition;
					clone[0].user = this.user;
				}
			});

			var titles = content.find('.flowTitle');
			titles.each(function () {
				var titleRect = this.getBoundingClientRect();
				var padding = $(this).getFloatStyle('paddingTop');
				var clone = $(this).clone()[0];
				$(clone).css({
					position: 'absolute',
					top: (titleRect.top - containerRect.top + padding) + 'px',
					left: (titleRect.left - containerRect.left - 0.5) + 'px',
					width: (titleRect.width + 1) + 'px',
					padding: ''
				}).appendTo(canvas);

				clone.currentTitle = this.currentTitle;
				clone.wrapper = me;
				clone.svgEditor = $(clone).find('svg')[0];
				clone.ratio = this.ratio;
				clone.instance = this.instance;
			});

			content.find('.flowRow').remove();
			titles.remove();

			var pageMargins = content.find('.flowPageMargins');
			var verticalMargin = parseFloat(pageMargins.css('marginTop').replace('px', ''));
			pageMargins.css({
				width: '100%',
				height: '100%',
				margin: 0
			});

			// Apply the top margin on the canvas
			content.find('.flowPageCanvas').css({
				marginTop: verticalMargin + 'px'
			});

			content.find('.nameLabel, .flowTitle, .flowPageNumber, .flowFreeText').each(function () {
				$(this).css('fontFamily', $(this).css('fontFamily'));
				var color = $(this).css('color');
				if (color == '' || color == 'rgb(0, 0, 0)' || color == 'rgb(0, 0, 0, 0.8)' || color == 'rgb(0, 0, 0, 1)') {
					$(this).addClass('productionTrueBlack');
				}
			});
			content.find('.flowPageNumber').css('position', 'absolute');

			// Replace effects
			var renderedEffects = 0;
			content.find('.frame, .flowCell:not(.rowHeader)').each(function () {
				var innerWrapper = $(this).find('.imageInnerStyleWrapper')[0];
				var middleWrapper = $(this).find('.imageMiddleStyleWrapper')[0];
				var outerWrapper = $(this).find('.imageOuterStyleWrapper')[0];

				var images = $(this).find('img');
				for(let i = 0; i < images.length; i++) {
					var img = images[i];
					if(!img.src && !$(this).hasClass('flowLayoutShape')) {
						continue;
					}

					var url = img.src;
					var effects = [];
					var definition;
					if (this.definition) {
						definition = this.definition;
					} else {
						definition = {};
					}

					var cropEffect = null;
					if(definition.crop && $.getObjectCount(definition.crop) > 1) {
						cropEffect = 'crop=' + [definition.crop.width || 1, definition.crop.height || 1, Math.min(definition.crop.left || 0, 0), Math.min(definition.crop.top || 0, 0)].join(',');
					} else if(this.user && this.user.yearbookPhoto && this.user.yearbookPhoto.yearbookCrop && !$(img).hasClass('greenScreenBackground')) {
						let crop = this.user.yearbookPhoto.yearbookCrop;

						var cropWidth = 1 / crop.width;
						var cropHeight = 1 / crop.height;
						cropEffect = 'crop=' + [cropWidth, cropHeight, -(crop.x * cropWidth), -(crop.y * cropHeight)].join(',');
					} else if(definition.field || definition.photoFieldMap) {
						// Get crop from dynamic graphic
						var defaultCrop = {
							left: 0,
							top: 0,
							width: 1,
							height: 1
						};
						let crop = $.extend(true, {}, defaultCrop);
						['left', 'top', 'width', 'height'].forEach(function(prop) {
							var cropProp = img.style[prop];

							if(cropProp) {
								crop[prop] = parseInt(cropProp.replace('%', '')) / 100;
							}
						});
						
						if(!$(crop).objectEquals(defaultCrop)) {
							cropEffect = 'crop=' + [crop.width, crop.height, crop.left, crop.top].join(',');
						}
					}

					// Fallback handling of cases like crop: { percentage: true }
					if(cropEffect && (cropEffect == 'crop=,,,' || cropEffect.indexOf(',,') != -1 || cropEffect.indexOf('=,') != -1)) {
						$.fireErrorReport(null, 'Crop error in outputProductionPage', 'Invalid crop effect', {
							cropEffect: cropEffect,
							snapshot: me.getSnapshot()
						});
						cropEffect = null;
					}

					var hasClipPath = innerWrapper && (innerWrapper.style['clip-path'] || innerWrapper.style['-webkit-clip-path']);
					var hasMask = innerWrapper && (innerWrapper.style['mask-image'] || innerWrapper.style['-webkit-mask-box-image'] || innerWrapper.style['-webkit-mask-image']);

					// Get the svg we are trying to apply
					var svgStyle = '';
					var svg = '';
					if(hasClipPath) {
						var clipPathStyleName = 'clip-path';
						var clipPathUrl = innerWrapper.style['clip-path'];
						if(!clipPathUrl) {
							clipPathUrl = innerWrapper.style['-webkit-clip-path'];
							clipPathStyleName = '-webkit-clip-path';
						}

						var clipPath = clipPathUrl.replace('url(', '').replace(')', '').replace(/"/ig, '');

						// Prince doesn't support clip-path + crop
						if(cropEffect) {
							var clipPathNode = $(clipPath).children()[0];
							if (clipPathNode) {
								switch (clipPathNode.nodeName) {
									case 'rect':
										effects.push('mask=rect,' + $(clipPathNode).attr('rx') + ',' + $(clipPathNode).attr('ry'));
										break;
									case 'circle':
										effects.push('mask=circle,' + $(clipPathNode).attr('r') + ',' + $(clipPathNode).attr('cx') + ',' + $(clipPathNode).attr('cy'));
										break;
									case 'polygon':
										var points = $(clipPathNode).attr('points').replace(/, +/ig, ',').replace(/ +/ig, ',');
										effects.push('mask=poly,' + points);
										break;
								}
							}

							effects.push(cropEffect);
						} else {
							var clipPathObject = $(clipPath);
							if(clipPathObject.length) {
								svg = clipPathObject[0].outerHTML;
								// IE/PhantomJS don't support SVG.outerHTML
								if(!svg) {
									svg = $('<div>').append(clipPathObject.clone())[0].innerHTML;
								}
								svgStyle = clipPathStyleName + ': ' + clipPathUrl.replace(/"/ig, "'") + ';';
							}
						}
					} else if(hasMask) {
						var alphaMaskUrl;
						if (innerWrapper.style['-webkit-mask-box-image']) {
							alphaMaskUrl = innerWrapper.style['-webkit-mask-box-image'];
						} if (innerWrapper.style['-webkit-mask-image']) {
							alphaMaskUrl = innerWrapper.style['-webkit-mask-image'];
						} else if (innerWrapper.style['mask-image']) {
							alphaMaskUrl = innerWrapper.style['mask-image'];
						}

						if(alphaMaskUrl.indexOf('url("') === 0) {
							alphaMaskUrl = alphaMaskUrl.replace('url("', '').replace('")', '');
						} else if(alphaMaskUrl.indexOf('url(') === 0) {
							alphaMaskUrl = alphaMaskUrl.replace('url(', '').replace(')', '');
						}

						if (alphaMaskUrl.indexOf('http') == -1) {
							alphaMaskUrl = window.location.origin + '/' + alphaMaskUrl;
						}
						effects.push('mask=alpha,' + encodeURIComponent(alphaMaskUrl));

						if(cropEffect) {
							effects.push(cropEffect);
						}
					}

					var startWrapperTransform = this.style.transform || this.style['-webkit-transform'];
					this.style.transform = this.style['-webkit-transform'] = '';
					var startOuterTransform = outerWrapper.style.transform || outerWrapper.style['-webkit-transform'];
					outerWrapper.style.transform = outerWrapper.style['-webkit-transform'] = '';

					var outerRect = outerWrapper.getBoundingClientRect();
					var innerRect = innerWrapper.getBoundingClientRect();
					var imgRect = img.getBoundingClientRect();

					if (startWrapperTransform) {
						this.style.transform = this.style['-webkit-transform'] = startWrapperTransform;
					}
					if (startOuterTransform) {
						outerWrapper.style.transform = outerWrapper.style['-webkit-transform'] = startOuterTransform;
					}

					// Take out calc since Prince doesn't support yet
					$(middleWrapper).convertCalcToPx('width', outerRect);
					$(middleWrapper).convertCalcToPx('height', outerRect);

					var outerWidth = innerRect.width;
					var outerHeight = innerRect.height;
					var innerWidth = imgRect.width;
					var innerHeight = imgRect.height;
					var x = imgRect.left - innerRect.left;
					var y = imgRect.top - innerRect.top;

					var inchWidth = innerWidth / me.ratio;
					var inchHeight = innerHeight / me.ratio;

					var imageAttr = '';
					if(effects.length) {
						imageAttr += 'effects="' + effects.join(';;') + '"';

						// Remove crop from svg since effects already include
						innerWidth = outerWidth;
						innerHeight = outerHeight;
						x = 0;
						y = 0;
					}

					var requiresChrome = false;
					var imgStyle = '';
					var innerWrapperFilter = innerWrapper.style.filter || innerWrapper.style['-webkit-filter'];
					// Note: In browser we blur the stroke if we don't have a mask defined, so want to only stop bluring the stroke when we have a mask
					// Note: Prince does not render a single filter style on img element, so we are careful to only move effect when absolutely necessary with masks
					if(hasMask && this.isShape && this.isShape()) {
						requiresChrome = true;
					} else if((hasClipPath || hasMask) && innerWrapperFilter && innerWrapperFilter.indexOf('blur') != -1) {
						imgStyle += 'filter: ' + innerWrapperFilter + ';';
						innerWrapper.style.filter = innerWrapper.style['-webkit-filter'] = '';
					}

					var svgImage = '';
					if(url) {
						svgImage = '<image xlink:href="' + url + '" x="' + x + '" y="' + y + '" width="' + innerWidth + '" height="' + innerHeight + '" inch_width="' + inchWidth + '" inch_height="' + inchHeight + '" ' + imageAttr + ' style="' + imgStyle + '" preserveAspectRatio="none" />';
					} else if($(innerWrapper).css('background-color')) {
						svgImage = '<rect x="0" y="0" width="' + outerWidth + '" height="' + outerHeight + '"' + ' fill="' + $(innerWrapper).css('background-color') + '" style="' + imgStyle + '" />'
						$(innerWrapper).css('background-color', '');
					}

					// Replace this img with a <svg><image><def></svg>
					var image = $('<svg width="' + outerWidth + '" height="' + outerHeight + '" class="imageSVGWrapper"><g style="' + svgStyle + '">' + svgImage + svg + '</g></svg>');
					if(requiresChrome) {
						image.attr('requires_chrome', 'true')
							.attr('inch_width', outerWidth / this.ratio)
							.attr('inch_height', outerHeight / this.ratio);

						image.attr('style', $(innerWrapper).attr('style'));
					}
					$(img).replaceWith(image);
				}

				$(innerWrapper).add(middleWrapper).css({
					'clip-path': '',
					'-webkit-clip-path': '',
					'mask-image': '',
					'mask-size': '',
					'-webkit-mask-image': '',
					'-webkit-mask-box-image': ''
				});
			});

			// Make sure each image points to an absolute reference
			content.find('img').each(function () {
				// Jquery returns exactly what I put in
				var src = $(this).attr('src');
				if (src && src.indexOf('http') == -1) {
					// obj.src returns the absolute path
					$(this).attr('src', this.src);
				}
			});

			let pageSet = this.getPageSet();
			if(this.includeWhiteSpace && this.inchWhiteSpace) {
				var background = $(this).find('.flowPageBackground');
				if(background.length && background.find('img').attr('src')) {
					var whiteSpace = this.inchWhiteSpace;
					if(whiteSpace.left || whiteSpace.right) {
						var leftWhiteSpace = whiteSpace.left * this.ratio;
						var rightWhiteSpace = whiteSpace.right * this.ratio;

						background.css({
							marginLeft: leftWhiteSpace + 'px',
							marginRight: rightWhiteSpace + 'px',
							width: ($(this).getFloatStyle('width') - leftWhiteSpace - rightWhiteSpace) + 'px'
						});
					}
					if(whiteSpace.top || whiteSpace.bottom) {
						var topWhiteSpace = whiteSpace.top * this.ratio;
						var bottomWhiteSpace = whiteSpace.bottom * this.ratio;

						background.css({
							marginTop: topWhiteSpace + 'px',
							marginBottom: bottomWhiteSpace + 'px',
							height: ($(this).getFloatStyle('height') - topWhiteSpace - bottomWhiteSpace) + 'px'
						});
					}
				}
			}

			content.find('.flowCell img, .frame img, .flowPageBackground img').each(function () {
				// Add attributes for max sizes
				var rect = this.getBoundingClientRect();
				if (rect.width && rect.height) {
					var inchWidth = rect.width / me.ratio;
					var inchHeight = rect.height / me.ratio;

					$(this).attr('inch_width', inchWidth).attr('inch_height', inchHeight);
				}
			});

			if (this.pageNumberDiv) {
				var position = pageSet.getPageNumberPosition();
				if (position.indexOf('bottom') != -1) {
					$(this.pageNumberDiv).css({
						top: '',
						bottom: '0'
					});
				}
			}

			// Add work around for Pacifico rendering last character as cursive but it isn't in the browser
			var searchAndDestroy = '.flowTitle.flowSVGContent, .flowFreeText.flowSVGContent, .flowPageNumber.flowSVGContent, .rowHeader .flowSVGContent.nameLabel';
			if(this.definition && this.definition.cell && ['inside', 'outside'].indexOf(this.definition.cell.name) !== -1) {
				searchAndDestroy += ', .flowCell .flowSVGContent.nameLabel';
			}
			content.find(searchAndDestroy).each(function () {
				var svgElem = this;
				if(!this.svgEditor) {
					return;
				}

				var applyMovementHack = false;
				var maxFontSize = 20;
				let hasIncreasedMaxFontSize = false;
				let requiresChrome = false;
				$(this).find('tspan').filter(function () {
					if (this.style.fontFamily && (
							this.style.fontFamily.indexOf('pacifico') !== -1 ||
							this.style.fontFamily.indexOf('cabin sketch') !== -1 ||
							this.style.fontFamily.indexOf('notable') !== -1 ||
							this.style.fontFamily.indexOf('roboto slab') !== -1) ||
							this.style.fontFamily.indexOf('coda caption') !== -1 ||
							this.style.fontFamily.indexOf('walter turncoat') !== -1) {
						applyMovementHack = true;
						maxFontSize = Math.max($(this).getFloatStyle('font-size'), maxFontSize);

						if(this.style.fontFamily.indexOf('coda caption') !== -1) {
							hasIncreasedMaxFontSize = true;
						}
						return false;
					}
					// All underlines are rendered thinner in Prince than Chrome, but some are particularly bad
					else if(this.style.fontFamily && (
						this.style.fontFamily.includes('croissant one')
					) && this.style.textDecoration === 'underline') {
						requiresChrome = true;
					}
				});
				if(hasIncreasedMaxFontSize) {
					maxFontSize *= 1.5;
				}

				// Prince does not render curved paths with stroke/drop shadow correctly
				const hasCurveWithClones = this.instance?.curve && $(this).find('text.clone').length > 0;
				if(hasCurveWithClones || requiresChrome) {
					this.requiresChromeRender = true;
				} else if(applyMovementHack) {
					// TODO: Would be better to actually fix the rotation calculation for this, but I couldn't get it down
					// Moving the position with curved text appears broken
					if(this.getRotation && this.getRotation(true) || this.instance?.curve) {
						this.requiresChromeRender = true;
						return;
					}

					var svgWidth = $(this.svgEditor).getFloatAttribute('width');
					this.svgEditor.setAttribute('width', svgWidth + maxFontSize);
					
					let moveElementLeftBy = maxFontSize / 4;
					let moveTextXBy = moveElementLeftBy;
					if($(this).hasClass('hasRightAlignedText') && $(this).hasClass('flowFreeText')) {
						// Right aligned text should have a manual size which will break if we try to move it over
						// If we don't have a manual size, we need to move by the full increase in svgEditor width in order to keep the correct position
						if($(this).getFloatStyle('width') > 0) {
							moveElementLeftBy = moveTextXBy = 0;
						} else {
							moveElementLeftBy = moveTextXBy = maxFontSize;
							moveTextXBy = 0;
						}
					}
					// Title divs have implicit centering we need to maintain
					if($(svgElem).hasClass('flowPageNumber') && svgElem.style.right != '') {
						var right = $(svgElem).getFloatStyle('right');
						var newRight = right - maxFontSize;
						$(svgElem).css({
							right: newRight
						});
					} else {
						var left = $(this).getFloatStyle('left');
						var newLeft = left - moveElementLeftBy;
						$(this).css({
							left: newLeft
						});
					}

					var isRowHeader = $(this).parent().hasClass('rowHeader');
					if($(this).hasClass('nameLabel')) {
						if(isRowHeader) {
							var rowHeader = $(this).parent();
							let maxWidth = rowHeader.getFloatStyle('max-width');
							if(maxWidth) {
								rowHeader.css({
									'max-width': maxWidth + maxFontSize
								});
							}
						}

						let width = $(this).getFloatStyle('width');
						if(width) {
							$(this).css({
								'width': width + maxFontSize
							});

							let outerWrapperWidth = $(this).find('.flowSVGOuterWrapper').getFloatStyle('width');
							if(outerWrapperWidth) {
								$(this).find('.flowSVGOuterWrapper').css({
									'width': outerWrapperWidth + maxFontSize
								});
							}
						}
					} else if($(this).hasClass('flowTitle') && $(this).getFloatStyle('width')) {
						let maxWidth = $(this).getFloatStyle('width');
						$(this).css({
							'width': maxWidth + maxFontSize,
							'max-width': ''
						});
					}

					var me = this;
					$(this.svgEditor).find('text, rect').each(function() {
						if(this.getAttribute('text-anchor') === 'end' && $(me).hasClass('flowFreeText') && $(me).hasClass('hasRightAlignedText')) {
							this.setAttribute('x', parseFloat(this.getAttribute('x')) + maxFontSize + moveTextXBy);
						} else {
							this.setAttribute('x', parseFloat(this.getAttribute('x')) + moveTextXBy);
						}
					});

					// Side labels get messed up if the height is changed!
					if(!$(this).hasClass('nameLabel')) {
						var svgHeight = $(this.svgEditor).getFloatAttribute('height');
						this.svgEditor.setAttribute('height', svgHeight + maxFontSize / 10);
					}
				}
			});

			// Prince fails on certain emoji and we will not upgrade Prince due to what they want to charge us for an upgrade
			if(!$.FlowLayout.badEmojiRegex) {
				$.FlowLayout.badEmojiRegex = /(\uFE0F\u20E3|🫶\uD83C|🏋\uD83C|🏋‍|🏌|🏳‍|👁‍🗨|🕴\uD83C|🕵|🖐\uD83C|🤌\uD83C|🥷\uD83C|🫃\uD83C|🫄\uD83C|🫅\uD83C|🫰\uD83C|🫱\uD83C|🫲\uD83C|🫳\uD83C|🫴\uD83C|🫵\uD83C|🫶\uD83C|🫷|🫸|🛜|🩵|🩶|🩷|🪇|🪈|🪭|🪮|🪯|🪻|🪼|🪽|🪿|🫎|🫏|🫚|🫛|🫨)/g;
			}
			content.find(searchAndDestroy + ', .flowCell .flowSVGContent').each(function () {
				var hasBadFont = false;
				$(this.svgEditor).find('tspan').each(function() {
					// NOTE: If we add more fonts, need to add to PrinceErrorsModule::checkForEmbeddedFonts
					// Boogaloo doesn't render non-breaking spaces the same in Prince
					// Fontdiner Swanky renders wider in Prince (appears to be character spacing issue) - example "Christopher Baldwin" goes past edge of cell label in Prince but not in browser - tried Prince 14.2 and still broken
					if(this.style.fontFamily.includes('candal') || this.style.fontFamily.includes('sawarabi mincho') || this.style.fontFamily.includes('boogaloo') || this.style.fontFamily.includes('fontdiner swanky')
						|| this.style.fontFamily.includes('short stack')) {
						hasBadFont = true;
					} else if(this.textContent.match($.FlowLayout.badEmojiRegex)) {
						hasBadFont = true;
					}
				});

				// Prince does not render underline across multiple font families correctly
				if(!hasBadFont && this.instance?.lines && $.isPrinceRenderer) {
					let lines = this.instance.lines;
					if($.isPlainObject(lines)) {
						lines = [lines];
					} else if(typeof lines === 'string') {
						lines = [
							{
								text: lines
							}
						];
					}

					for(let i = 0; i < lines.length; i++) {
						let line = lines[i];
						let parts = line.parts ?? [line];

						let requireSingleFontFamily = false;
						parts.forEach(part => {
							if(part['text-decoration'] === 'underline') {
								requireSingleFontFamily = true;
							}
						});

						if(requireSingleFontFamily) {
							const fonts = parts.reduce((fonts, part) => {
								let partFont = part.fontFamily ?? 'open sans';
								if(!fonts.includes(partFont)) {
									fonts.push(partFont);
								}

								return fonts;
							}, []);

							if(fonts.length > 1) {
								hasBadFont = true;
							}
						}
					}
				}

				if(hasBadFont || this.requiresChromeRender) {
					if(!this.ratio) {
						console.error('No ratio for ', this);
					}

					// Add padding so text can't get cut off during render
					var extraPadding = 4;
					// Text with borders will display wrong with this
					if($(this).find('.flowSVGOuterWrapper')[0] && $(this).find('.flowSVGOuterWrapper')[0].style.border) {
						extraPadding = 0;
					}
					// Implicitly centered subject labels are off by 2px with this.  Not obvious on large cells, but in hundreds of subjects on a 8 x 10 composite it is pretty bad
					else if($(this).hasClass('nameLabel') && this.parentNode && $(this.parentNode).hasClass('flowCell') && this.parentNode.definition && this.parentNode.definition.nameAlign === 'center') {
						extraPadding = 0;
					}
					var width = parseFloat(this.svgEditor.getAttribute('width')) + extraPadding;
					var height = parseFloat(this.svgEditor.getAttribute('height')) + extraPadding;
					// Rotated images can be outside of strict bounds since we are talking the pre-rotated width/height
					if(!this.getRotation || !this.getRotation(true)) {
						width = Math.min(wholePageRect.width, width);
						height = Math.min(wholePageRect.height, height);
					}
					
					$(this.svgEditor)
						.attr('requires_chrome', true)
						.attr('width', width)
						.attr('height', height)
						.attr('inch_width', width / this.ratio)
						.attr('inch_height', height / this.ratio);
				} else if($(this.svgEditor).find('text[textLength]').length && $.isPrinceRenderer) {
					$(this.svgEditor).find('tspan').removeAttr('textLength');
				}
			});

			content.find('.flowFreeText').each(function() {
				if(!this.isContentHidden || !this.parentNode) {
					return;
				}

				var myRect = this.getBoundingClientRect();
				var parentRect = this.getParentRect();
				if(this.isContentHidden(myRect, parentRect) || (this.isTextBoxHidden && this.isTextBoxHidden(myRect, parentRect))) {
					$(this).addClass('outOfBoundsContent');
				}

				let text = this.getInstanceText();
				// Loop through to see if every piece of text is an emoji - if it is, we will end at the end of the string
				let startIndex = 0;
				while(startIndex < text.length && $.FlowLayoutSVGUtils.isMultiByteCharacter(text, startIndex)) {
					startIndex += Math.max(1, $.FlowLayoutSVGUtils.getMultiByteCharacterLength(text, startIndex));
				}
				if(text.length > 0 && text.length === startIndex) {
					let otherFontFamily = null;
					this.iterateInstanceParts(function(part) {
						if(part && part.fontFamily && part.fontFamily !== 'open sans') {
							otherFontFamily = part.fontFamily;
						}
					});

					if(otherFontFamily) {
						$(this).addClass('ignoreMissingFont').attr('ignoreMissingFont', otherFontFamily);
					}
				}
			});

			// Change the container margin to be padding on yearbookPage
			// Gets around a strange layout issue
			var startWidth = $(this).getFloatStyle('width');
			var startHeight = $(this).getFloatStyle('height');
			var css = {
				width: startWidth,
				height: startHeight
			};
			var sides = ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'];
			for(let i = 0; i < sides.length; i++) {
				var side = sides[i];

				var value = $(this.container).getFloatStyle(side);
				css[side.replace('margin', 'padding')] = value;
				switch(side) {
					case 'marginLeft':case 'marginRight':
						css.width -= value;
						break;
					case 'marginTop':case'marginBottom':
						css.height -= value;
						break;
				}
			}

			for(let i in css) {
				css[i] += 'px';
			}

			$(this.container).css('margin', 0);
			$(this).removeClass('pushable').css(css);
			var parent = $(this).parent();
			parent.css({
				width: startWidth,
				height: startHeight
			});
			$(this).addClass('hideOverflow');

			if ($.getGETParams().debugPrince) {
				let page = parseInt($.getGETParams().debugPrince) + $.PAGE_OFFSET;
				if (isNaN(page) || page == this.getPage().getPageNumber()) {
					$('body > .pusher, #header, .modals').remove();
					$('body, html').css('overflow', 'visible');

					$('image').each(function () {
						if ($(this).attr('effects')) {
							// Preview quality
							var url = window.location.origin + '/ajax/getPhoto.php?effects=' + $(this).attr('effects') + '&url=' + encodeURIComponent($(this).attr('xlink:href'));
							// Production quality
							if($.getGETParams().debugPrinceProductionQuality) {
								url = window.location.origin + '/ajax/getPhoto.php?effects=' + $(this).attr('effects') + '&id=' + this.parentNode.parentNode.parentNode.parentNode.definition.photo;
							}
							$(this).attr('xlink:href', url);
						}
					});
					// eslint-disable-next-line
					asdf;
				}
			}

			parent.attr('renderedEffects', renderedEffects);
			return parent;
		},
		setEditable: function (editable, editableModifier, pageChanging) {
			var me = this;
			if (!$(this).hasClass('editablePage') && (editable && editableModifier !== false)) {
				if ($(this.droppableTarget).data('ui-droppable')) {
					$(this.droppableTarget).droppable('destroy');
				}
				this.container.setEditable(true);
				this.canvas.setEditable(true);
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setEditable(true);
				});
				$(this.droppableTarget).droppable({
					drop: function (event, ui) {
						$(me.parent.pages).removeClass('dragging-onto dragging-spread');
						if (!me.isValidDrop(ui.draggable)) {
							return;
						}

						var oldPage = me.getPage();
						if (ui.draggable.hasClass('class')) {
							let definition = ui.draggable.data('definition');
							if(oldPage.getClass) {
								var onComplete = ui.draggable.data('onComplete');
								me.startGroupedEvents();

								me.loadLayout(definition, onComplete, {
									triggerOverflowPrompt: true
								});
							} else {
								me.startGroupedEvents();

								let page = new $.YearbookClassPage(null);
								me.addNewPage(page, function () {
									var newPage = this.getPage();
									newPage.setTitle('Class Placeholder');

									var themeStyles = me.getThemeTextStyles('Panel', 'subjectLabel');
									if(themeStyles) {
										$.extend(newPage.studentLabelCSS.css, themeStyles);
									}

									this.loadLayout(definition);
								});
							}
						} else if(ui.draggable.hasClass('classOptions')) {
							let rootPage = oldPage;
							if(rootPage.getRootPage) {
								rootPage = rootPage.getRootPage();
							}

							let definition = ui.draggable.data('definition');
							if(rootPage.getClass) {
								if(typeof definition === 'function') {
									definition(rootPage, (layout) => {
										me.mergeClassLayoutOptions(rootPage, layout);
									});
								} else {
									me.mergeClassLayoutOptions(rootPage, $.extend(true, {}, definition));
								}
							}
						} else if(ui.draggable.hasClass('pageOptions')) {
							let rootPage = oldPage;
							if(rootPage.getRootPage && !ui.draggable.hasClass('allowOnOverflowPage')) {
								rootPage = rootPage.getRootPage();
							}

							let definition = ui.draggable.data('definition');
							rootPage.mergePageOptions(definition);
							me.loadLayout();
						} else if (ui.draggable.hasClass('composites')) {
							let layout = $.extend(true, {}, ui.draggable.data('definition'));
							me.loadLayout(layout);
						} else if(ui.draggable.hasClass('compositeTemplate')) {
							let layout = $.extend(true, {}, ui.draggable.data('definition'));
							me.loadLayout(layout);
						} else if (ui.draggable.hasClass('candid')) {
							let definition = ui.draggable.data('definition');

							var startingPage = me.getPage();
							var pageSet = me.getPageSet();
							var page;
							if(definition && definition.type && pageSet && pageSet.db) {
								page = pageSet.db.loadSinglePage({
									type: definition.type,
									id: $.getUniqueId()
								});
							} else if($.getGETParams().adId) {
								page = new $.AdPage();
							} else if($.getGETParams().personalizationId) {
								page = new $.PersonalizedPage();
							} else if($.getGETParams().layoutId) {
								page = new $.LayoutPage({
									layout: {}
								});
							} else {
								page = new $.YearbookCandidPage();
							}

							var startLayoutProperties = page.getExtraProperty('layoutProperties');
							var layoutProperties = ui.draggable.data('layoutProperties');
							if(layoutProperties) {
								page.setExtraProperty('layoutProperties', layoutProperties, {
									save: false
								});
							}
							var matchExtras = ui.draggable.data('matchExtras');

							me.addNewPage(page, function(params) {
								if(!params) {
									params = {};
								}

								// Make sure to reset collage state
								var myPage = this.getPage();
								if(myPage && params.added !== false && myPage.getExtraProperty('collage')) {
									myPage.extraPropertyChange('collage', false);
								}

								let definition = ui.draggable.data('definition');
								if(typeof definition == 'function') {
									let layout = this;
									layout.startGroupedEvents();

									var succeeded = false;
									definition.call(this, function (definition, extras, params) {
										layout.keepEventsGroupedLonger();
										let page = layout.getPage();
										var loadingLayout = false;
										if(params && params.merge) {
											page.mergeWithLayout(definition, {
												groupContent: params.groupContent
											});
											layout.refreshPage({
												keepGroupedEvents: false
											});
										} else {
											layout.loadLayout(definition, () => {
												if(params && params.onLoadLayout) {
													params.onLoadLayout(layout, page);
												}
											});
											loadingLayout = true;
										}

										if(extras) {
											for(var extra in extras) {
												page.extraPropertyChange(extra, extras[extra]);
											}
										}

										if(layoutProperties) {
											// Make sure we are saving from what it really was to what it is now
											if(page.extras && page !== startingPage) {
												page.extras.layoutProperties = startLayoutProperties;
											}
											page.setExtraProperty('layoutProperties', layoutProperties, {
												stripDuplicates: false
											});
										}

										// Calling loadLayout will stop grouped events post load, but we want to capture any changes from loadLayout in this group
										if(!loadingLayout) {
											layout.stopGroupedEvents();
										}
										succeeded = true;
									}, function () {
										// Ex: modal which cancels in onHide, don't want to trigger a success then a cancel
										if(succeeded) {
											return;
										}
										page = layout.getPage();
										if (oldPage != page) {
											var pageSet = layout.getPageSet();
											if(params.added) {
												pageSet.removePage(page);
											} else {
												pageSet.replacePage(page, oldPage);
											}
											layout.parent.updatePagesAfterChange();
										}

										layout.stopGroupedEvents();
									}, {
										oldPage: oldPage,
										newPage: page
									});
								} else {
									definition = $.extend(true, {}, definition);
									if($.isArray(definition.texts)) {
										var texts = {};
										for (var j = 0; j < definition.texts.length; j++) {
											let text = definition.texts[j];
											text.id = $.getGuid();
											texts[text.id] = text;
										}
										definition.texts = texts;
									}

									if (definition.texts) {
										for (var id in definition.texts) {
											let text = definition.texts[id];
											if(text.text) {
												text.schema = $.FlowLayoutSVG.MAX_SCHEMA;
											}
										}
									}

									definition = myPage.getScaledLayout(definition);
									oldPage.populatePlaceholdersFromPage(definition);

									if(params.merge) {
										myPage.mergeWithLayout(definition);
										this.refreshPage();
									} else {
										this.loadLayout(definition);
									}

									if(layoutProperties) {
										if(myPage.extras && myPage !== startingPage) {
											myPage.extras.layoutProperties = startLayoutProperties;
										}
										myPage.setExtraProperty('layoutProperties', layoutProperties, {
											stripDuplicates: false
										});
									}
								}
							}, {
								matchExtras: matchExtras
							});
						} else if (ui.draggable.hasClass('backgrounds')) {
							var parentTheme = me.getPageSet().getTheme();
							var parentName = (parentTheme && parentTheme.name) ? parentTheme.name : parentTheme;

							let page = me.getPage();
							let theme = $.extend(true, {}, ui.draggable[0].theme);
							var name = theme.name ? theme.name : theme;

							let backgroundSettingKey = me.getBackgroundSettingKey();
							var backgroundSettings = page.getExtraProperty(backgroundSettingKey);
							if (backgroundSettings) {
								page.extraPropertyChange(backgroundSettingKey, null);
							}

							// If it is the same, reset value to be
							if (name == parentName) {
								me.setTheme(parentTheme);
							} else {
								let backgroundSize = me.getBackgroundInchSize();
								if(theme.Background && me.isThemePartTwoPageSpread(theme.Background) && (me.nextLayout || me.previousLayout)) {
									let leftLayout, rightLayout;
									let leftPage, rightPage;
									if(me.nextLayout) {
										leftLayout = me;
										rightLayout = me.nextLayout;

										leftPage = me.getPage();
										rightPage = me.nextLayout.getPage();

										if(!rightPage && leftPage.pageLabel && leftPage.pageLabel.sidebar) {
											let editSpreadOption = leftPage.pageLabel.sidebar.find(option => option.name === 'Edit Spread');
											if(editSpreadOption) {
												me.parent.wrapPages = !$.flowLayout.wrapPages;
												me.parent.updatePagesAfterChangeImmediately(true);

												rightPage = rightLayout.getPage();
											}
										}
									} else if(me.previousLayout) {
										leftLayout = me.previousLayout;
										rightLayout = me;

										leftPage = me.previousLayout.getPage();
										rightPage = me.getPage();
										if(!leftPage && rightPage.pageLabel && rightPage.pageLabel.sidebar) {
											let editSpreadOption = rightPage.pageLabel.sidebar.find(option => option.name === 'Edit Spread');
											if(editSpreadOption) {
												me.parent.wrapPages = !$.flowLayout.wrapPages;
												me.parent.updatePagesAfterChangeImmediately(true);

												leftPage = leftLayout.getPage();
											}
										}
									}

									let photoAspectRatio = theme.Background.photoWidth / theme.Background.photoHeight;
									let layoutAspectRatio = backgroundSize.width / backgroundSize.height;
									let cropWidth = 2;
									let cropHeight = 1;
									let top = 0;
									let leftDiff = 0;
									let aspectRatioDiff = photoAspectRatio / layoutAspectRatio;
									if(aspectRatioDiff > 2) {
										cropWidth = aspectRatioDiff;
										leftDiff = -(cropWidth - 2) / 2;
									} else if(aspectRatioDiff < 2) {
										cropHeight = layoutAspectRatio / (photoAspectRatio / 2);
										top = -(cropHeight - 1) / 2;
									}

									if(leftPage && !leftPage.getFreeMovementLocked() && !leftPage.getLocked()) {
										leftPage.extraPropertyChange(backgroundSettingKey, {
											crop: {
												left: leftDiff,
												width: cropWidth,
												top: top,
												height: cropHeight
											}
										}, true);
										leftLayout.setTheme($.extend(true, {}, theme), true);
									}
									if(rightPage && !rightPage.getFreeMovementLocked() && !rightPage.getLocked()) {
										rightPage.extraPropertyChange(backgroundSettingKey, {
											crop: {
												left: -1 + leftDiff,
												width: cropWidth,
												top: top,
												height: cropHeight
											}
										}, true);
										rightLayout.setTheme($.extend(true, {}, theme), true);
									}

									if(me.nextLayout) {
										if(rightPage && rightPage.updatePageLabel) {
											rightPage.updatePageLabel();
											rightLayout.setLabel(rightPage.getPageLabel());
										}
									} else if(me.previousLayout) {
										if(leftPage && leftPage.updatePageLabel) {
											leftPage.updatePageLabel();
											leftLayout.setLabel(leftPage.getPageLabel());
										}
									}
								} else {
									if(theme.Background && theme.Background.photoWidth && theme.Background.photoHeight) {
										let photoWidth = theme.Background.photoWidth;
										let photoHeight = theme.Background.photoHeight;
										let photoAspectRatio = photoWidth / photoHeight;
										let layoutAspectRatio = backgroundSize.width / backgroundSize.height;

										if($.isWithinDiff(photoAspectRatio, layoutAspectRatio, 0.01)) {
											// We are fine do not crop
										} else if(photoAspectRatio > layoutAspectRatio) {
											let cropWidth = photoAspectRatio / layoutAspectRatio;
											page.extraPropertyChange(backgroundSettingKey, {
												crop: {
													left: -((cropWidth - 1) / 2),
													width: cropWidth,
													top: 0,
													height: 1
												}
											}, true);
										} else if(photoAspectRatio < layoutAspectRatio) {
											let cropHeight = layoutAspectRatio / photoAspectRatio;
											page.extraPropertyChange(backgroundSettingKey, {
												crop: {
													left: 0,
													width: 1,
													top: -((cropHeight - 1) / 2),
													height: cropHeight
												}
											}, true);
										}
									}

									me.setTheme(theme, true);
								}
							}

							if (page.updatePageLabel) {
								page.updatePageLabel();
								me.setLabel(page.getPageLabel());
							}
							ui.draggable.data('dropped', page);
						} else if (ui.draggable.hasClass('themes')) {
							let theme = $.extend(true, {}, ui.draggable[0].theme);

							var parent = me.parent;
							parent.setTheme(theme);

							let page = me.getPage();
							if (page && page.updatePageLabel) {
								page.updatePageLabel();
								me.setLabel(page.getPageLabel());
							}
						} else if (ui.draggable.hasClass('candidPicture')) {
							me.addNewFrameFromDragTarget(ui.draggable, ui.position, event);
						} else if(ui.draggable.hasClass('subjectPhoto')) {
							me.addNewSubjectPhotoFromDragTarget(ui.draggable, ui.position, event);
						} else if(ui.draggable.hasClass('newShape')) {
							me.addNewShapeFromDragTarget(ui.draggable, ui.position, event);

							if($.layoutCategories) {
								var currentCategories = $.layoutCategories.getCurrentCategories();
								var maskCategory = currentCategories.getMatch({name: 'Masks'}, 'name');
								if(maskCategory && maskCategory.subCategories) {
									var shapeCategory = maskCategory.subCategories.getMatch({name: 'Shapes'}, 'name');
									if(shapeCategory) {
										$.layoutCategories.setCategory(shapeCategory);
									}
								}
							}
						} else if(ui.draggable.hasClass('newText')) {
							var textSettings = ui.draggable.data('textSettings');
							var textStyles = ui.draggable.data('textStyles');
							me.createNewText({
								left: event.clientX,
								top: event.clientY
							}, {
								textStyles: textStyles,
								textSettings: textSettings,
								autoFocus: !textSettings && !textStyles,
								centerOnPoint: true
							});
						} else if (ui.draggable.hasClass('classButton')) {
							var classObj = ui.draggable.data('classObj');

							let page = new $.YearbookClassPage(classObj);
							page.title = null;

							var subjectLabelTheme = me.getThemeTextStyles('Panel', 'subjectLabel');
							if(subjectLabelTheme) {
								$.extend(page.studentLabelCSS.css, subjectLabelTheme);
							}

							var pageMarginTheme = me.getThemeTextStyles('Panel', 'margins');
							if(pageMarginTheme) {
								$.extend(page.extras, {
									pageMargins: pageMarginTheme
								});
							}
							let startPagePlaceholder = $(me).hasClass('classPlaceholder');

							me.startGroupedEvents();
							me.addNewPage(page, function () {
								classObj.active = true;
								ui.draggable.addClass('active');
								// Delay this so we don't get a js error from destroying during a drag.stop event
								window.setTimeout(function () {
									var updateStatusHandler = ui.draggable.data('updateStatus');
									if(updateStatusHandler) {
										updateStatusHandler(classObj);
									}
								}, 10);

								if(startPagePlaceholder) {
									$(this).removeClass('classPlaceholder');
									if(oldPage && oldPage.resetBlankTitle) {
										oldPage.resetBlankTitle();
									}

									this.setLayout(this.getLayout(), {
										triggerOverflowPrompt: true
									});
								} else {
									var layout;
									if (this.getPage() == page || !this.getPage().getLayout()) {
										layout = $.DEFAULT_CLASS_LAYOUT
									}
									this.loadLayout(layout, undefined, {
										triggerOverflowPrompt: true
									});
								}
							});
						} else if (ui.draggable.hasClass('autograph')) {
							let page = new $.YearbookAutographPage();
							me.addNewPage(page, function () {
								this.loadLayout(ui.draggable.data('definition'));
							});
						} else if (ui.draggable.hasClass('index')) {
							let page = new $.YearbookIndexPage();
							
							var indexHeaderStyle = me.getThemeTextStyles('Index', 'indexHeader');
							if(indexHeaderStyle) {
								$.extend(page.extras, {
									indexHeaderStyle: indexHeaderStyle
								});
							}

							var labelStyle = me.getThemeTextStyles('Index', 'subjectIndex');
							if(labelStyle) {
								$.extend(page.extras, {
									labelStyle: labelStyle
								});
							}

							let definition = ui.draggable.data('definition');
							if(oldPage.type == 'index') {
								me.loadLayout(definition);
							} else {
								me.addNewPage(page, function () {
									this.loadLayout(ui.draggable.data('definition'));
								});
							}
						} else if (ui.draggable.hasClass('adTemplate')) {
							$.Confirm('Replace existing page', 'Would you like to replace the existing page with this template?', function() {
								let definition = $.extend(true, {}, ui.draggable.data('definition'));
								if(definition.origGrid) {
									let origGrid = definition.origGrid;
									origGrid.width = origGrid.width - origGrid.bleed.left - origGrid.bleed.right;
									origGrid.height = origGrid.height - origGrid.bleed.top - origGrid.bleed.bottom;
									definition.grid = origGrid;

									let currentGrid = oldPage.getLayout().grid;
									currentGrid.width = currentGrid.width - currentGrid.bleed.left - currentGrid.bleed.right;
									currentGrid.height = currentGrid.height - currentGrid.bleed.top - currentGrid.bleed.bottom;
									definition = oldPage.getScaledLayout(definition, {
										pageSetDimensions: currentGrid
									});
								}
								me.loadLayout(definition);
							});
						} else if (ui.draggable.hasClass('page-size')) {
							let definition = ui.draggable.data('definition');
							if (definition.grid == 'custom') {
								var form = $('<div class="ui form">');
								form.append('<div class="field"><label>Width</label><input name="width" placeholder="10" /></div>');
								form.append('<div class="field"><label>Height</label><input name="height" placeholder="8" /></div>');

								form.form({
									width: {
										identifier: 'width',
										rules: [
											{type: 'floatRange[4-100]'}
										]
									},
									height: {
										identifier: 'height',
										rules: [
											{type: 'floatRange[4-100]'}
										]
									}
								});

								$('<div class="ui classPlacement modal"><i class="close icon"></i><div class="header">Choose a width and height</div></div>')
									.append($('<div class="content">').append(form))
									.append('<div class="actions"><div class="ui deny button">Cancel</div><div class="ui approve primary button">OK</div></div>')
									.modal({
										onApprove: function () {
											if (!form.form('validate form')) {
												return false;
											}

											var width = parseFloat(form.find('input[name="width"]').val());
											var height = parseFloat(form.find('input[name="height"]').val());

											// Make sure to extend so a second custom drop will still give option
											definition = $.extend({}, definition, {
												grid: {
													width: width,
													height: height
												}
											});

											// Apply new layout definition
											me.startGroupedEvents();
											me.getPage().changeGridSize(definition.grid);
											me.loadLayout();
										},
										onHidden: function () {
											$(this).remove();
										}
									}).modal('show');
							} else {
								me.startGroupedEvents();
								me.getPage().changeGridSize(definition.grid);
								me.loadLayout();
							}
						} else if (ui.draggable.hasClass('subjectMask')) {
							var mask = $.extend(true, {}, ui.draggable.data('apply-mask'));
							if(mask && mask.isDirtyMask) {
								$.Alert('Warning', 'This mask has a dirty look and will not look good on portrait images.  Please try a different mask.');
							} else {
								me.setMask(mask);
							}
						} else if(ui.draggable.hasClass('candidPlaceholder')) {
							me.addNewCandidPlaceholderFromDragTarget(ui.draggable, ui.position);
						} else if(ui.draggable.hasClass('dynamicGraphic')) {
							me.addNewDynamicGraphicFromDragTarget(ui.draggable, ui.position);
						} else if(ui.draggable.hasClass('newPage')) {
							me.insertNewPage(ui.draggable[0].pageContructor, ui.draggable[0].getNewPageSettings);
						}
					},
					over: function (event, ui) {
						me.removeDraggingOver();
						if (!me.isValidDrop(ui.draggable)) {
							if (!ui.draggable.hasClass('flowPageNumber') && !ui.draggable.hasClass('flowFreeText') && !ui.draggable.hasClass('imageWrapper') && !ui.draggable.hasClass('pageMarginHandles')) {
								$(ui.helper).css('cursor', 'no-drop');
							}
						} else {
							if(ui.draggable.hasClass('candidPicture')) {
								var frame = new $.FlowLayoutFrame(me, ui.draggable);
								ui.draggable.data('current-droppable-frame', frame);
								ui.draggable.data('current-droppable', function (clone) {
									frame.currentHelper = clone;
									window.setTimeout(function () {
										if ($(clone).isAttached() && frame.hideOverlap($(me.canvas).find('.flowCell:not(.rowHeader)'), clone)) {
											me.addUsers();
										}
									}, 0);
								});
								$(me).data('dragging-frame', frame);
							}

							$(me).addClass('dragging-onto');
							if(ui.draggable.hasClass('backgrounds') && ui.draggable[0].theme && ui.draggable[0].theme.Background && me.isThemePartTwoPageSpread(ui.draggable[0].theme.Background) && (
									(me.nextLayout && me.nextLayout.page && !me.nextLayout.page.getFreeMovementLocked() && !me.nextLayout.page.getLocked())
									||
									(me.previousLayout && me.previousLayout.page && !me.previousLayout.page.getFreeMovementLocked() && !me.previousLayout.page.getLocked())
								)) {
								$(me).addClass('dragging-spread');
								if(me.nextLayout) {
									$(me.nextLayout).addClass('dragging-onto dragging-spread');
								} else if(me.previousLayout) {
									$(me.previousLayout).addClass('dragging-onto dragging-spread');
								}
							}
						}
					},
					out: function (event, ui) {
						$(ui.helper).css('cursor', 'auto');
						if (ui.draggable.data('current-droppable-frame')) {
							ui.draggable.removeData('current-droppable');
							$(me).data('dragging-frame', null);
							var frame = ui.draggable.data('current-droppable-frame');
							window.setTimeout(function () {
								if (frame.removeHidden()) {
									me.addUsers();
								}
							}, 0);
							ui.draggable.removeData('current-droppable-frame');
						}
						me.removeDraggingOver();
					}
				});

				$(this.container).on('dblclick', function (e) {
					if (me.parent.ignoreNextClick) {
						return;
					}
					let page = me.getPage();
					if (page && !page.getFreeMovementLocked() && !page.getLocked()) {
						me.createNewText({
							left: e.clientX,
							top: e.clientY
						}, {
							centerOnPoint: true
						});
					}
				});

				// Make sure that lower level objects make above click event not fire
				$(this.container).on('click', '>', function () {
					if (me.allowNextClickPropogation) {
						return true;
					} else {
						return false;
					}
				}).on('click', function() {
					me.parent.lastClickedLayout = me;
				});

				var overlay = this.layoutOverlay = $('<div class="flowPageOverlay" style="display: none">');
				overlay.append('<div class="ui tiny primary button">Small</div>')
					.append('<div class="ui medium primary button">Medium</div>')
					.append('<div class="ui big primary button">Large</div>')
					.append('<div class="ui big primary icon button customSizeButton" data-tooltip="Custom size"><i class="settings icon"></i></div>');

				overlay.find('div.button').click(function () {
					var startPage = me.getPage();
					var cellSize = $(this).text();

					if (cellSize) {
						me.setSize(cellSize, function () {
							// Action erased page -> clear overlay
							if (me.getPage() != startPage) {
								hideOverlay();
							}
						});
					} else {
						let minColumns = 2;
						if(me.definition.cells && me.definition.cells.Small && me.definition.cells.Small.insidePadding) {
							minColumns = 1;
						}

						var settings = [
							{
								name: 'columns',
								description: 'Columns',
								type: 'positiveInt',
								range: [minColumns, 15]
							},
							{
								name: 'rows',
								description: 'Rows',
								type: 'positiveInt',
								// We want to let them leave this blank
								customRule: 'intRangeWithEmpty[2-15]',
								help: 'Leave blank or 0 to automatically calculate.  We\'ll do our best to make your column choice fit. If it doesn\'t, we\'ll automatically fit as many as we can.'
							}
						];

						if(me.definition.lockColumns) {
							settings.shift();
							settings[0].customRule = 'intRange[2-15]';
						}

						$.SettingsBuilderDialog(settings, {
							title: 'Set number of columns and rows',
							onSettingsApplied: function(settings) {
								if(me.getPage() == startPage) {
									var columns = settings.columns;
									if(me.definition.lockColumns) {
										columns = me.definition.cell.columns;
									}

									me.calculateCellsToColumns(me.getLayout(), columns, settings.rows, true);
								}
							}
						});
					}
				});
				overlay.visible = false;
				overlay.appendTo(this.canvas);
				me.overlay = overlay;

				var _timeout;
				var hideOverlay = function () {
					if (overlay.visible) {
						overlay.stop(false, true).animate({opacity: 'toggle'});
						overlay.visible = false;
						window.clearTimeout(_timeout);
						_timeout = null;
					}
				};
				var startOverlayTimer = function () {
					_timeout = window.setTimeout(function () {
						hideOverlay();
					}, 2000);
				};
				$(this.container).mouseenter(function (e) {
					// Don't pop back up if we are trying to use toolbars on the page
					if(e.relatedTarget && ($(e.relatedTarget).closest('.flowEditToolbar').length || $(e.relatedTarget).closest('.flowEditColorPicker').length)) {
						return;
					}
					// Don't pop back up when changing text selection
					if($(e.target).closest('.flowContentFocused').length) {
						return;
					}

					var allowSizeOptions = me.isClassSizeAvailable() && !me.getPage().getFreeMovementLocked();
					if(allowSizeOptions && !overlay.visible) {
						// Should only happen if it is hidden now, which we don't care about so ignore errors
						try {
							overlay.center('top');
						} catch(e) {
							console.warn('Failed to center overlay', e);
						}
						overlay.stop(false, true).animate({opacity: 'toggle'});
						overlay.visible = true;
						startOverlayTimer();
					} else if(!allowSizeOptions && overlay[0].style.display != 'none') {
						overlay.hide();
						overlay.visible = false;
					}
				}).mouseleave(function (e) {
					hideOverlay();
				});

				$(overlay).mouseenter(function () {
					if (overlay.visible) {
						window.clearTimeout(_timeout);
						_timeout = null;
					}
				}).mouseleave(function () {
					if (overlay.visible) {
						startOverlayTimer();
					}
				});

				this.canvasMargins.setEditable($.getProjectSetting('allowMarginChanges', true));
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setEditable(true);
				});

				if(this.pageNumberDiv) {
					this.pageNumberDiv.setEditable(true);
				}

				if(this.titleDiv) {
					this.titleDiv.setEditable(true);
				}

				$(this).addClass('editablePage').removeClass('lockedPage');
				this.allowNextClickPropogation = true;
				this.editable = true;
			} else if ($(this).hasClass('editablePage') && (!editable || editableModifier === false)) {
				this.container.setEditable(false);
				this.canvas.setEditable(false);
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setEditable(false);
				});
				$(this.container).off('mouseenter dblclick click');
				if ($(this.droppableTarget).data('ui-droppable')) {
					$(this.droppableTarget).droppable('destroy');
				}
				$(this.droppableTarget).droppable({
					drop: function (event, ui) {
						if (!me.isValidLockedDrop(ui.draggable)) {
							return;
						}

						if (ui.draggable.hasClass('themes')) {
							let theme = $.extend(true, {}, ui.draggable[0].theme);

							var parent = me.parent;
							parent.setTheme(theme);

							let page = me.getPage();
							if (page && page.updatePageLabel) {
								page.updatePageLabel();
								me.setLabel(page.getPageLabel());
							}
						}
					},
					over: function (event, ui) {
						if(!me.isValidLockedDrop(ui.draggable)) {
							$(ui.helper).css('cursor', 'no-drop');
						}
					},
					out: function (event, ui) {
						$(ui.helper).css('cursor', 'auto');
					}
				});
				this.overlay.remove();
				this.overlay = null;
				this.canvasMargins.setEditable(false);
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.setEditable(false);
				});

				// Only bother if page isn't in the middle of being changed
				if (!pageChanging) {
					for (let i = 0; i < this.frames.length; i++) {
						this.frames[i].setMovementLocked(true);
					}

					for (let i = 0; i < this.texts.length; i++) {
						this.texts[i].setMovementLocked(true);
					}
				}

				$(this).removeClass('editablePage').addClass('lockedPage');
				this.editable = false;
				this.mainEditable = editable;

				let page = this.getPage();
				if (page && page.updatePageLabel) {
					page.updatePageLabel();
					this.setLabel(page.getPageLabel());
				}

				this.getCells().each(function () {
					this.disable();
				});
				$(this.canvas).find('.flowTitle').each(function () {
					// Compat with old Title
					if(this.disable) {
						this.disable();
					} else {
						this.setEditable(false);
					}
				});

				if (this.pageNumberDiv) {
					this.pageNumberDiv.setEditable(false);
				}

				if(this.titleDiv) {
					this.titleDiv.setEditable(false);
				}
			}

			if(typeof this.editable == 'undefined') {
				this.editable = editable;
			}

			this.mainEditable = editable;
		},
		removeDraggingOver: function(removeFromSet = false) {
			if(removeFromSet && this.parent) {
				$(this.parent.pages).removeClass('dragging-onto dragging-spread');
			} else {
				$(this).removeClass('dragging-onto dragging-spread');
			}
		},
		addNewFrameFromDragTarget: function(draggable, position, event) {
			var placeholderHidden = 0;
			if (draggable.data('current-droppable-frame')) {
				draggable.removeData('current-droppable');
				placeholderHidden = draggable.data('current-droppable-frame').removeHidden();
				draggable.removeData('current-droppable-frame');
			}
			$(this).data('dragging-frame', null);

			// If page is blank, treat it as a candid page
			let page = this.getPage();
			if (!page) {
				if($.YearbookCandidPage) {
					page = new $.YearbookCandidPage();
				} else {
					page = new $.FlowPage();
				}
				page.setLayout({});
				this.getPageSet().addPage(page);
				this.page = page;
				this.setTheme(this.getPageSet().getTheme());
			}
			if (page.type == 'empty') {
				page.propertyChange('type', 'candid');
			}

			// Create a new candid spot
			var orig = draggable.find('img');
			var parent = $(this.container).offset();
			// Photos in list have margins to make them display centered that screws up position relative to mouse
			position.left = position.left - parent.left + $(orig).getFloatStyle('marginLeft');
			position.top = position.top - parent.top + $(orig).getFloatStyle('marginTop');

			var frameDefinition = this.getNewFrame(position, orig, event);
			this.fixNewFrameDefinition(frameDefinition);
			var frame = new $.FlowLayoutFrame(this, frameDefinition, this.ratio, this.getContentSettings('image'));
			frame.extraOffset = this.canvasBorderWidth;

			this.container.appendChild(frame);
			this.frames.push(frame);
			page.addCandid(frameDefinition);

			var photoObj = draggable.data('photo');
			if(page.shouldAddCaptionsAutomatically(frameDefinition, photoObj)) {
				frame.addCaption();
			}

			this.onFlowAdd(frame, {
				forceAddUsers: placeholderHidden && frameDefinition.behindCells
			});
			draggable.data('dropped', page);
		},
		addNewCandidPlaceholderFromDragTarget: function(draggable, position) {
			// Create a new candid spot
			var orig = draggable.find('img');
			var parent = $(this.container).offset();
			position.left -= parent.left;
			position.top -= parent.top;

			var frameDefinition = $.extend({
				id: $.getGuid(),
				x: position.left / this.ratio,
				y: position.top / this.ratio,
				width: orig.width() / this.ratio,
				height: orig.height() / this.ratio,
				zIndex: this.getMaxZIndex() + 1
			}, this.extraContentProperties);

			this.addNewFrameFromDefinition(frameDefinition);
		},
		addNewDynamicGraphicFromDragTarget: function(draggable, position) {
			var orig = draggable.find('img');
			var parent = $(this.container).offset();
			position.left -= parent.left;
			position.top -= parent.top;

			var frameDefinition = $.extend({
				id: $.getGuid(),
				x: position.left / this.ratio,
				y: position.top / this.ratio,
				width: orig.width() / this.ratio,
				height: orig.height() / this.ratio,
				zIndex: this.getMaxZIndex() + 1,
				photoFieldMap: []
			}, this.extraContentProperties);

			this.addNewFrameFromDefinition(frameDefinition);
		},
		addNewSubjectPhotoFromDragTarget: function(draggable, position) {
			// If page is blank, treat it as a candid page
			let page = this.getPage();

			// Create a new candid spot
			var orig = draggable.find('img');
			var parent = $(this.container).offset();
			// Photos in list have margins to make them display centered that screws up position relative to mouse
			position.left = position.left - parent.left + $(orig).getFloatStyle('marginLeft');
			position.top = position.top - parent.top + $(orig).getFloatStyle('marginTop');

			var frameDefinition = $.extend({
				id: $.getGuid(),
				x: position.left / this.ratio,
				y: position.top / this.ratio,
				width: orig.width() / this.ratio,
				height: orig.height() / this.ratio,
				field: 'Image Name',
				zIndex: this.getMaxZIndex() + 1
			}, this.extraContentProperties);

			if(draggable.hasClass('yearbookPosePhoto')) {
				frameDefinition.poseSelection = 'Yearbook Pose';
			}

			var frame = new $.FlowLayoutFrame(this, frameDefinition, this.ratio, this.getContentSettings('image'));
			frame.extraOffset = this.canvasBorderWidth;
			this.container.appendChild(frame);
			this.frames.push(frame);
			page.addCandid(frameDefinition);
		},
		addNewShapeFromDragTarget: function(draggable, position) {
			// Create a new candid spot
			var parent = $(this.container).offset();
			position.left -= parent.left;
			position.top -= parent.top;

			var width = draggable.width() / this.ratio;
			var height = width;
			if(draggable.hasClass('isLine')) {
				height = 3 / this.ratio;
			}

			var frameDefinition = $.extend({
				id: $.getGuid(),
				x: position.left / this.ratio,
				y: position.top / this.ratio,
				width: width,
				height: height,
				zIndex: this.getMaxZIndex() + 1,
				shape: true,
				color: 'blue'
			}, this.extraContentProperties);

			this.addNewFrameFromDefinition(frameDefinition);
		},
		addNewFrameFromDefinition: function(frameDefinition) {
			let page = this.getPage();
			this.fixNewFrameDefinition(frameDefinition);

			var frame = new $.FlowLayoutFrame(this, frameDefinition, this.ratio, this.getContentSettings('image'));
			frame.extraOffset = this.canvasBorderWidth;
			this.container.appendChild(frame);
			this.frames.push(frame);
			page.addCandid(frameDefinition);

			// If page is blank, treat it as a candid page
			if(page.type == 'empty') {
				page.propertyChange('type', 'candid');
			}

			this.onFlowAdd(frame);
		},
		fixNewFrameDefinition: function(frameDefinition) {
			var contentSettings = this.getContentSettings('image');

			if(contentSettings.containInParent) {
				if(frameDefinition.x < 0) {
					frameDefinition.x = 0;
				}
				if(frameDefinition.y < 0) {
					frameDefinition.y = 0;
				}

				var rightPosition = frameDefinition.x + frameDefinition.width;
				var positionDiff = rightPosition - this.inchContentSize.width;
				if(positionDiff > 0) {
					var ratio = frameDefinition.width / frameDefinition.height;
					frameDefinition.width -= positionDiff;
					frameDefinition.height = frameDefinition.width / ratio;
				}

				var topPosition = frameDefinition.y + frameDefinition.height;
				positionDiff = topPosition - this.inchContentSize.height;
				if(positionDiff > 0) {
					ratio = frameDefinition.width / frameDefinition.height;
					frameDefinition.height -= positionDiff;
					frameDefinition.width = frameDefinition.height * ratio;
				}
			}
		},
		addNewPage: function (newPage, onComplete, options) {
			options = $.extend({}, options);
			var dialogTitle;
			var newPagesAllowed = this.isAllowedToAddPages(false);
			let page = this.getPage();
			if (!page) {
				$.fireErrorReport('Failed to add new page.  Refreshing your browser may fix this', 'Failed to add new page', 'addNewPage called on layout with no page defined', {
					snapshot: this.getSnapshot()
				});
				return;
			}

			var skipPages = ['cover', 'insideCover'];
			if(page.type == 'empty') {
				this.getPageSet().replacePage(page, newPage);
				this.updateChildLayout();
				this.page = newPage;
				onComplete.call(this, {
					added: false
				});
			} else if(newPage.type === 'candid' && page.type === 'candid' && (newPage.getExtraProperty('layoutProperties') || {}).adIds && ((page.getExtraProperty('layoutProperties') || {}).adIds || ($.getObjectCount(page.getCandids()) === 0 && $.getObjectCount(page.getTexts()) === 0))) {
				onComplete.call(this, {
					added: false
				});
			} else if(newPage.type === page.type && options.matchExtras && Object.keys(options.matchExtras).length > 0 && Object.keys(options.matchExtras).filter(function(matchExtra) {
				return page.getExtraProperty(matchExtra) == options.matchExtras[matchExtra];
			}).length === Object.keys(options.matchExtras).length) {
				onComplete.call(this, {
					added: false
				});
			} else if(page.type === 'candid' && $.getObjectCount(page.texts) === 0 && $.getObjectCount(page.candids) === 0 && !page.getLocked()) {
				this.getPageSet().replacePage(page, newPage);
				this.updateChildLayout();
				this.page = newPage;
				onComplete.call(this, {
					added: false
				});
			} else if (newPage.type == 'candid' && (page.type == 'cover' || page.type == 'title' || (page.type == 'insideCover' && !page.getLocked())) && this.editable) {
				let actions = $('<div class="actions"><div class="ui negative button">Replace</div></div>');
				if (newPagesAllowed) {
					if (page.getPageNumber() <= 3) {
						actions.append('<div class="ui approve primary button">After</div>');
					} else {
						actions.append('<div class="ui approve primary button">Before</div>');
					}
				}

				let me = this;
				$('<div class="ui classPlacement modal"><i class="close icon"></i><div class="header">Would you like to replace the existing page with this candid template or to add a new candid page?</div></div>')
					.append(actions)
					.modal({
						onDeny: function () {
							me.startGroupedEvents();
							page.clearCandids();
							page.clearTexts();
							onComplete.call(me, {
								added: false
							});
						},
						onApprove: function () {
							var pageSet = me.getPageSet();
							var layout;
							if (page.getPageNumber() <= 3) {
								pageSet.addPage(newPage, 2);
							} else {
								var lastSpot = pageSet.getTotalPages() - 3;
								pageSet.addPage(newPage, lastSpot);
							}

							// Delay so that goToPage will have already executed
							layout = me.parent.goToPage(newPage);
							window.setTimeout(function () {
								onComplete.call(layout, {
									added: true
								});
							}, 11);
						},
						onHidden: function () {
							$(this).remove();
						}
					}).modal('show');
			} else if (skipPages.indexOf(page.type) != -1) {
				if (newPagesAllowed) {
					var pageSet = this.getPageSet();
					var layout;
					if (page.getPageNumber() <= 3) {
						var index, insideCovers = 0;
						for (let i = 0; i < pageSet.getTotalPages(); i++) {
							var type = pageSet.getPage(i).type;
							if (type == 'insideCover') {
								insideCovers++;
							}

							if (skipPages.indexOf(type) != -1 && insideCovers < 2) {
								index = i;
							} else {
								break;
							}
						}

						pageSet.addPage(newPage, index);
					} else {
						var lastSpot = pageSet.getTotalPages() - 3;
						pageSet.addPage(newPage, lastSpot);
					}

					// Delay so that goToPage will have already executed
					layout = this.parent.goToPage(newPage);
					window.setTimeout(function () {
						onComplete.call(layout, {
							added: true
						});
					}, 11);
				} else {
					this.pastPageLimitAlert();
					return;
				}
			} else if (page.type == 'class' && !page.getClass() && newPage.type == 'class' && newPage.getClass()) {
				page.changeClass(newPage.getClass());
				if (page.title == 'Class Placeholder') {
					page.title = null;
				}
				onComplete.call(this, {
					added: false
				});
			} else {
				let index = page.getPageNumber() - 1;
				var pageNumber = index - 1;
				let me = this;

				var pageType = newPage.getType();
				if (newPage.getType() == 'class') {
					pageType = 'class';
				} else if (newPage.getType() == 'candid') {
					pageType = 'candid page';
				} else if (newPage.getType() == 'autograph') {
					pageType = 'autograph page';
				}

				let actions = $('<div class="actions">');
				if (newPagesAllowed) {
					if (page.getPageNumber() >= 3) {
						actions.append('<div class="ui deny button">Before</div>');
					} else {
						dialogTitle = 'Would you like to place this ' + pageType + ' before page ' + pageNumber;
					}
					if(page.getPageNumber() <= me.getPageSet().getTotalPages() - 2) {
						actions.append('<div class="ui approve button">After</div>');
					} else {
						dialogTitle = 'Would you like to place this ' + pageType + ' after page ' + Math.max(0, pageNumber);
					}
					if(pageType === 'layout') {
						dialogTitle = 'Would you like to replace the existing layout with this template?';
					}

					var setupMergeModal = false, setupReplaceButton = false;
					if (newPage.getType() == 'class' && page.getType().indexOf('class') != -1) {
						setupMergeModal = true;
						let title = page.getTitleText();
						$('<div class="ui mergeButton button">Combine on page with ' + title + '</button>').prependTo(actions);
					} else {
						setupReplaceButton = true;
						$('<div class="ui replaceButton button">Replace</button>').prependTo(actions);
					}
				} else if (newPage.getType() == 'candid' && page.getType() == 'candid') {
					setupReplaceButton = true;
					$('<div class="ui replaceButton button">Replace</button>').prependTo(actions);
					dialogTitle = 'Would you like to replace the existing page with this candid template?';
				} else if (newPage.getType() == 'class' && page.getType().indexOf('class') != -1) {
					setupMergeModal = true;
					let title = page.getTitleText();
					actions.append('<div class="ui deny button">Cancel</div>');
					actions.append('<div class="ui mergeButton button">Combine on page with ' + title + '</button>')
					dialogTitle = 'Would you like to combine this class on page ' + pageNumber + '?';
				} else {
					setupReplaceButton = true;
					$('<div class="ui replaceButton button">Replace</button>').prependTo(actions);
					dialogTitle = 'Would you like to replace the existing page?';
				}

				// Adding candid layout to class page
				if((page.getType() === 'class' || page.getType() === 'classOverflow') && newPage.getType() === 'candid') {
					$('<div class="ui mergeButton button" data-tooltip="Add layout elements onto the class page">Merge</button>').click(function() {
						onComplete.call(me, {
							added: false,
							merge: true
						});

						modal.modal('hide');
					}).insertAfter(actions.find('.replaceButton'));
				}
				// Adding class to candid page
				if(page.getType() === 'candid' && newPage.getType() === 'class') {
					$('<div class="ui mergeButton button" data-tooltip="Add class but keep content already on the page">Merge</button>').click(function() {
						me.startGroupedEvents();
						me.getPageSet().replacePage(page, newPage, {
							candids: true,
							texts: true,
							comments: true
						});
						me.updateChildLayout();
						me.page = newPage;
						onComplete.call(me, {
							added: false
						});
						modal.modal('hide');
					}).insertAfter(actions.find('.replaceButton'));
				}

				if(setupReplaceButton && page.getParentPage) {
					setupReplaceButton = false;
					actions.find('.replaceButton').remove();
				}

				if (!dialogTitle) {
					dialogTitle = 'Would you like to place this ' + pageType + ' before or after page ' + pageNumber + '?';
				}
				var modal = $('<div class="ui classPlacement modal"><i class="close icon"></i><div class="header">' + dialogTitle + '</div></div>')
					.append(actions)
					.modal({
						onDeny: function (button) {
							if($(button).text() == 'Cancel') {
								return true;
							}

							// Make sure previous page is not a overflow page!
							var pagesSkipped = 0;
							var previousPage = me.getPageSet().getPage(index);
							while (previousPage != null && previousPage.type.toLowerCase().indexOf('overflow') !== -1) {
								pagesSkipped++;
								previousPage = me.getPageSet().getPage(index - pagesSkipped);
							}

							me.getPageSet().addPage(newPage, index - 1 - pagesSkipped);

							let layout = me;
							if (pagesSkipped) {
								layout = me.parent.getPreviousPage(me, pagesSkipped - 1);
							}
							layout.setPage(newPage);

							// Make sure we update next page if it exists
							var nextLayout = me.parent.getNextVisiblePage(layout);
							if (nextLayout) {
								var nextPage = me.parent.getCurrentPages()[1];
								if (nextLayout.getPage() != nextPage) {
									nextLayout.setPage(me.parent.getCurrentPages()[1]);
								}
							}

							onComplete.call(layout, {
								added: true
							});
						},
						onApprove: function () {
							// Make sure next page is not a overflow page!
							var pagesSkipped = 0;
							var nextPage = me.getPageSet().getPage(index + 1);
							while (nextPage != null && nextPage.type.toLowerCase().indexOf('overflow') !== -1) {
								pagesSkipped++;
								nextPage = me.getPageSet().getPage(index + 1 + pagesSkipped);
							}

							me.getPageSet().addPage(newPage, index + pagesSkipped);
							let layout = me.parent.getNextPage(me, pagesSkipped);
							if (layout.getPage() != newPage) {
								layout.setPage(newPage);
							}

							onComplete.call(layout, {
								added: true
							});
						},
						onHidden: function () {
							$(this).remove();
						}
					}).modal('show');

				if (setupMergeModal) {
					let title = page.getTitleText();

					$('<div class="ui modal"><i class="close icon"></i><div class="header">Confirm Combination?</div></div>')
						.append('<div class="content">You are about to combine ' + newPage.getTitleText() + ' onto the same page as ' + title + '. Do you want to continue?</div>')
						.append('<div class="actions"><div class="ui deny button">Cancel</div><div class="ui approve primary button">OK</div></div>')
						.modal({
							onApprove: function () {
								let layout = me;
								while (page.getType() == 'classOverflow') {
									page = page.getParentPage();
									if (me.parentLayout) {
										layout = me.parentLayout;
									}
								}

								layout.mergeClassPages(page, newPage.classObj, onComplete, {
									added: false
								});
							},
							onHidden: function () {
								$(this).remove();
							}
						})
						.modal('attach events', '.modal.classPlacement .mergeButton');
				} else if (setupReplaceButton) {
					$('<div class="ui modal"><i class="close icon"></i><div class="header">Confirm Replacement?</div></div>')
						.append('<div class="content">You are about to replace this ' + page.type + ' page with a new ' + newPage.type + ' page. Do you want to continue?</div>')
						.append('<div class="actions"><div class="ui deny button">Cancel</div><div class="ui approve primary button">OK</div></div>')
						.modal({
							onApprove: function () {
								me.startGroupedEvents();
								me.getPageSet().replacePage(page, newPage);
								me.updateChildLayout();
								me.page = newPage;
								onComplete.call(me, {
									added: false
								});
							},
							onHidden: function () {
								$(this).remove();
							}
						})
						.modal('attach events', '.modal.classPlacement .replaceButton');
				}
			}
		},
		isAllowedToAddPages: function (displayError) {
			var pageSet = this.getPageSet();
			if(this.isPastPageLimit(true)) {
				if (displayError) {
					this.pastPageLimitAlert();
				}
				return false;
			} else if(!pageSet.isAllowedToAddPages()) {
				return false;
			} else {
				return true;
			}
		},
		pastPageLimitAlert: function () {
			$.Alert('Error', 'You have reached your allotted page limit. Your limit is ' + this.getPageSet().getPageLimit() + '.');
		},
		isPastPageLimit: function (dontAllowEqualTo) {
			var pageSet = this.getPageSet();
			var totalPages = pageSet.getTotalPages() - 4;
			var pageLimit = pageSet.getPageLimit();
			if (pageLimit <= 0 || totalPages < pageLimit || (!dontAllowEqualTo && totalPages === pageLimit)) {
				return false;
			} else {
				return true;
			}
		},
		mergeClassPages: function (page, newClass, onComplete) {
			page.addExtraClass(newClass);
			onComplete.call(this);
		},
		mergeClassLayoutOptions: function(page, definition) {
			if(definition.title !== undefined) {
				let oldTitle = page.title;
				if(oldTitle?.lines && definition.title) {
					this.page.setTitle({
						lines: $.extend(true, {}, oldTitle.lines, {
							text: definition.title
						})
					});
				} else {
					this.page.setTitle(definition.title);
				}

				(page.extraClasses ?? []).forEach(extraBatch => {
					let extraTitle = definition.title;
					let existingTitle = page.extraTitles?.[extraBatch.id];
					if(existingTitle?.lines && definition.title) {
						extraTitle = {
							lines: $.extend(true, {}, existingTitle.lines, {
								text: extraTitle
							})
						};
					}

					page.jsonPropertyChange('extraTitles', extraBatch.id, extraTitle);
				});

				delete definition.title;
			}

			if(Object.values(definition).length > 0) {
				page.mergeLayoutOptions(definition);
			}
			if($.isInit(definition.sidePadding) || (definition.cell && $.isInit(definition.cell.padding))) {
				let rootLayout = $.extend(true, {}, page.getLayout());
				this.initLayoutDefinition(rootLayout);
				if(rootLayout.cellCols) {
					this.calculateCellsToColumns(rootLayout, rootLayout.cellCols, rootLayout.cellRows, false);
				} else if(rootLayout.cell.columns) {
					this.calculateCellsToColumns(rootLayout, rootLayout.cell.columns, null, false);
				}

				page.setLayout(rootLayout, true, true);
			}

			this.loadLayout();
		},

		startLoading: function (blockingLoad) {
			this.loading++;

			if (!this.loadingDiv) {
				this.loadingDiv = $('<div id="layoutLoading" class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
				$(this).append(this.loadingDiv);
			} else {
				this.loadingDiv.addClass('active');
			}
			if (typeof blockingLoad != 'undefined') {
				this.blockingLoad = blockingLoad;
			}

			let page = this.getPage();
			var overflowLayout = this;
			while (page && page.getOverflowPage && page.getOverflowPage() && this.parent) {
				overflowLayout = this.parent.getNextVisiblePage(overflowLayout);
				if (overflowLayout) {
					overflowLayout.startLoading(blockingLoad);
					this.childrenLoading.push(overflowLayout);
				}
				page = page.getOverflowPage();
			}
		},
		stopLoading: function (blockingLoad) {
			this.loading--;
			if (this.loading < 0) {
				this.loading = 0;
			}

			if (!this.loading) {
				$(this.loadingDiv).removeClass('active');
				this.loadingDiv = null;
			}
			if (typeof blockingLoad != 'undefined') {
				this.blockingLoad = blockingLoad;
			}

			while (this.childrenLoading.length > 0) {
				this.childrenLoading.pop().stopLoading();
			}
		},
		isLoading: function() {
			return $(this.loadingDiv).hasClass('active');
		},
		getPageSet: function () {
			// It is important that we pull from this objects pageSet first so we can assign the subset of the pages to the $.FlowLayoutSet
			if(this.pageSet) {
				return this.pageSet;
			} else if(this.parent) {
				return this.parent.pageSet;
			} else {
				return null;
			}
		},
		getLayoutDimensions: function() {
			var pageSet = this.getPageSet();
			if(pageSet && pageSet.getLayoutDimensions) {
				return pageSet.getLayoutDimensions();
			} else {
				return null;
			}
		},

		getSubjectAtIndex: function(index) {
			let subjects = this.getSubjects();
			// When we have a largeCellUser set we are changing the order of subjects so #1 is the large cell user
			if(subjects && this.largeCellUser > 0 && subjects[this.largeCellUser] && index === 0) {
				subjects = [...subjects];
				subjects.unshift(...subjects.splice(this.largeCellUser, 1));
			}

			if(subjects && index >= 0 && index < subjects.length) {
				return subjects[index];
			} else {
				return null;
			}
		},
		getFirstSubject: function() {
			return this.getSubjectAtIndex(0);
		},
		setPrimarySubject: function(subject) {
			let subjects = this.getSubjects();
			let index = subjects.indexOfMatch(subject, 'id');

			this.setPrimarySubjectIndex(index);
		},
		getPrimarySubject: function() {
			return this.getSubjectAtIndex(this.primarySubjectIndex);
		},
		getPrimarySubjectIndex: function() {
			return this.primarySubjectIndex;
		},
		setPrimarySubjectIndex: function(primarySubjectIndex) {
			this.primarySubjectIndex = primarySubjectIndex;
			this.updatePrimarySubject();
		},
		incrementPrimarySubject: function() {
			this.primarySubjectIndex++;
			this.updatePrimarySubject();
		},
		decrementPrimarySubject: function() {
			this.primarySubjectIndex--;
			this.updatePrimarySubject();
		},
		updatePrimarySubject: function() {
			// TODO: This should only refresh what it needs to
			this.refreshFrames();
			this.refreshTexts();
		},
		hasPreviousPrimarySubject: function() {
			if(this.primarySubjectIndex <= 0) {
				return false;
			} else {
				return true;
			}
		},
		hasNextPrimarySubject: function() {
			let subjects = this.getSubjects();
			if(subjects && (this.primarySubjectIndex + 1) < subjects.length) {
				return true;
			} else {
				return false;
			}
		},
		getSubjects: function() {
			let page = this.getPage();
			if(page && page.getSubjects) {
				return page.getSubjects();
			} else {
				return null;
			}
		},
		getSubjectIndexes: function() {
			if(!this.getPage() || !this.getPageSet()) {
				return {};
			}

			var subjectIndexes = {};

			let subjects = this.getPageSet().getSubjects();
			if(!subjects) {
				return {};
			}
			subjects = subjects.filter(function(subject) {
				return !!subject;
			});
			
			var outerRect = this.getBoundingClientRect();
			let page = this.getPage();
			this.getCells().each(function() {
				if(!this.user || !this.user.yearbookPhoto) {
					return;
				}

				var subjectIndex = subjectIndexes[this.user.id];
				if(!subjectIndex) {
					subjectIndexes[this.user.id] = subjectIndex = wrapper.createIndexForSubject(this.user);
				}

				var imgRect = this.imgWrapper.getBoundingClientRect();
				subjectIndex.images.push({
					type: 'portrait',
					page: page.pageNumber - $.PAGE_OFFSET,
					id: this.user.yearbookPhoto.id,
					x: wrapper.convertToPdfPixelCoordinates(imgRect.left - outerRect.left),
					y: wrapper.convertToPdfPixelCoordinates(imgRect.top - outerRect.top),
					width: wrapper.convertToPdfPixelCoordinates(imgRect.width),
					height: wrapper.convertToPdfPixelCoordinates(imgRect.height)
				});
			});

			$(this.container).children('.flowLayoutFrame').each(function() {
				if(!this.instance || !this.instance.photo) {
					return;
				}

				for(let i = 0; i < subjects.length; i++) {
					var subject = subjects[i];
					if(!subject.photos) {
						continue;
					}

					for(var j = 0; j < subject.photos.length; j++) {
						var photo = subject.photos[j];

						if(this.instance.photo == photo.id) {
							var subjectIndex = subjectIndexes[subject.id];
							if(!subjectIndex) {
								subjectIndexes[subject.id] = subjectIndex = wrapper.createIndexForSubject(subject);
							}

							var imgRect = this.getBoundingClientRect();
							subjectIndex.images.push({
								type: 'candid',
								page: page.pageNumber - $.PAGE_OFFSET,
								id: this.photoId,
								x: wrapper.convertToPdfPixelCoordinates(imgRect.left - outerRect.left),
								y: wrapper.convertToPdfPixelCoordinates(imgRect.top - outerRect.top),
								width: wrapper.convertToPdfPixelCoordinates(imgRect.width),
								height: wrapper.convertToPdfPixelCoordinates(imgRect.height)
							});

							return;
						}
					}
				}
			});

			return subjectIndexes;
		},
		convertToPdfPixelCoordinates: function(number) {
			return Math.ceil(number / this.ratio * $.PDF_PIXEL_RATIO);
		},
		createIndexForSubject: function(subject) {
			return {
				id: subject.id,
				firstName: subject['First Name'],
				lastName: subject['Last Name'],
				grade: subject['Grade'],
				teacher: subject['Teacher'],
				homeRoom: subject['Home Room'],
				images: []
			};
		},

		// TODO: Make this name obvious this is an index and NOT a subject
		getLargeCellUser: function () {
			return this.largeCellUser;
		},
		getLargeCellSubject: function() {
			if(this.largeCellUser === -1) {
				return null;
			}

			let subjects = this.getSubjects();
			return subjects[this.largeCellUser];
		},
		setLargeCellUser: function (index) {
			this.largeCellUser = index;

			let layout = this.getLayout();
			// Make sure we do a hard refresh each time we switch subjects to identical
			if(layout?.cell?.size === 'fit') {
				this.hardRefreshFitLayout(layout);
			}

			this.addUsers();
			this.refreshTexts();
			if(this.primarySubjectPoseFrame && $(this.primarySubjectPoseFrame).isAttached()) {
				this.primarySubjectPoseFrame.refreshInstance();
			}
		},
		setLabelError: function(error, failOnError = true) {
			if(this.userLabelError != error) {
				this.userLabelError = error;

				if(this.userLabelError && this.label) {
					if (this.label.hasClass('hasPopup')) {
						this.label.popup('destroy');
					}

					this.label.removeClass('orange teal').addClass('red');
					this.label.addClass('hasPopup').popup({
						content: this.userLabelError
					});
				} else {
					this.updateLabel();
				}

				if(this.userLabelError) {
					$.setSingleTimeout.call(this, 'promptUserError', () => {
						if($.isBackgroundRenderer && failOnError) {
							throw this.userLabelError;
						} else if(window.alertify && this.userLabelError) {
							window.alertify.error(this.userLabelError);
						}
					}, 1000);
				} else {
					$.clearSingleTimeout.call(this, 'promptUserError');
				}
			}
		},
		updateLabel: function() {
			let page = this.getPage();
			if(page) {
				if(page.updatePageLabel) {
					page.updatePageLabel();
				}
				var label = page.getPageLabel();
				this.setLabel(label);
			}
		},
		setLabel: function (label) {
			// Pusher drives hiding overflow behavior so we should always require it
			this.setupPushableSidebar();

			if (label && (!this.parent || this.parent.showLabels) && this.showLabels) {
				label = this.updateLabelDisplay(label);
				if (!this.label) {
					this.label = $('<a>').appendTo(this);
				}
				if (this.label.hasClass('hasPopup')) {
					this.label.popup('destroy');
				}
				this.label[0].className = 'ui teal ' + (this.side == 'Left' ? 'left' : 'right') + ' ribbon label pageLabel';

				if($.isInit(label.display)) {
					if((label.display !== '' || this.editable || label.forceSidebar || label.forcePopup)) {
						var icon = 'setting';
						if($.isInit(label.icon)) {
							icon = label.icon;
						}

						if (label.display === '') {
							this.label.removeClass('teal ribbon').addClass('orange tiny corner');
							this.label.html('<i class="' + icon +' icon"></i>');

							if(label.color) {
								this.label.removeClass('orange').addClass(label.color);
							}
						} else {
							var display = label.display;
							if (label.sidebar && this.editable) {
								if (this.side == 'Left') {
									display = '<i class="' + icon +' icon"></i> ' + display;
								} else {
									display = display + ' <i class="' + icon +' icon"></i>';
								}
							}
							this.label.html(display);

							if(label.color) {
								this.label.removeClass('teal').addClass(label.color);
							}
						}

						if (label.popup && (this.editable || label.forcePopup)) {
							var popup;
							if($.isPlainObject(label.popup)) {
								popup = label.popup;

								$.extend(popup, {
									lastResort: true
								});
							} else {
								popup = {
									content: label.popup,
									lastResort: true
								};
							}

							this.label.addClass('hasPopup').popup(popup);
						}

						this.label.show();
						if (this.editable || (this.mainEditable && label.forceSidebar)) {
							this.setSidebar(label.sidebar);
							$(this.label).css('cursor', '');
						} else {
							this.setSidebar(null);
							$(this.label).css('cursor', 'default');
						}
					} else {
						this.label.hide();
						this.setSidebar(null);
					}
				} else {
					this.label.show().html(label.display ? label.display : label);
					$(this.label).css('cursor', 'default');
					this.setSidebar(null);
				}
			} else if (this.label) {
				this.label.hide();
				this.setSidebar(null);
			}
		},
		updateLabelDisplay: function(label) {
			if(this.userLabelError) {
				label = $.extend(true, {}, label);
				label.popup = this.userLabelError;
				label.color = 'red';
			}

			return label;
		},
		setSidebar: function (sidebar) {
			var me = this;
			if (sidebar && sidebar.length !== 0) {
				if (!this.sidebar) {
					this.sidebar = $('<div class="ui ' + (this.side == 'Left' ? 'left' : 'right') + ' vertical inverted labeled icon sidebar menu"></div>').prependTo(this);
					this.setupPushableSidebar();

					this.sidebar.sidebar({
						context: this,
						transition: 'overlay',
						onHidden: function () {
							$(me).css({
								'overflow': ''
							});

							if(me.sidebarDelayedFix) {
								window.clearTimeout(me.sidebarDelayedFix);
							}
							me.sidebarDelayedFix = window.setTimeout(function() {
								if(me.sidebar.hasClass('overlay animating')) {
									$(me.sidebar).removeClass('animating');
								}
								me.sidebarDelayedFix = null;
							}, 10);
						},
						onVisible: function () {
							$(me).css({
								'overflow': 'hidden'
							});
						}
					});
					this.sidebar.sidebar('attach events', '#' + $(this).attr('id') + ' .sidebar.menu');
				}

				$(this.label).off('click').on('click', function () {
					$(me.sidebar).sidebar('toggle');
				});

				// Destroy any popups
				this.sidebar.find('.popup').each(function () {
					$(this).popup('destroy');
				});

				this.sidebar.empty();
				for (let i = 0; i < sidebar.length; i++) {
					var item = sidebar[i];
					var elem = $('<a class="item"><i class="' + item.icon + ' icon"></i>' + item.name + '</a>').click(function () {
						var item = $(this).data('item');
						item.onClick.call(this, me.getPage(), me);
					}).data('item', item).appendTo(this.sidebar);

					if (item.popup) {
						elem.popup({
							content: item.popup
						}).addClass('popup');
					}
				}
			} else if (this.sidebar) {
				$(this.label).off('click');
			}

			// Make sure to hide the sidebar when changing pages
			if (this.sidebar && $(this.sidebar).sidebar('is visible')) {
				$(this.sidebar).sidebar('hide');
			}
		},
		setCommentsBar: function(visible, editable) {
			var me = this;
			if(visible) {
				if (!this.commentsLabel) {
					this.commentsLabel = $('<a class="ui yellow corner ' + (this.side == 'Right' ? 'left' : 'right') + ' tiny corner label commentsLabel"><i class="comment icon"></i></a>').appendTo(this);
				}

				if(this.commentsBar) {
					this.commentFeed.setEditable(editable);
				} else {
					this.commentsBar = $('<div class="ui ' + (this.side == 'Right' ? 'left' : 'right') + ' vertical inverted labeled icon sidebar commentsSidebar"></div>').prependTo(this);
					this.setupPushableSidebar();

					this.commentsBar.sidebar({
						context: this,
						transition: 'overlay',
						onHidden: function () {
							$(me).css({
								'overflow-x': ''
							});
							me.commentFeed.clearComments();

							if(me.commentsSidebarDelayedFix) {
								window.clearTimeout(me.commentsSidebarDelayedFix);
							}
							me.commentsSidebarDelayedFix = window.setTimeout(function() {
								if(me.commentsBar.hasClass('overlay animating')) {
									$(me.commentsBar).removeClass('animating');
								}
								me.commentsSidebarDelayedFix = null;
							}, 10);
						},
						onVisible: function () {
							$(me).css({
								'overflow-x': 'hidden'
							});

							let page = me.getPage();
							if (page) {
								me.commentFeed.setComments(page.getComments());
							}
						},
						onShow: function() {
							me.commentFeed.setFocused(true);
						}
					});

					var commentFeedOptions = {
						onCommentSubmit: function (comment) {
							let page = me.getPage();
							if(page) {
								page.addComment(comment);
							}
						},
						editOriginal: false,
						editable: editable
					};
					let page = this.getPage();
					if(page && page.onCheckComment) {
						commentFeedOptions.onCheck = function(comment) {
							let page = me.getPage();
							page.onCheckComment(comment);
						};
					}
					if(page && page.onReplyComment) {
						commentFeedOptions.onReply = function(comment, reply) {
							let page = me.getPage();
							page.onReplyComment(comment, reply);
						};
					}

					this.commentFeed = new $.CommentFeed(null, commentFeedOptions);
					this.commentsBar.append(this.commentFeed);
				}

				this.commentsLabel.show();
				$(this.commentsLabel).off('click').on('click', function () {
					$(me.commentsBar).sidebar('toggle');
				});
			} else if (this.commentsLabel) {
				$(this.commentsLabel).off('click').hide();
			}

			// Make sure to hide the sidebar when changing pages
			if (this.commentsBar && $(this.commentsBar).sidebar('is visible')) {
				$(this.commentsBar).sidebar('hide');
			}
		},
		setupPushableSidebar: function () {
			if (!$(this).hasClass('pushable')) {
				$(this).addClass('pushable');
				this.pusher = $('<div class="pusher">').appendTo(this);
				$(this.container).detach().appendTo(this.pusher);

				this.pusher.on('scroll', function() {
					this.scrollTop = 0;
					this.scrollLeft = 0;
				});
			}
		},
		getSnapshot: function () {
			let page = this.getPage();
			var canvasRect = this.canvasMargins.getBoundingClientRect();
			var batch = page.getClass ? page.getClass() : null;
			if(batch) {
				batch = {
					id: batch.id,
					name: batch.name,
					kids: batch.subjects ? batch.subjects.length : null
				};
			}

			return {
				pageNumber: page.getPageNumber(),
				layout: JSON.stringify($.removeFunctions(this.getLayout())),
				visibleDimensions: {
					width: $(this).width(),
					height: $(this).height()
				},
				canvas: {
					width: canvasRect.width,
					height: canvasRect.height
				},
				candids: page.getCandids(),
				texts: page.getTexts(),
				class: batch,
				type: page.type
			};
		},

		openSubjectSettings: function () {
			var modal = $('<div class="ui modal"><i class="close icon"></i><div class="header">Subject Settings</div><div class="content"></div></div>');
			modal.append('<div class="actions"><div class="ui deny button">Cancel</div><div class="ui approve primary button">OK</div></div>');
			var content = modal.find('.content');

			var validFields = ['first', 'last'];
			var user = this.getPage().getKids()[0];
			for (let i in user) {
				// Ignore non-user editable fields
				if (['id', 'photo', 'photoCdnUrl'].indexOf(i) == -1) {
					validFields.push(i);
				}
			}

			var helpPopup = 'Valid fields: %' + validFields.join('%, %') + '%';

			let layout = this.getLayout();
			var orderSettings = [];
			var staffOrderSettings = [];
			if(layout.name) {
				orderSettings.push({
					name: 'order',
					type: 'text',
					description: 'First Line',
					customRule: ''
				});

				if(layout.name && !$.isInit(layout.name.teacherPrefixOrder)) {
					layout.name.teacherPrefixOrder = '%prefix% %last name%';
				}

				// Only do multiple lines with label underneath cell
				if(layout.cell && layout.cell.name) {
					orderSettings.push({
						name: 'order2',
						type: 'text',
						description: 'Second Line',
						customRule: ''
					});
					orderSettings.push({
						name: 'order3',
						type: 'text',
						description: 'Third Line',
						customRule: ''
					});

					orderSettings.push({
						id: 'overflow',
						type: 'checkbox',
						description: 'Add Overflow Line',
						help: 'Space wil be left for an extra line for the last line to wrap instead of shrinking to fit',
						defaultValue: !!layout.name.overflowLabel
					});
				}

				staffOrderSettings.push({
					name: 'teacherPrefixOrder',
					type: 'text',
					description: 'Staff Prefix Line 1',
					placeholder: '%Prefix% %Last Name%',
					customRule: ''
				});

				if(layout.cell && layout.cell.name) {
					staffOrderSettings.push({
						name: 'teacherPrefixOrder2',
						type: 'text',
						description: 'Staff Prefix Line 2',
						placeholder: '%title%',
						customRule: ''
					});
					staffOrderSettings.push({
						name: 'teacherPrefixOrder3',
						type: 'text',
						description: 'Staff Prefix Line 3',
						placeholder: '%title%',
						customRule: ''
					});
				}
			}

			var form = $('<div class="ui form">');
			var settings = [];
			if(orderSettings.length) {
				settings.push({
					name: 'name',
					description: 'Name Display',
					type: 'section',
					value: layout.name,
					group: 'name',
					help: helpPopup,
					settings: orderSettings,
					perLine: 3,
					customRule: ''
				});
			}
			if(staffOrderSettings.length) {
				settings.push({
					name: 'name',
					description: 'Staff Prefix Display',
					type: 'section',
					value: layout.name,
					group: 'name',
					help: 'When a staff member has a Prefix defined, display them in this format.  Leave blank to disable.',
					settings: staffOrderSettings
				});
			}
			settings.push({
				name: 'cell',
				description: 'Cell Settings',
				type: 'section',
				value: layout.cell,
				group: 'cell',
				settings: [
					{
						name: 'padding',
						type: 'positiveFloat',
						description: 'Base Padding',
						help: 'More padding will automatically be added to make cells take up the entire page',
						range: [0, 0.5]
					},
					{
						name: 'ratio',
						type: 'positiveFloat',
						description: 'Aspect Ratio',
						range: [0.1, 2]
					}
				]
			});

			if(layout.cell && layout.cell.size === 'fit') {
				settings.push({
					name: 'cell',
					type: 'section',
					value: layout.cell,
					group: 'cell',
					settings: [
						{
							name: 'distributeSpacingOutsideLayout',
							type: 'checkbox',
							description: 'Distribute extra space to outside margins',
							help: 'If on, extra space will be on the outside.  If off (default), extra space will be put between cells evenly',
							value: !!layout.cell.distributeSpacingOutsideLayout
						}
					]
				});
			}

			var maxMargins = $.MAX_PAGE_MARGIN;
			let page = this.getPage();
			if(page.getMaxMargins) {
				maxMargins = page.getMaxMargins();
			}

			var startMargins = $.extend(true, {}, page.getPageMargins() || {});
			settings.push({
				name: 'margins',
				description: 'Margins',
				type: 'section',
				group: 'margins',
				value: startMargins,
				perLine: 4,
				settings: [
					{
						name: 'gutter',
						type: 'positiveFloat',
						description: 'Left Margin',
						range: [0, maxMargins],
						value: startMargins.gutter || 0
					},
					{
						name: 'outside',
						type: 'positiveFloat',
						description: 'Right Margin',
						range: [0, maxMargins],
						value: startMargins.outside || 0
					},
					{
						name: 'top',
						type: 'positiveFloat',
						description: 'Top Margin',
						range: [0, maxMargins],
						value: startMargins.top || 0
					},
					{
						name: 'bottom',
						type: 'positiveFloat',
						description: 'Bottom Margin',
						range: [0, maxMargins],
						value: startMargins.bottom || 0
					}
				]
			});


			if($.projectSettings) {
				var projectSettings = [];

				if($.projectSettings.renameGradeToWord) {
					projectSettings.push($.projectSettings.renameGradeToWord);
				}
				if($.projectSettings.centerLastRow) {
					projectSettings.push($.projectSettings.centerLastRow);
				}

				if($.projectSettings.contentOverlapsSubjectLabels) {
					projectSettings.push($.projectSettings.contentOverlapsSubjectLabels);
				}
				if($.projectSettings.moveSubjectsToKeepRowsEven) {
					projectSettings.push($.projectSettings.moveSubjectsToKeepRowsEven);
				}
				if($.projectSettings.moveSubjectsToKeepBottomRowFilled) {
					projectSettings.push($.projectSettings.moveSubjectsToKeepBottomRowFilled);
				}

				if($.getProjectSetting('shrinkCellWhitespace') && $.projectSettings.maxShrinkCellWhitespace) {
					projectSettings.push($.projectSettings.maxShrinkCellWhitespace);
				}

				if(projectSettings.length) {
					settings.push({
						name: 'other',
						description: 'Other Settings',
						type: 'section',
						settings: projectSettings
					});
				}
			}

			var settingsForm = form.SettingsBuilder(settings, {
				onSubmit: function() {
					modal.find('.primary.button').click();
				}
			});
			content.append(form);

			var me = this;
			return modal.modal({
				onApprove: function () {
					if (!settingsForm.formDOM.form('validate form')) {
						return false;
					}
					var changes = settingsForm.getChanges();

					if (changes.length) {
						settingsForm.applyChanges(changes);

						// Update layout
						var updateLayout = false;
						for (let i in settingsForm.currentSettings) {
							if (isNaN(i) && i !== 'margins') {
								layout[i] = settingsForm.currentSettings[i];
								updateLayout = true;
							}
						}
						if(layout.name) {
							let overflowLabel = null;
							if(layout.name.overflow) {
								if(layout.name.order3) {
									overflowLabel = 'wrapLines4';
								} else if(layout.name.order2) {
									overflowLabel = 'wrapLines3';
								} else {
									overflowLabel = 'wrap';
								}
							}
							layout.name.overflowLabel = overflowLabel;

							delete layout.name.overflow;
						}

						var hasMarginChanges = changes.filter(function(change) {
							return change.group === 'margins';
						}).length > 0;
						if(hasMarginChanges && settingsForm.currentSettings.margins) {
							me.setPageMargins(settingsForm.currentSettings.margins, true);
						}

						if (updateLayout) {
							var pageSet = me.getPageSet();
							if (pageSet.setLayout) {
								pageSet.setLayout(layout, true);
							} else {
								let page = me.getPage();
								page.setLayout(layout, true);
							}
						}

						// Update project settings
						var projectSettings = [];
						for (let i = 0; i < changes.length; i++) {
							var change = changes[i];
							if (!isNaN(change.id)) {
								projectSettings.push(change);
							}
						}

						if (projectSettings.length) {
							$.ajax({
								url: 'ajax/saveProjectSettings.php',
								type: 'POST',
								data: {
									jobId: $.getGETParams().jobId,
									settings: projectSettings
								},
								error: function () {
									$.Alert('Error', 'Failed to update project settings');
								}
							});
						}
						me.setLayout(layout);
					}
				},
				onHidden: function () {
					$(this).remove();
				},
				closable: false
			}).modal('show');
		},
		getSubjectEffects: function() {
			let page = this.getPage();
			if (!page || !page.getKids) {
				return;
			}
			if (page.getRootPage && page.getRootPage()) {
				page = page.getRootPage();
			}

			return page.getExtraProperty('subjectEffects', {});
		},
		setSubjectEffects: function(names, values) {
			var startEffectsSettings = this.getSubjectEffects();
			var effectsSettings = $.extend(true, {}, startEffectsSettings);
			for(let i = 0; i < names.length; i++) {
				effectsSettings[names[i]] = values[i];
			}

			let page = this.getPage();
			if(page.getRootPage) {
				page = page.getRootPage();
			}
			page.setExtraProperty('subjectEffects', effectsSettings);

			// Update layout.  If this returns a layout we should refresh the page
			let layout = page.getLayout();
			if(layout) {
				layout = this.updateLayoutAfterEffects(page.getLayout(), startEffectsSettings, effectsSettings);
				if (layout) {
					page.setLayout(layout);
					this.updateCellDefinition(layout);
				} else {
					this.applySubjectEffects(effectsSettings, true);
				}
			} else {
				this.applySubjectEffects(effectsSettings);
			}

			if(names.includes('cropSelection')) {
				this.canvas.updateCropSelection();
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.updateCropSelection();
				});
			}
		},
		updateLayoutAfterEffects: function(layout, oldEffectsSettings, newEffectsSettings) {
			// Drop shadow needs leading space it to not run over the labels
			if(layout.cell && layout.name && layout.cell.name === 'bottom') {
				layout = $.extend(true, {}, layout);
				if(!layout.primaryPose) {
					layout.primaryPose = {};
				}

				// Add padding
				var changes = false;
				if(newEffectsSettings && newEffectsSettings.dropShadow && $.getStudioSetting('addDropShadowPadding', true)) {
					if(newEffectsSettings.dropShadow.sizeScaling) {
						// Drop-shadow is calculated as `depth * maxSize / 200`, but we need a little extra space for fuzzy drop-shadow
						var maxSize = Math.max(layout.cell.width, layout.cell.height);
						layout.primaryPose.bottomPadding = newEffectsSettings.dropShadow.depth * maxSize / 160;
					} else {
						// Drop-shadow is calculated as `depth / 60`, but we need a little extra space for fuzzy drop-shadow
						layout.primaryPose.bottomPadding = newEffectsSettings.dropShadow.depth / 40;
					}
					changes = true;
				}
				// Delete old padding
				else if(oldEffectsSettings && oldEffectsSettings.dropShadow) {
					delete layout.primaryPose.bottomPadding;

					if($.getObjectCount(layout.primaryPose) == 0) {
						delete layout.primaryPose;
					}

					changes = true;
				}

				if(changes) {
					return layout;
				}
			}

			return false;
		},
		applySubjectEffects: function(effectsSettings, propogate) {
			if(!effectsSettings) {
				effectsSettings = this.getSubjectEffects();
			}

			if(effectsSettings) {
				this.canvas.applySubjectEffects(effectsSettings);
				this.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.applySubjectEffects(effectsSettings);
				});
			}

			if(propogate) {
				if(this.childLayout) {
					this.childLayout.applySubjectEffects(effectsSettings);
				} else if(this.parentLayout) {
					this.parentLayout.applySubjectEffects(effectsSettings);
				}
			}
		},
		onConsumePageEvent: function(event) {
			var updatePageLabels = false;
			let page = this.getPage();

			if(event.context.length == 2) {
				// Entire page was updated
				page = this.parent.getCurrentPageForLayout(this);
				this.setPage(page);
			} else {
				if (event.context[2] == 'theme' || (event.context[2] == 'extras' && event.context[3] == this.getBackgroundSettingKey())) {
					this.updateTheme();
				} else if (event.context[2] == 'extras') {
					if (event.context[3] == 'freeMovementLocked') {
						this.updateMovementLocked();
						updatePageLabels = true;
					} else if (event.context[3] == 'subjectEffects') {
						this.applySubjectEffects();
						this.canvas.updateCropSelection();
						this.extraSubjectGrids.forEach(function(subjectGrid) {
							subjectGrid.updateCropSelection();
						});
						if(this.childLayout) {
							this.childLayout.applySubjectEffects();
							this.childLayout.canvas.updateCropSelection();
							this.childLayout.extraSubjectGrids.forEach(function(subjectGrid) {
								subjectGrid.updateCropSelection();
							});
						}
					} else if(event.context[3] == 'pageMargins') {
						this.updatePageMargins();
					} else if(event.context[3] == 'labelStyle') {
						var styles = event.args[1];
						if(styles['font-size']) {
							this.refreshLockedTexts(true);
						} else {
							this.setLockedTextStyles('label', event.args[1], true);
						}
					} else if(event.context[3] == 'indexHeaderStyle') {
						this.setLockedTextStyles('indexHeader', event.args[1], true);
					} else if(event.context.length === 3) {
						this.refreshPage();
					}
				} else if (event.context[2] == 'locked') {
					this.setEditable(true, page.locked);
					updatePageLabels = true;
				} else if (event.context[2] == 'layout') {
					if (event.context.length == 5 && event.context[4] == 'mask') {
						var css = $.extend(true, {}, event.args[1]);
						for(var name in css) {
							if(css[name] === null) {
								css[name] = '';
							}
						}

						$(this).find('.flowCell').find('.imageInnerStyleWrapper').css(css);
					} else {
						this.parent.updatePagesAfterChangeImmediately(page);
					}
				} else if (['extraClasses', 'batchId', 'largeCellPosition', 'extraTitles', 'classObj', 'subjects'].indexOf(event.context[2]) != -1) {
					if(event.context[2] === 'largeCellPosition' && page.layout && page.layout.largeCell) {
						if(event.args[1] && typeof event.args[1].col !== 'undefined') {
							page.layout.largeCell.col = event.args[1].col || 0;
						}

						if(event.args[1] && typeof event.args[1].row !== 'undefined') {
							page.layout.largeCell.row = event.args[1].row || 0;
						}
					}

					this.addUsers(null, false);
				} else if (event.context[2] == 'classObj') {
					if (event.context.length == 4 && event.context[3] == 'subjects') {
						this.addUsers(null, false);
					}
				} else if(event.context[2] == 'studentLabelCSS') {
					if(event.args.length === 2 && event.args[0] && typeof event.args[0]['font-size-multiplier'] !== 'undefined') {
						this.updateCellDefinition();
					} else {
						this.updateLabelCSS(true);
					}
				} else if(event.context[2] == 'candids') {
					if(event.context.length == 3) {
						this.refreshFrames();
					} else {
						let frame = this.refreshFrame(event.context[3]);
						if(frame) {
							this.onFlowStop(frame);
						}
					}
					// When undoing events, other elements we are setting the border against might not be done yet
					$.setSingleTimeout.call(this, 'debounceApplyGroupBorder', () => {
						this.postAddedContent();
					}, 1);
				} else if(event.context[2] == 'texts') {
					if(event.context.length == 3) {
						this.refreshTexts();
					} else {
						let text = this.refreshText(event.context[3]);
						if(text) {
							this.onFlowStop(text);
						}
					}
					// When undoing events, other elements we are setting the border against might not be done yet
					$.setSingleTimeout.call(this, 'debounceApplyGroupBorder', () => {
						this.postAddedContent();
					}, 1);
				} else if(event.context[2] == 'title') {
					this.refreshTitle();
				} else if(event.context[2] == 'subjectCellData' && event.context.length == 4) {
					var objPropsToHandle = $.extend(true, {}, event.args[0], event.args[1]);
					for(var id in objPropsToHandle) {
						if(event.context[3] == 'global') {
							this.updateCellTextCSS(id, true);
						} else {
							this.updateCellDisplay(id, event.context[3], true);
						}
					}
				} else if(event.context[2] == 'comments') {
					if(this.commentFeed) {
						this.commentFeed.setComments(page.getComments());
					}
				} else if(event.context[2] === 'pageText') {
					this.refreshLockedTexts(true);
				} else if(event.context[2] === 'pageNumberCSS') {
					this.updatePageNumber();
				}

				if (updatePageLabels && page.updatePageLabel) {
					page.updatePageLabel();
					this.setLabel(page.getPageLabel());
				}
			}
		},
		onConsumeSubjectEvent: function(event) {
			if(event.context.length > 1) {
				var matchedCell = $(this.canvas).find('.flowCell:not(.rowHeader)').filter(function() {
					if(this.user && this.user.id == event.context[1]) {
						return true;
					} else {
						return false;
					}
				})[0];

				if(matchedCell) {
					if(event.context.length == 2) {
						matchedCell.setUser(matchedCell.user, true);
					} else if(event.context.length > 2) {
						if(event.context[2] == 'yearbookPhoto') {
							if(event.context.length == 3) {
								if($.isArray(event.args) && event.args.length == 2 && event.args[1].version_id) {
									matchedCell.user.photoCdnUrl = null;
									matchedCell.user.yearbookPhoto.cdn_url = null;
									matchedCell.user.yearbookPhoto.version_id = event.args[1].version_id;
									matchedCell.user.yearbookPhoto.version_ids = event.args[1].version_ids;
									matchedCell.refreshPhotoVersion = true;
								}

								matchedCell.setUser(matchedCell.user, true);
							} else if(event.context.length >= 4 && event.context[3] == 'yearbookCrop') {
								matchedCell.setUserCrop(matchedCell.user);
							}
						}
					}
				}
			}
		},
		getDynamicSubjectFields: function() {
			// Class specific types
			var subjectFields = ['Teacher', 'Grade', 'Prefix'];
			var pageSet = this.getPageSet();
			if(pageSet && pageSet.getTemplateFields) {
				var templateFields = pageSet.getTemplateFields();
				if(templateFields && templateFields.length) {
					subjectFields = [];
					for(let i = 0; i < templateFields.length; i++) {
						subjectFields.push(templateFields[i]);
					}
				}

				var labelMap = pageSet.getTemplateLabelMap();
				if(labelMap) {
					for(var label in labelMap) {
						if(label && label !== 'null') {
							subjectFields.push(label);
						}
					}
				}

				if(subjectFields.indexOf('First Name') !== -1) {
					subjectFields.push('First Name Initial');
				}
				if(subjectFields.indexOf('Last Name') !== -1) {
					subjectFields.push('Last Name Initial');
				}
				if(subjectFields.indexOf('Grade') !== -1) {
					subjectFields.push('Grade Word');
					subjectFields.push('Grade Ordinal');
				}
			}
			
			subjectFields.removeItem('photo_assignment');
			return subjectFields;
		},
		getDynamicOrderFields: function(allFields) {
			let subjects = this.getSubjects()?.filter(s => !!s);
			if((subjects && subjects.length && subjects[0].orders) || allFields) {
				if(!subjects) {
					subjects = [];
				}
				
				var unverifiedProperties = [];
				var orderOptions = [];
				subjects.forEach(function(subject) {
					(subject?.orders || []).forEach(function(order) {
						Object.keys(order.unverified_properties || {}).forEach(function(key) {
							var field = 'Order Property ' + key;
							if(!unverifiedProperties.includes(field)) {
								unverifiedProperties.push(field);
							}
						});

						(order.ordered_options || []).forEach(function(orderOption) {
							var field = 'Order Option ' + orderOption.group_id;
							if(!orderOptions.includes(field)) {
								orderOptions.push(field);
							}
						});
						order.ordered_packages.forEach(function(orderedPackage) {
							(orderedPackage.ordered_options || []).forEach(function(orderOption) {
								var field = 'Order Option ' + orderOption.group_id;
								if(!orderOptions.includes(field)) {
									orderOptions.push(field);
								}
							});

							orderedPackage.ordered_products.forEach(function(orderedProduct) {
								(orderedProduct.ordered_options || []).forEach(function(orderOption) {
									var field = 'Order Option ' + orderOption.group_id;
									if(!orderOptions.includes(field)) {
										orderOptions.push(field);
										orderOptions.push('Order OptionX ' + orderOption.group_id);
									}
								});
							});
						});
					});
				});
				var extraFields = $.merge([], unverifiedProperties);
				$.merge(extraFields, orderOptions);

				return $.merge([
					'Order Number',
					'Order #',
					'Order External ID',
					'Order Batch Number',
					'Order Batch #',
					'ImageQuix Order ID',
					'ImageQuix Gallery Order ID',
					'ImageQuix Subject ID',
					'ImageQuix Ref Service Ids',
					'Order State',
					'Order Direct Shipping',
					'Order Shipping Recipient',
					'Order Shipping Address',
					'Order Shipping Line 1',
					'Order Shipping Line 2',
					'Order Shipping City',
					'Order Shipping State',
					'Order Shipping Zip Code',
					'Order Shipping Zipcode',
					'Order Shipping Country',
					'Order Direct Shipping Recipient',
					'Order Direct Shipping Address',
					'Order Direct Shipping Line 1',
					'Order Direct Shipping Line 2',
					'Order Direct Shipping City',
					'Order Direct Shipping State',
					'Order Direct Shipping Zip Code',
					'Order Direct Shipping Zipcode',
					'Order Direct Shipping Country',
					'Order Billing Recipient',
					'Order Billing Address',
					'Order Billing Line 1',
					'Order Billing Line 2',
					'Order Billing City',
					'Order Billing State',
					'Order Billing Zip Code',
					'Order Billing Zipcode',
					'Order Billing Country',
					'Order Date',
					'Long Order Date',
					'Short Order Date',
					'Order Time',
					'Order Submitted Date',
					'Long Order Submitted Date',
					'Short Order Submitted Date',
					'Order Submitted Time',
					'Order Packages',
					'Order Package SKUs',
					'Order Package Names',
					'Order Package Descriptions',
					'Order Package SKU',
					'Order Package Name',
					'Order Package Description',
					'Order Products',
					'Order Product SKUs',
					'Order Product Descriptions',
					'Order Product Names',
					'Order Product Types',
					'Order Product SKU',
					'Order Product Description',
					'Order Product Name',
					'Order Product Type',
					'Order Layout Names',
					'Order Product Names/Layout Names',
					'Order Product Descriptions/Layout Names',
					'Order Payment Type',
					'Order Price',
					'Order Subtotal',
					'Order Taxes',
					'Order Shipping',
					'Order Discount',
					'Order Total',
					'Order Payment',
					'Order Comments',
					'Order Customer Service Flagged',
					'Order Customer Service Notes',
					'Ordered Options',
					'Order All Options',
					'Order Package Options',
					'Order Product Options',
					'Order Photo Options',
					'Order Digital Delivery Email',
					'Order Digital Delivery Phone Number',
					'Order Background Name',
					'Order Background Names'
				], extraFields);
			} else {
				return [];
			}
		},
		getDynamicFields: function(allFields) {
			// Class specific types
			var templateFields = this.getDynamicSubjectFields();

			var staticFieldNames = [];
			for(var id in $.dynamicStaticGlobalVariables) {
				staticFieldNames.push(id);
			}
			$.merge(staticFieldNames, $.dynamicGlobalVariables);
			
			return $.merge(
				$.merge(this.getDynamicOrderFields(allFields), templateFields),
				// Globals
				$.merge(['Batch', 'Project', 'Project ID', 'Encoded Project ID',
					'Photography Date', 'Long Photography Date', 'Short Photography Date', 'Photography Time',
					'Organization', 'School', 'School Name', 'Studio', 'Lab', 'Page Number', 'Page', 'Pages', 'Sequence Number',
					'Pose Name', 'Primary Pose Name', 'Primary Pose Number', 'Yearbook Pose Name', 'Yearbook Pose Number', 'Group Photo Name', 'Ordered Photo Name', 'Group By', 'Group Count'], staticFieldNames)
			);
		},
		getDynamicFieldRep: function(field, subjectIndex, options) {
			options = $.extend({
				allowBlank: true
			}, options);

			let page = wrapper.getPage();
			let dynamicSubjectFields = this.getDynamicSubjectFields();
			let matchingSubjectFieldIndex = dynamicSubjectFields.map(field => field.toLowerCase()).indexOf(field.toLowerCase());

			// Run order field check first but if null (ie: no order option) then fallback to subject properties if the field matches a subject property as well
			if(this.getDynamicOrderFields().includes(field)) {
				let orderFieldRep = this.getDynamicOrderFieldRep(page, field, subjectIndex, options);
				if(orderFieldRep || matchingSubjectFieldIndex === -1) {
					return orderFieldRep;
				}
			}

			if(matchingSubjectFieldIndex !== -1 && page.getFieldRep) {
				if(!$.isPlainObject(subjectIndex) && (!page.getSubjects || !page.getSubjects())) {
					return null;
				}
				// We usually have the correct case already but if someone does the wrong case for one of our hard coded order fields it won't match
				// ie: template field of "order option nickname" but there is an order option for nickname so we are searching for "Order Option Nickname" instead of lower case version
				field = dynamicSubjectFields[matchingSubjectFieldIndex] ?? field;

				var dataField = field;
				if(['First Name Initial', 'Last Name Initial'].indexOf(dataField) !== -1) {
					dataField = dataField.replace(' Initial', '');
				} else if(dataField === 'Grade Word' || dataField === 'Grade Ordinal') {
					dataField = 'Grade';
				}

				let primarySubject;
				if($.isInit(subjectIndex)) {
					if($.isPlainObject(subjectIndex)) {
						primarySubject = subjectIndex;
					} else {
						primarySubject = this.getSubjectAtIndex(subjectIndex);

						// If we don't have the requested subject it should be no-op
						if(!primarySubject) {
							return null;
						}
					}
				} else if(this.definition && this.definition.primarySubjectPoseFrame) {
					primarySubject = this.getLargeCellSubject();
				} else {
					primarySubject = this.getPrimarySubject();
				}
				var fieldRep = page.getFieldRep(dataField, primarySubject);
				if(!$.isInit(fieldRep) && this.getPageSet()) {
					var labelMap = this.getPageSet().getTemplateLabelMap();
					if(labelMap[field]) {
						dataField = labelMap[field];
						fieldRep = page.getFieldRep(dataField, primarySubject);
					}
				}
				if(!fieldRep) {
					if(this.editable && options.allowBlank) {
						fieldRep = 'Blank';
					} else {
						fieldRep = '';
					}
				}

				if(dataField == 'Grade' && $.getProjectSetting('renameGradeToWord')) {
					let gradeValue = $.SubjectManagement.getGradeWordDisplay(fieldRep);
					if(gradeValue) {
						return gradeValue;
					}
				} else if(fieldRep && fieldRep.length > 0 && fieldRep !== 'Blank') {
					if(['First Name Initial', 'Last Name Initial'].indexOf(field) !== -1) {
						return fieldRep[0];
					} else if(field === 'Grade Word') {
						let gradeValue = $.SubjectManagement.getGradeWordDisplay(fieldRep);
						if(gradeValue) {
							return gradeValue;
						}
					} else if(field === 'Grade Ordinal') {
						let gradeValue = $.SubjectManagement.getGradeOrdinalDisplay(fieldRep);
						if(gradeValue) {
							return gradeValue;
						}
					}
				}

				return fieldRep;
			} else if(field == 'Batch') {
				if(page && page.getClass && page.getClass()) {
					return page.getClass().name;
				} else {
					return null;
				}
			} else if(field == 'Project') {
				return $.ProjectName;
			} else if(['Photography Date', 'Long Photography Date', 'Short Photography Date', 'Photography Time'].indexOf(field) !== -1 && $.projectData && $.projectData.photography_date) {
				if(field == 'Photography Date' || field == 'Long Photography Date') {
					return moment($.projectData.photography_date).format(LONG_DATE_FORMAT);
				} else if(field == 'Short Photography Date') {
					return moment($.projectData.photography_date).format(SHORT_DATE_FORMAT);
				} else if(field == 'Photography Time') {
					return moment($.projectData.photography_date).format(TIME_FORMAT);
				}
			} else if(field == 'Project ID') {
				return $.PlicProjectId;
			} else if(field == 'Encoded Project ID' && window.ascii85) {
				if($.PlicProjectId) {
					return window.ascii85.fromByteArray($.convertHexToBytes($.PlicProjectId.replace(/-/g, '')), false);
				} else {
					return null;
				}
			} else if(field == 'Organization' || field == 'School Name' || field === 'School') {
				return $.OrgName;
			} else if(field == 'Studio') {
				return $.studioSettingOrgs[0].orgName;
			} else if(field == 'Lab') {
				return $.studioSettingOrgs[$.studioSettingOrgs.length - 1].orgName;
			} else if($.dynamicGlobalVariables.indexOf(field) !== -1) {
				return $.getDynamicGlobalVariables(field);
			} else if($.isInit($.dynamicStaticGlobalVariables[field])) {
				return $.dynamicStaticGlobalVariables[field];
			} else if(field == 'Page Number' || field == 'Page') {
				return this.page.pageNumber - $.PAGE_OFFSET;
			} else if(field == 'Pages') {
				var pageSet = this.getPageSet();
				if(pageSet) {
					if(pageSet.getTotalPagesStub) {
						return pageSet.getTotalPagesStub() - $.PAGE_OFFSET;
					} else {
						return pageSet.getTotalPages() - $.PAGE_OFFSET;
					}
				} else {
					return 1;
				}
			} else if(field == 'Sequence Number') {
				let primarySubject;
				if($.isInit(subjectIndex)) {
					if($.isPlainObject(subjectIndex)) {
						primarySubject = subjectIndex;
					} else {
						primarySubject = this.getSubjectAtIndex(subjectIndex);

						// If we don't have the requested subject it should be no-op
						if(!primarySubject) {
							return null;
						}
					}
				} else {
					primarySubject = this.getPrimarySubject();
				}

				if(primarySubject) {
					if(primarySubject.sequenceNumber) {
						return primarySubject.sequenceNumber;
					} else {
						return this.getSubjects().indexOf(primarySubject) + 1;
					}
				} else {
					return '1';
				}
			} else if(field == 'Group By') {
				if(!page.getSubjects) {
					return null;
				}
				let subjects = this.getSubjects();
				if(!subjects) {
					return null;
				}

				var groupBy = this.getGroupByParam();
				if(groupBy) {
					var values = [];
					groupBy.forEach(function(field) {
						var fieldRep = page.getFieldRep(field);

						if(!fieldRep) {
							if(this.editable && options.allowBlank) {
								fieldRep = 'Blank';
							} else {
								fieldRep = '';
							}
						}

						values.push(fieldRep);
					});

					return values.join(', ');
				}
			} else if(field == 'Group Count') {
				if(!page.getSubjects) {
					return '0';
				}
				let subjects = this.getSubjects()?.filter(s => !!s);
				if(!subjects) {
					return '0';
				}

				if(subjects.length && subjects[0].groupCount) {
					return subjects[0].groupCount;
				} else {
					return subjects.length;
				}
			} else if(field.includes('Pose Name') || field.includes('Ordered Photo Name') || field.includes('Group Photo Name')) {
				if(!$.isPlainObject(subjectIndex) && !page.getSubjects()) {
					return null;
				}

				let primarySubject;
				if($.isInit(subjectIndex)) {
					if($.isPlainObject(subjectIndex)) {
						primarySubject = subjectIndex;
					} else {
						primarySubject = this.getSubjectAtIndex(subjectIndex);
					}
				} else {
					primarySubject = this.getPrimarySubject() || this.getFirstSubject();
				}
				if(!primarySubject) {
					return null;
				}

				// We pass in %pose name2% as subjectIndex of 1
				var poseIndex = options.secondaryIndex || 0;
				var poseSelection = 'pose ' + (poseIndex + 1);
				if(field === 'Primary Pose Name') {
					poseSelection = 'primary pose';
				} else if(field === 'Yearbook Pose Name') {
					poseSelection = 'yearbook pose';
				} else if(field === 'Ordered Photo Name') {
					poseSelection = 'ordered photo ' + (poseIndex + 1);
				} else if(field === 'Group Photo Name') {
					poseSelection = 'group photo ' + (poseIndex + 1);
				}

				var pose = $.FlowLayoutFrameUtils.getSubjectPose({
					poseSelection: poseSelection
				}, primarySubject);
				if(!pose) {
					return '';
				}

				return pose.upload_file_name;
			} else if(field === 'Primary Pose Number' || field === 'Yearbook Pose Number') {
				if(!$.isPlainObject(subjectIndex) && !page.getSubjects()) {
					return null;
				}

				let primarySubject;
				if($.isInit(subjectIndex)) {
					if($.isPlainObject(subjectIndex)) {
						primarySubject = subjectIndex;
					} else {
						primarySubject = this.getSubjectAtIndex(subjectIndex);
					}
				} else {
					primarySubject = this.getPrimarySubject() || this.getFirstSubject();
				}
				if(!primarySubject || !primarySubject.photos) {
					return null;
				}

				let poseSelection = 'primary pose';
				if(field === 'Yearbook Pose Number') {
					poseSelection = 'yearbook pose';
				}

				let pose = $.FlowLayoutFrameUtils.getSubjectPose({
					poseSelection
				}, primarySubject);
				if(!pose) {
					return null;
				}

				return primarySubject.photos.filter(photo => {
					return photo.category === 'individual_category' || photo.id === pose.id;
				}).indexOfMatch(pose, 'id') + 1;
			} else {
				return null;
			}
		},
		getDynamicOrderFieldRep: function(page, field, subjectIndex, options) {
			if(!$.isPlainObject(subjectIndex) && !page.getSubjects()) {
				return null;
			}

			let primarySubject;
			if($.isInit(subjectIndex)) {
				if($.isPlainObject(subjectIndex)) {
					primarySubject = subjectIndex;
				} else {
					primarySubject = this.getSubjectAtIndex(subjectIndex);
				}
			} else {
				primarySubject = this.getPrimarySubject() || this.getFirstSubject();
			}
			if(!primarySubject || !primarySubject.orders || !primarySubject.orders.length) {
				return null;
			}

			var scopeToPackage = !['Order Background Names'].includes(field);
			var scopeToProduct = scopeToPackage && !field.includes('Order OptionX ');

			var order = primarySubject.orders[0];
			if($.OrderedId) {
				order = primarySubject.orders.find(order => {
					return order.id === $.OrderedId;
				});
			}

			var orderedPackages = order.ordered_packages;
			if($.OrderedProductId && scopeToPackage) {
				orderedPackages = orderedPackages.filter(function(orderedPackage) {
					return !!orderedPackage.ordered_products.find(function(orderedProduct) {
						return orderedProduct.id === $.OrderedProductId;
					});
				});
			}
			var orderedProducts = orderedPackages.reduce(function(orderedProducts, orderedPackage) {
				return $.merge(orderedProducts, orderedPackage.ordered_products || []);
			}, []);
			if($.OrderedProductId && scopeToProduct) {
				orderedProducts = orderedProducts.filter(function(orderedProduct) {
					return orderedProduct.id === $.OrderedProductId;
				});
			}
			var orderedPhotos = orderedProducts.reduce(function(orderedPhotos, orderedProduct) {
				return $.merge(orderedPhotos, orderedProduct.ordered_photos || []);
			}, []);

			if(field.includes('Order Property')) {
				var unverifiedPropertyName = field.replace('Order Property ', '');
				return (order.unverified_properties || {})[unverifiedPropertyName] || '';
			} else if(field.includes('Order Option ') || field.includes('Order OptionX ')) {
				var groupId = field.replace('Order Option ', '').replace('Order OptionX ', '');
				var i, j;
				
				var orderedPhotoIds = orderedPhotos.map(function(orderedPhoto) {
					return orderedPhoto.photo_id
				});
				for(i = 0; i < (order.ordered_options || []).length; i++) {
					let orderOption = order.ordered_options[i];
					if(orderOption.group_id === groupId && (orderOption.option_type !== 'photo' || orderedPhotoIds.includes(orderOption.photo_id))) {
						return orderOption.choice_id;
					}
				}

				for(i = 0; i < orderedPackages.length; i++) {
					let orderedPackage = orderedPackages[i];
					for(j = 0; j < (orderedPackage.ordered_options || []).length; j++) {
						let orderOption = orderedPackage.ordered_options[j];
						if(orderOption.group_id === groupId) {
							return orderOption.choice_id;
						}
					}
				}
				for(i = 0; i < orderedProducts.length; i++) {
					let orderedProduct = orderedProducts[i];
					for(j = 0; j < (orderedProduct.ordered_options || []).length; j++) {
						let orderOption = orderedProduct.ordered_options[j];
						if(orderOption.group_id === groupId) {
							return orderOption.choice_id;
						}
					}
				}

				return '';
			}

			switch(field) {
				case 'Order #': case 'Order Number':
					return order.studio_order_number;
				case 'Order Batch #': case 'Order Batch Number':
					return order.studio_order_batch_number || '';
				case 'Order External ID':
					return order.external_id || '';
				case 'ImageQuix Order ID':
					return order.imagequix_order_number_id || '';
				case 'ImageQuix Gallery Order ID':
					return order.imagequix_gallery_order_id || '';
				case 'ImageQuix Subject ID':
					return order.imagequix_subject_id || '';
				case 'ImageQuix Ref Service Ids':
					return order.ordered_packages.reduce(function(serviceIds, orderedPackage) {
						orderedPackage.ordered_products.forEach(function(orderedProduct) {
							if(orderedProduct.imagequix_ref_service_order_id && !serviceIds.includes(orderedProduct.imagequix_ref_service_order_id)) {
								serviceIds.push(orderedProduct.imagequix_ref_service_order_id);
							}
						});

						return serviceIds;
					}, []);
				case 'Order State':
					var state = order.state.toUpperWordCase();
					if(order.cancel_reason) {
						state += ' (' + order.cancel_reason.toUpperWordCase() + ')';
					}

					return state;
				case 'Order Direct Shipping':
					return order.shipping_direct ? 'Yes' : 'No';
				case 'Order Shipping Recipient':
					if(order.shipping_address) {
						return order.shipping_address.recipient
					} else {
						return '';
					}
				case 'Order Shipping Address':
					if(order.shipping_address) {
						let address = order.shipping_address.street_line1;
						if(order.shipping_address.street_line2) {
							address += ' ' + order.shipping_address.street_line2;
						}

						address += ' ' + order.shipping_address.locality + ', ' + order.shipping_address.region +
							' ' + order.shipping_address.postal_code;

						return address;
					} else {
						return 'No shipping address';
					}
				case 'Order Shipping Line 1':
					if(order.shipping_address) {
						return order.shipping_address.street_line1
					} else {
						return '';
					}
				case 'Order Shipping Line 2':
					if(order.shipping_address) {
						return order.shipping_address.street_line2
					} else {
						return '';
					}
				case 'Order Shipping City':
					if(order.shipping_address) {
						return order.shipping_address.locality
					} else {
						return '';
					}
				case 'Order Shipping State':
					if(order.shipping_address) {
						return order.shipping_address.region
					} else {
						return '';
					}
				case 'Order Shipping Zipcode': case 'Order Shipping Zip Code':
					if(order.shipping_address) {
						return order.shipping_address.postal_code
					} else {
						return '';
					}
				case 'Order Shipping Country':
					if(order.shipping_address) {
						return order.shipping_address.country
					} else {
						return '';
					}
				case 'Order Direct Shipping Recipient':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.recipient
					} else {
						return '';
					}
				case 'Order Direct Shipping Address':
					if(order.direct_shipping_address) {
						let address = order.direct_shipping_address.street_line1;
						if(order.direct_shipping_address.street_line2) {
							address += ' ' + order.direct_shipping_address.street_line2;
						}

						address += ' ' + order.direct_shipping_address.locality + ', ' + order.direct_shipping_address.region +
							' ' + order.direct_shipping_address.postal_code;

						return address;
					} else {
						return 'No direct shipping address';
					}
				case 'Order Direct Shipping Line 1':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.street_line1
					} else {
						return '';
					}
				case 'Order Direct Shipping Line 2':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.street_line2
					} else {
						return '';
					}
				case 'Order Direct Shipping City':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.locality
					} else {
						return '';
					}
				case 'Order Direct Shipping State':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.region
					} else {
						return '';
					}
				case 'Order Direct Shipping Zipcode': case 'Order Direct Shipping Zip Code':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.postal_code
					} else {
						return '';
					}
				case 'Order Direct Shipping Country':
					if(order.direct_shipping_address) {
						return order.direct_shipping_address.country
					} else {
						return '';
					}
				case 'Order Billing Recipient':
					if(order.billing_address) {
						return order.billing_address.recipient
					} else {
						return '';
					}
				case 'Order Billing Address':
					if(order.billing_address) {
						let address = order.billing_address.street_line1;
						if(order.billing_address.street_line2) {
							address += ' ' + order.billing_address.street_line2;
						}

						address += ' ' + order.billing_address.locality + ', ' + order.billing_address.region +
							' ' + order.billing_address.postal_code;

						return address;
					} else {
						return 'No billing address';
					}
				case 'Order Billing Line 1':
					if(order.billing_address) {
						return order.billing_address.street_line1
					} else {
						return '';
					}
				case 'Order Billing Line 2':
					if(order.billing_address) {
						return order.billing_address.street_line2
					} else {
						return '';
					}
				case 'Order Billing City':
					if(order.billing_address) {
						return order.billing_address.locality
					} else {
						return '';
					}
				case 'Order Billing State':
					if(order.billing_address) {
						return order.billing_address.region
					} else {
						return '';
					}
				case 'Order Billing Zipcode': case 'Order Billing Zip Code':
					if(order.billing_address) {
						return order.billing_address.postal_code
					} else {
						return '';
					}
				case 'Order Billing Country':
					if(order.billing_address) {
						return order.billing_address.country
					} else {
						return '';
					}
				case 'Order Date': case 'Long Order Date':
					return moment(order.created_at).format(LONG_DATE_FORMAT);
				case 'Short Order Date':
					return moment(order.created_at).format(SHORT_DATE_FORMAT);
				case 'Order Time':
					return moment(order.created_at).format(TIME_FORMAT);
				case 'Order Submitted Date': case 'Long Order Submitted Date':
					return order.studio_order_batch_created_at ? moment(order.studio_order_batch_created_at).format(LONG_DATE_FORMAT) : '';
				case 'Short Order Submitted Date':
					return order.studio_order_batch_created_at ? moment(order.studio_order_batch_created_at).format(SHORT_DATE_FORMAT) : '';
				case 'Order Submitted Time':
					return order.studio_order_batch_created_at ? moment(order.studio_order_batch_created_at).format(TIME_FORMAT) : '';
				case 'Order Packages': case 'Order Package SKUs':
					var packageSKUs = {};
					order.ordered_packages.forEach(function(orderedPackage) {
						if(packageSKUs[orderedPackage.sku]) {
							packageSKUs[orderedPackage.sku] += orderedPackage.quantity || 1;
						} else {
							packageSKUs[orderedPackage.sku] = orderedPackage.quantity || 1;
						}
					});

					return Object.keys(packageSKUs).map(function(sku) {
						return '(' + packageSKUs[sku] + ') ' + sku;
					});
				case 'Order Package Names': {
					let packageNames = {};
					order.ordered_packages.forEach(function(orderedPackage) {
						if(packageNames[orderedPackage.name]) {
							packageNames[orderedPackage.name] += orderedPackage.quantity || 1;
						} else {
							packageNames[orderedPackage.name] = orderedPackage.quantity || 1;
						}
					});

					return Object.keys(packageNames).map(function(name) {
						return '(' + packageNames[name] + ') ' + name;
					});
				}
				case 'Order Package Descriptions':
					return order.ordered_packages.map(function(orderedPackage) {
						return orderedPackage.description;
					});
				case 'Order Package SKU': case 'Order Package Name': case 'Order Package Description': {
					let orderedPackage = orderedPackages[0] || null;
					if(orderedPackage) {
						switch(field) {
							case 'Order Package SKU':
								return orderedPackage.sku;
							case 'Order Package Name':
								return orderedPackage.name;
							case 'Order Package Description':
								return orderedPackage.description;
						}
					}
					
					return '';
				}
				case 'Order Products': case 'Order Product SKUs':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.sku;
						}));
					}, []);
				case 'Order Product Names':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.name;
						}));
					}, []);
				case 'Order Product Descriptions':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.description;
						}));
					}, []);
				case 'Order Product Types':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.item_type.replace(/_/, ' ').toTitleCase();
						}));
					}, []);
				case 'Order Product SKU': case 'Order Product Name': case 'Order Product Description': case 'Order Product Type': {
					let orderedProduct = orderedProducts[0] || null;
					if(orderedProduct) {
						switch(field) {
							case 'Order Product SKU':
								return orderedProduct.sku;
							case 'Order Product Name':
								return orderedProduct.name;
							case 'Order Product Description':
								return orderedProduct.description;
							case 'Order Product Type':
								return orderedProduct.item_type.replace(/_/, ' ').toTitleCase();
						}
					}
					
					return '';
				}
				case 'Order Layout Names':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.plicLayoutName;
						}));
					}, []).filter(function(layoutName) {
						return !!layoutName;
					});
				case 'Order Product Names/Layout Names':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.plicLayoutName || product.name;
						}));
					}, []);
				case 'Order Product Descriptions/Layout Names':
					return order.ordered_packages.reduce(function(products, orderedPackage) {
						return $.merge(products, orderedPackage.ordered_products.map(function(product) {
							return product.plicLayoutName || product.description;
						}));
					}, []);
				case 'Order Payment Type':
					return (order.payment_type || '').split('_')
						.map(function(word) {
							return word.charAt(0).toUpperCase() + word.slice(1);
						})
						.join(' ');
				case 'Order Price':
					var cents = order.order_package_price_cents || 0;
					return '$' + (cents / 100.0).toFixed(2);
				case 'Order Subtotal':
					if(order.order_subtotal) {
						return '$' + (order.order_subtotal.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Taxes':
					if(order.order_tax) {
						return '$' + (order.order_tax.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Shipping':
					if(order.order_shipping) {
						return '$' + (order.order_shipping.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Discount':
					if(order.order_discount) {
						return '$' + (order.order_discount.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Total':
					if(order.order_total) {
						return '$' + (order.order_total.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Payment':
					if(order.payment_total) {
						return '$' + (order.payment_total.subunits / 100.0).toFixed(2);
					} else {
						return '$0.00';
					}
				case 'Order Comments':
					return order.comments || '';
				case 'Order Customer Service Flagged':
					return order.customer_service_flagged ? 'Yes' : 'No';
				case 'Order Customer Service Notes':
					return order.customer_service_notes || '';
				case 'Ordered Options': case 'Order All Options':
					var orderOptions = $.merge([], order.ordered_options || []);
					order.ordered_packages.forEach(function(orderedPackage) {
						if(orderedPackage.ordered_options) {
							$.merge(orderOptions, orderedPackage.ordered_options);
						}

						orderedPackage.ordered_products.forEach(function(orderedProduct) {
							if(orderedProduct.ordered_options) {
								$.merge(orderOptions, orderedProduct.ordered_options);
							}
						});
					});

					return orderOptions.map(function(option) {
						var quantity = ' (' + option.option_type.charAt(0).toUpperCase() + option.option_type.slice(1) + ' option)';
						return option.group_id + ': ' + option.choice_id + quantity;
					});
				case 'Order Package Options': {
					let orderPackageOptions = [];
					order.ordered_packages.forEach(function(orderedPackage) {
						if(orderedPackage.ordered_options) {
							orderPackageOptions.push(...orderedPackage.ordered_options);
						}
					});

					return orderPackageOptions.map(function(option) {
						return option.group_id + ': ' + option.choice_id;
					});
				}
				case 'Order Product Options': {
					let orderProductOptions = [];
					order.ordered_packages.forEach(function(orderedPackage) {
						orderedPackage.ordered_products.forEach(function(orderedProduct) {
							if(orderedProduct.ordered_options) {
								orderProductOptions.push(...orderedProduct.ordered_options);
							}
						});
					});

					return orderProductOptions.map(function(option) {
						return option.group_id + ': ' + option.choice_id;
					});
				}
				case 'Order Photo Options': {
					let orderPhotoOptions = (order.ordered_options ?? []).filter(option => option.option_type === 'photo');

					return orderPhotoOptions.map(function(option) {
						return option.group_id + ': ' + option.choice_id;
					});
				}
				case 'Order Digital Delivery Email':
					return order.digital_delivery_email || '';
				case 'Order Digital Delivery Phone Number':
					return order.digital_delivery_phone_number || '';
				case 'Order Background Name': {
					let orderedBackgrounds = orderedPhotos.map(function(photo) {
						return photo.background_photo;
					}).filter(function(background) {
						return !!background;
					});
					
					var orderedBackground = orderedBackgrounds[options.secondaryIndex || 0];
					if(orderedBackground) {
						return orderedBackground.original_file_name;
					} else {
						return '';
					}
				}
				case 'Order Background Names': {
					let orderedBackgrounds = orderedPhotos.map(function(photo) {
						return photo.background_photo;
					}).filter(function(background) {
						return !!background;
					});

					return orderedBackgrounds
						.map(orderedBackground => orderedBackground.original_file_name)
						.filter((orderedBackground, index, self) => self.indexOf(orderedBackground) === index)
						.join(', ');
				}
				default:
					return null;
			}
		},
		getGroupByParam: function() {
			var pageSet = this.getPageSet();
			if(!pageSet) {
				return null;
			}

			if(pageSet.project && pageSet.project.groupBy) {
				return pageSet.project.groupBy.split(',');
			} else {
				return null;
			}
		},
		updateAllUsersSelection: function() {
			if(!this.parent) {
				return;
			}

			var activeUsers = this.parent.getActiveUsers();
			if(!activeUsers) {
				return;
			}

			for(let i = 0; i < activeUsers.length; i++) {
				var user = activeUsers[i];
				user.currentSelectionDivs = [];

				if(user.currentSelection) {
					this.onUpdateCurrentSelection(user, user.currentSelection);
				}
			}
		},
		onUpdateCurrentSelection: function(user, currentSelections) {
			if(!$.isArray(currentSelections)) {
				currentSelections = [currentSelections];
			}

			var me = this;
			currentSelections.forEach(function(currentSelection) {
				me.onUpdateCurrentSelectionDiv(user, currentSelection);
			});
		},
		onUpdateCurrentSelectionDiv: function(user, currentSelection) {
			var selectedDiv;
			for(let i = 0; i < this.texts.length; i++) {
				let text = this.texts[i];
				if(text.instance && text.instance.id == currentSelection.id) {
					selectedDiv = text;
					break;
				}
			}

			for(let i = 0; i < this.frames.length; i++) {
				var frame = this.frames[i];
				if(frame.instance.id == currentSelection.id) {
					selectedDiv = frame;
					break;
				}
			}

			selectedDiv = this.canvas.onUpdateCurrentSelectionDiv(selectedDiv, currentSelection);
			if(selectedDiv) {
				selectedDiv.setOtherUserFocused(user, currentSelection);
			}
		},
		updateUserSelection: function(id, selection) {
			if(this.parent && this.parent.pageSet && this.parent.pageSet.db) {
				this.parent.pageSet.db.updateUserSelection(id, selection);
			}

			this.checkIfContentShouldTriggerWrap(id);
		},
		checkIfContentShouldTriggerWrap: function(id) {
			let page = this.getPage();
			if(page?.openSpreadOnEditCenterContent && !this.parent?.wrapPages && this.parent?.hasEmptyPage()) {
				if(Object.values(page.openSpreadOnEditCenterContent.texts ?? {}).filter(text => text?.id === id).length || Object.values(page.openSpreadOnEditCenterContent.candids ?? {}).filter(candid => candid?.id === id).length) {
					this.parent.wrapPages = true;
					this.parent.updatePagesAfterChange(true);
					return true;
				}
			}

			return false;
		},
		initDroppable: function() {
			$(this.canvas).find('.flowCell:not(.rowHeader)').each(function() {
				if(this.initDroppable) {
					this.initDroppable();
				}
			});
		},
		setMask: function(mask) {
			if(mask['clip-path'] == 'url(#Mask-1)') {
				$.extend(mask, {
					'clip-path':'url(#Mask-1-0_8)',
					'-webkit-clip-path':'url(#Mask-1-0_8)'
				});

				$.appendToGlobalSVGDefinition('<clipPath id="Mask-1-0_8" clipPathUnits="objectBoundingBox"><rect x="0" y="0" width="1" height="1" rx="0.100px" ry="0.080px"/></clipPath>');
			}

			let layout = this;
			if (this.getPageSet() && this.getPageSet().setStudentMask) {
				var pageSet = this.getPageSet();
				if ($.userEvents) {
					$.userEvents.addEvent({
						context: [pageSet, 'mask'],
						action: 'update',
						args: [pageSet.getStudentMask(), mask]
					});
				}

				pageSet.setStudentMask(mask);
			} else {
				// Get root layout/page
				if (layout.parentLayout) {
					layout = layout.parentLayout;
				}
				let rootPage = layout.getPage();
				if (rootPage.getRootPage) {
					rootPage = rootPage.getRootPage();
				}
				rootPage.setStudentMask(mask);

				// Update page's layout mask
				var layoutDefinition = rootPage.getLayout();
				if (layoutDefinition && layoutDefinition.cell) {
					if ($.userEvents) {
						$.userEvents.addEvent({
							context: [rootPage, 'layout', 'cell', 'mask'],
							action: 'update',
							args: [layoutDefinition.cell.mask, mask]
						});
					}

					layoutDefinition.cell.mask = mask;
				}
			}

			// Go down chain and apply mask to each layout
			while (layout) {
				$(layout).find('.flowCell').find('.imageInnerStyleWrapper').css(mask);
				layout = layout.childLayout;
			}
		},
		setSide: function(side) {
			this.side = side;
			$(this).removeClass('leftPage rightPage').addClass(side.toLowerCase() + 'Page');
			this.canvasMargins.side = side.toLowerCase();
			this.canvasMargins.saveGutter = !!side;
		},
		divideAllPropertiesByRatio: function(obj) {
			for(var property in obj) {
				this.dividePropertyByRatio(obj, property);
			}
		},
		dividePropertyByRatio: function(obj, property) {
			if($.isInit(obj[property])) {
				obj[property] = obj[property] / this.ratio;
			}
		},
		multiplyAllPropertiesByRatio: function(obj) {
			for(var property in obj) {
				this.multiplyPropertyByRatio(obj, property);
			}
		},
		multiplyPropertyByRatio: function(obj, property) {
			if($.isInit(obj[property])) {
				obj[property] = obj[property] * this.ratio;
			}
		},
		startGroupedEvents: function() {
			if ($.userEvents) {
				$.userEvents.startGroupedEvents();
			}
		},
		keepEventsGroupedLonger: function() {
			if($.userEvents) {
				$.userEvents.keepEventsGroupedLonger();
			}
		},
		stopGroupedEvents: function() {
			if ($.userEvents) {
				$.userEvents.stopGroupedEvents();
			}
		},
		setUserSettings: function(settings) {
			if(!settings) {
				settings = {};
			}

			this.setGridLinesSettings(settings.gridLines);
			if($.isInit(settings.alignmentLines)) {
				this.setContentSettingsValue('alignmentLinesEnabled', $.isTruthy(settings.alignmentLines));
			}
			if($.isInit(settings.advancedEditor)) {
				this.setContentSettingsValue('showDisplayTooltips', $.isTruthy(settings.advancedEditor));
			}
		},
		setGridLinesSettings: function(settings) {
			this.gridLineSettings = settings;
			if(this.page) {
				this.gridLines.setSettings(settings);
			}
		},
		setContentSettingsValue: function(name, value) {
			this.contentSettings[name] = value;

			$(this).find('.flowContent').each(function() {
				this[name] = value;
			});
		},
		getContentSettings: function(type) {
			var contentSettings = $.extend(true, {}, this.contentSettings);

			var layoutDimensions = this.getLayoutDimensions();
			if(layoutDimensions) {
				contentSettings.duplicateLinkedLayoutsInBleed = !layoutDimensions.gutterBleedContentVisible;
			}

			let page = this.getPage();
			if(page.rootPage) {
				page = page.rootPage;
			}
			if(typeof page.duplicateLinkedLayoutsInBleed !== 'undefined') {
				contentSettings.duplicateLinkedLayoutsInBleed = page.duplicateLinkedLayoutsInBleed;
			}

			var pageSet = this.getPageSet();
			if(type === 'image') {
				if(pageSet) {
					contentSettings.containInParent = pageSet.doesContainImageInParent();
				}
				if($.isInit(page.disableRotateSwapWidthAndHeight)) {
					if(typeof page.disableRotateSwapWidthAndHeight === 'function') {
						contentSettings.disableRotateSwapWidthAndHeight = page.disableRotateSwapWidthAndHeight();
					} else {
						contentSettings.disableRotateSwapWidthAndHeight = page.disableRotateSwapWidthAndHeight;
					}
				}
				if(page.shouldKeepSizeWhenChangingEffects) {
					contentSettings.keepSizeWhenChangingEffects = page.shouldKeepSizeWhenChangingEffects();
				}
				if(page.shouldKeepLayoutSizedTogether) {
					contentSettings.keepLayoutSizedTogether = page.shouldKeepLayoutSizedTogether();
				}
			} else if(type === 'text') {
				if(page.doesOverflowTextToLinkedLayouts) { 
					contentSettings.overflowToLinkedLayouts = page.doesOverflowTextToLinkedLayouts();

					if(contentSettings.overflowToLinkedLayouts && !contentSettings.duplicateLinkedLayoutsInBleed) {
						if(this.side == 'Right') {
							$.extend(contentSettings, {
								containInLeftParent: false
							});
						} else {
							$.extend(contentSettings, {
								containInRightParent: false
							});
						}
					}
				} else if(page.doesAllowTextToCrossLinkedLayouts) {
					contentSettings.overflowToLinkedLayouts = page.doesAllowTextToCrossLinkedLayouts();
					contentSettings.dontAllowStopInMargins = true;

					if(contentSettings.overflowToLinkedLayouts) {
						if(this.side == 'Right') {
							$.extend(contentSettings, {
								containInLeftParent: false
							});
						} else {
							$.extend(contentSettings, {
								containInRightParent: false
							});
						}
						// This should not stop in bleed, so should never need to duplicate it
						contentSettings.duplicateLinkedLayoutsInBleed = false;
					}
				}

				if(page.doesAllowTypingOffEdge && page.doesAllowTypingOffEdge()) {
					contentSettings.wordWrapTextBounds = null;
				}
			}

			if(page.doesAllowContentCompletelyInBleed) {
				contentSettings.allowCompletelyInBleed = page.doesAllowContentCompletelyInBleed();
			}

			if(page.canToggleContentLocks()) {
				contentSettings.canToggleLock = true;
			}

			return contentSettings;
		},

		getLinkedLayout: function(direction) {
			if(!this.parent) {
				return null;
			}

			var linkedLayout = null;
			if(direction == 'next') {
				linkedLayout = this.parent.getNextVisiblePage(this);
			} else if(direction == 'previous') {
				linkedLayout = this.parent.getPreviousVisiblePage(this);
			}

			return linkedLayout;
		},
		flushDelayedSaves: function() {
			$(this.container).find('.flowContent').each(function() {
				if(this.flushDelayedSaveQueue) {
					this.flushDelayedSaveQueue();
				}
			});
		},
		getMaxZIndex: function() {
			return this.page.getMaxZIndex();
		},
		setForcedDPI: function(dpi) {
			this.forcedDPI = dpi;

			if(this.page) {
				this.setPage(this.page, true);
			}
		},
		setZoom: function(zoom) {
			this.zoom = zoom;
		},
		setDisplayCropMarks: function(displayCropMarks) {
			this.container.setDisplayCropMarks(displayCropMarks);
		},

		destroy: function() {
			this.container.destroy();
			$(this.container).find('.flowContent').each(function() {
				if(this.destroy) {
					this.destroy();
				}
			});
			this.canvasMargins.destroy();
			this.canvas.destroy();
			this.extraSubjectGrids.forEach(function(subjectGrid) {
				subjectGrid.destroy();
			});

			if(this.titleDiv) {
				this.titleDiv.destroy();
			}

			if(this.pageNumberDiv) {
				this.pageNumberDiv.destroy();
			}
			if(this.delayedChange) {
				window.clearTimeout(this.delayedChange);
			}
		},
		getDisplayUnit: function(value) {
			return $.getDisplayUnit(value);
		},
		insertNewPage: function(pageContructor, getNewPageSettings) {
			var pageSet = this.getPageSet();
			let page = new pageContructor(getNewPageSettings.call(this, pageSet));

			pageSet.addPage(page);
		},

		addPrimarySubjectPoseFrame: function(frameDefinition) {
			frameDefinition = $.extend(true, {
				id: $.getUniqueId(),
				field: 'individualized image field',
				zIndex: this.getMaxZIndex() + 1
			}, frameDefinition);

			let page = this.getPage();
			if(this.largeCellUser == -1) {
				if(page && page.getNextIndividualIndex) {
					this.largeCellUser = page.getNextIndividualIndex(-1);
				} else {
					this.largeCellUser = 0;
				}
			}

			var frame = this.primarySubjectPoseFrame = new $.FlowLayoutFrame(this, frameDefinition, this.ratio, $.extend(true, {
				onChangeInstanceProperty: function(name, value, extras) {
					// Since we aren't doing partial updates on layout schema, don't think it matters if we actually do anything with name/value
					let layout = $.extend(true, {}, wrapper.getLayout());
					layout.primarySubjectPoseFrame = $.extend(true, {}, this.instance);
					page.setLayout(layout, true, true);
				}
			}, this.getContentSettings('image')));
			frame.extraOffset = this.canvasBorderWidth;
			this.container.appendChild(frame);
			this.frames.push(frame);

			this.onFlowAdd(frame);
			frame.onAdd();
		},

		setupCustomUserLabel: function() {
			if(!this.showCustomLabels) {
				return;
			}

			let page = this.getPage();
			if(!page || !this.editable) {
				if(this.customUserLabel) {
					this.customUserLabel.hide();
				}
				return;
			}

			let userLabel = page.getExtraProperty('userLabel');
			if(!userLabel) {
				if(this.customUserLabel) {
					this.customUserLabel.hide();
				}
				return;
			}

			let userLabelText = $.FlowLayoutSVGUtils.getInstanceText(userLabel);
			if(!this.customUserLabel) {
				this.customUserLabel = $('<div class="customPageLabelBar"><div class="ui tiny label green"></div></div>').appendTo(this);
			}
			this.customUserLabel.show().children('.label').text(userLabelText);
		},
		getBackgroundSettingKey: function() {
			return 'backgroundSettings';
		},

		userLabelError: false,
		contentSettings: {},
		forcedDPI: false,
		canvasBorderWidth: 0,
		zoom: 100,
		displayCropMarks: false,
		defaultBackgroundSize: 600,
		extraContentProperties: {},
		shouldHideInsidePageBorder: !!options.shouldHideInsidePageBorder,
		hideBleed: false,
		showCustomLabels: false,

		DEFAULT_CELL_PADDING: DEFAULT_CELL_PADDING
	});

	wrapper.frames = [];
	wrapper.texts = [];
	wrapper.loading = 0;
	wrapper.largeCellUser = -1;
	wrapper.childrenLoading = [];
	wrapper.visible = true;
	wrapper.showLabels = true;

	if(options) {
		if(options.id) {
			$(wrapper).attr('id', options.id);
		}
		if(options.pageSet) {
			wrapper.pageSet = options.pageSet;
		}
		if(options.onLayoutChange) {
			wrapper.onLayoutChange = options.onLayoutChange;
		}
		wrapper.fixedSize = options.fixedSize;
		if($.isInit(options.showLabels)) {
			wrapper.showLabels = options.showLabels;
		}
		if(options.forcedDPI) {
			wrapper.forcedDPI = options.forcedDPI;
		}
		if(options.displayCropMarks) {
			wrapper.displayCropMarks = options.displayCropMarks;
		}
		if(options.includeWhiteSpace) {
			wrapper.includeWhiteSpace = options.includeWhiteSpace;
		}
		if(options.hideBleed) {
			wrapper.hideBleed = options.hideBleed;
		}
		if(options.showCustomLabels) {
			wrapper.showCustomLabels = options.showCustomLabels;
		}
	}

	wrapper.container = new $.FlowLayoutContainer({
		flowLayout: wrapper
	});
	wrapper.canvas = new $.FlowLayoutSubjectGrid({
		flowLayout: wrapper
	});
	wrapper.extraSubjectGrids = [];
	wrapper.canvasMargins = new $.FlowLayoutMargins({
		wrapper: wrapper,
		popupPrefix: $(wrapper).attr('id') || 'general',
		appendTo: wrapper.container,
		addClass: 'flowPageMargins flowAlignTarget',
		side: wrapper.side == 'Right' ? 'right' : 'left',
		onChangeMargins: function(newMargins) {
			wrapper.setPageMargins(newMargins, false);
		}
	});
	wrapper.appendChild(wrapper.container);
	wrapper.canvasMargins.appendChild(wrapper.canvas);
	wrapper.droppableTarget = wrapper.container;

	if(options) {
		if(options.side) {
			wrapper.setSide(options.side);
		}
	}

	wrapper.gridLines = new $.FlowLayoutGridLines({
		flowLayout: wrapper
	});

	wrapper.setEditable(options && options.editable);

	return wrapper;
};

