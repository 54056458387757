<template>
	<v-alert v-if="errorMessage" type="error" >{{ errorMessage }}</v-alert>
	<div v-else-if="loading">
		<v-progress-circular indeterminate />

		<v-alert v-if="loadingMessage" type="info" >{{ loadingMessage }}</v-alert>
	</div>
	<pick-from-google-drive v-else-if="pickingFromGoogleDrive" @returnToMain="returnToMain" :accepted-file-mime-types="acceptedFileMimeTypes" @filesSelected="filesSelected" :allow-multiple="allowMultiple" :allow-folder-uploads="allowFolderUploads" />
	<pick-from-google-photos v-else-if="pickingFromGooglePhotos" @returnToMain="returnToMain" @filesSelected="filesSelected" :accepted-file-mime-types="acceptedFileMimeTypes" :allow-multiple="allowMultiple" />
	<pick-from-instagram v-else-if="pickingFromInstagram" @returnToMain="returnToMain" @filesSelected="filesSelected" :popup="instagramUsePopup" :allow-multiple="allowMultiple" />
	<div v-else @drop.prevent="droppedFiles" @dragover.prevent>
		<component :is="chooseFileHeaderComponent" v-if="chooseFileHeaderComponent" :extra-step-props="extraStepProps" :album-id="albumId" />

		<v-btn class="upload-button select-files" color="primary" raised x-large @click="pickFiles" v-if="allowFileUploads"><v-icon left>cloud_upload</v-icon> {{ selectFilesButton || i18n('uploader.uploadPhotos') }}</v-btn>
		<v-btn class="upload-button select-folder" color="primary" raised x-large @click="pickFolder" v-if="allowFolderUploads && supportsFolderUpload"><v-icon left>folder_open</v-icon> {{ selectFolderButton }}</v-btn>
		<p/>
		<v-btn color="primary" raised @click="pickFromGoogleDrive"  class="upload-third-party-button" v-if="supportsGoogleComp">
			<v-avatar color="white" class="logo-avatar" :size="26">
				<v-img src="./images/google_drive_logo.png" />
			</v-avatar>
			{{ i18n('uploader.googleDrive') }}
		</v-btn>
		<v-btn class="google-photos-button upload-third-party-button" color="primary" raised @click="pickFromGooglePhotos" v-if="supportsGoogleComp">
			<v-avatar color="white" class="logo-avatar" :size="26">
				<v-img class="logo" src="./images/google_photos_logo.png" />
			</v-avatar>
			{{ i18n('uploader.googlePhotos') }}
		</v-btn>
		<p/>
		<div>{{ computedChooseFileMessage }}</div>
		<v-alert class="info-alert" type="info" outlined>Please ensure that any images you upload are those for which you hold the copyright. Unauthorized use of copyrighted images is prohibited. By uploading an image, you confirm that you own the rights to the image or have obtained proper permissions for its use.</v-alert>

		<v-alert type="info" outlined v-if="!stopButton && supportsGoogleComp">
			Captura Yearbooks use and transfer to any other app of information received from Google APIs will adhere to the <a class="link" target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.
		</v-alert>

		<template v-if="stopButton">
			<p/>
			<br/>
			<v-btn class="stop-button" @click="stop">{{ stopButton }}</v-btn>
		</template>

		<input ref="fileInput" type="file" :multiple="allowMultiple" :accept="acceptedFiles" class="hiddenInput" @change="selectedFiles"/>
		<input ref="folderInput" type="file" :multiple="allowMultiple" directory mozdirectory webkitdirectory :accept="acceptedFiles" class="hiddenInput" @change="selectedFiles"/>
	</div>
</template>

<script>
import PickFromGoogleDrive from './PickFromGoogleDrive';
import PickFromGooglePhotos from './PickFromGooglePhotos.vue';
import PickFromInstagram from './PickFromInstagram';
import { logAxiosError } from '../utils/axios';
import loadRequirements from './utils/load-requirements';
import chooseFiles from './mixins/choose-files';
import filesize from 'filesize';
import queryString from 'query-string';
import { validate as validateUUID } from 'uuid';
import { sleep } from '../utils/sleep';

export default {
	props: {
		acceptedFileExtensions: null,
		chooseFileMessage: null,
		chooseFileHeaderComponent: null,
		imageRequirements: null,
		allowMultiple: null,
		isVisible: null,
		albumId: null,
		stopButton: null,
		selectFilesButton: null,
		selectFolderButton: null,
		allowFileUploads: null,
		allowFolderUploads: null,
		instagramUsePopup: null,
		initialDropEvent: null,
		checkValidUploaderFunction: null,
		extraStepProps: null
	},
	data() {
		let queryParams = queryString.parse((window.testLocation ?? window.location).href);

		return {
			loading: true,
			loadingMessage: null,
			errorMessage: null,
			pickingFromGoogleDrive: false,
			pickingFromGooglePhotos: false,
			pickingFromInstagram: !!queryParams.instagramToken,
			supportsGoogle: !!process.env.VUE_APP_GOOGLE_API_KEY,
			googleAPILoaded: !!(window.gapi && window.google?.accounts),
			supportsInstagram: !!process.env.VUE_APP_FACEBOOK_API_INSTAGRAM_APP_ID,
			supportsFolderUpload: true
		};
	},
	mixins: [chooseFiles],
	computed: {
		computedChooseFileMessage() {
			return this.chooseFileMessage.replace('{{maxFileSize}}', filesize(this.imageRequirements.maxSize));
		},
		supportsGoogleComp() {
			return this.supportsGoogle && this.googleAPILoaded;
		}
	},
	watch: {
		isVisible(value) {
			// Only needed if user clicks back and then re-selects same image again
			if(value) {
				if(this.$refs.fileInput) {
					this.$refs.fileInput.value = '';
				}
				if(this.$refs.folderInput) {
					this.$refs.folderInput.value = '';
				}
			}
		}
	},
	methods: {
		pickFiles() {
			this.$refs.fileInput.click();
		},
		pickFolder() {
			this.$refs.folderInput.click();
		},
		
		selectedFiles(event) {
			this.filesSelected(event.target.files);
		},
		filesSelected: async function(files) {
			this.loading = true;
			files = await this.loadSelectedFiles(files);

			this.$emit('set-files', files);
			this.$emit('next-step');
			this.loading = false;
		},
		pickFromGoogleDrive() {
			this.pickingFromGoogleDrive = true;
		},
		pickFromGooglePhotos() {
			this.pickingFromGooglePhotos = true;
		},
		pickFromInstagram() {
			this.pickingFromInstagram = true;
		},
		returnToMain() {
			this.pickingFromGoogleDrive = false;
			this.pickingFromGooglePhotos = false;
			this.pickingFromInstagram = false;
		},
		stop() {
			this.$emit('stop');
		},
		error(errorMessage, error, logBugsnag = false) {
			this.loading = false;
			this.errorMessage = errorMessage;
			console.error(errorMessage, error);

			if(logBugsnag) {
				logAxiosError(errorMessage, error);
			}
		},

		async checkForGoogleAPI() {
			// If this isn't loaded in 5 seconds then something is broken
			for(let i = 0; i < 50; i++) {
				await sleep(100);
				this.googleAPILoaded = !!(window.gapi && window.google?.accounts);

				if(this.googleAPILoaded) {
					return;
				}
			}
		}
	},
	mounted() {
		loadRequirements().then(requirements => {
			this.imageRequirements.validTypes = requirements.valid_content_types;
			this.imageRequirements.maxSize = requirements.max_size;

			if(this.checkValidUploaderFunction) {
				return this.checkValidUploaderFunction();
			}
		}).then(() => {
			this.loading = false;
			if(this.onLoadUploadRequirements) {
				this.onLoadUploadRequirements();
			}
		}).catch((error) => {
			let errorMessage = this.i18n('uploader.errors.loadRequirements');
			if(error.response?.data?.reason) {
				errorMessage = error.response.data.reason;
			}
			this.error(errorMessage, error);
		});

		if(!this.albumId || (this.albumId !== 'stub' && !validateUUID(this.albumId))) {
			this.errorMessage = 'Invalid album.  Ask your contact to generate a new link.';
		}

		if(this.initialDropEvent?.dataTransfer) {
			this.droppedFiles(this.initialDropEvent);
		}

		let tmpInput = document.createElement('input')
		tmpInput.type = 'file'
		this.supportsFolderUpload = typeof tmpInput.webkitdirectory != 'undefined';

		if(this.supportsGoogle && !this.googleAPILoaded) {
			this.checkForGoogleAPI();
		}
	},
	components: {
		PickFromGoogleDrive,
		PickFromGooglePhotos,
		PickFromInstagram
	}
};
</script>

<style scoped>
.hiddenInput {
	display: none;
}

.select-folder, .instagram-button, .google-photos-button {
	margin-left: 0.5em;
}

.upload-third-party-button {
	height: 48px !important;
	margin-bottom: 0.5em;
}
.upload-third-party-button:deep(.v-btn__content) {
	width: 16em;
	text-wrap: wrap;
}
.logo-avatar {
	margin-right: 0.4em;
}

.info-alert {
	margin-top: 2em;
}

.link {
	text-decoration: underline;
}
.stop-button {
	margin-bottom: 0.5em;
}
</style>